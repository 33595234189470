import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Input, Button } from "antd";

import SelectHandler from "./SelectHandler";
import { customInputCss } from "../../style/theme";

const UserFormInner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  span {
    font-size: 12px;
  }
  button {
    height: 100%;
  }
`;

const CustomInput = styled(Input)`
  ${customInputCss}
`;

function CommonInputs({ selectData, setSearchType, searchText, setSearchText, onReset, searchType }) {
  const [inputSelect, setInputSelect] = useState(selectData[0].value);
  const [placeHolderValue, setPlaceHolderValue] = useState(selectData[0].title);

  const selectOnChange = useCallback(
    (select, data) => {
      setPlaceHolderValue(data.children);
      setInputSelect(select);
      setSearchType(select);
    },
    [inputSelect],
  );

  const setValue = () => {
    const isIndex = selectData.findIndex((value) => value.value === searchType);

    if (isIndex) {
      return isIndex;
    }

    return 0;
  };

  useEffect(() => {
    // setPlaceHolderValue();
    setPlaceHolderValue(selectData[setValue()].title);
    setInputSelect(searchType);
  }, [searchType]);

  return (
    <UserFormInner>
      <SelectHandler data={selectData} onChange={selectOnChange} isValue={searchType} />
      <CustomInput name="name" value={searchText} onChange={setSearchText} placeholder={`${placeHolderValue} 검색`} />
      <Button type="button" onClick={onReset}>
        초기화
      </Button>
      <Button htmlType="submit" type="primary" style={{ color: "#fff", border: "1px solid #1890ff", marginLeft: 6 }}>
        검색
      </Button>
    </UserFormInner>
  );
}

CommonInputs.propTypes = {
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  setSearchType: PropTypes.func.isRequired,
  selectData: PropTypes.array.isRequired,
  searchType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onReset: PropTypes.func,
};

CommonInputs.defaultProps = {
  onReset: () => {},
};

export default CommonInputs;
