import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { columns } from "../../pages/Band/config";

import { customTableCss, customDateFlexWrapperCss } from "../../style/theme";

const CustomTable = styled.table`
  ${customTableCss}
  col {
    width: 16%;
    :last-of-type {
      width: 20%;
    }
  }
  tbody {
    tr {
      td {
        &:first-child {
          max-width: 253px;
        }
        &:nt-child(2) {
          max-width: 218px;
        }
        &:nt-child(2) {
          max-width: 215px;
        }
      }
    }
  }
`;

const CustomFlexWrapper = styled.div`
  ${customDateFlexWrapperCss}
`;

const CustomColorSearched = styled.div`
  color: ${(props) => (props.searched ? "#1890ff" : "#000")}!important;
`;

function BandTableHandler({ onSubmit, sortOption, page, clickHandler, postData }) {
  const { bandListData, bandSearched } = useSelector((state) => state.band);
  const [sort, setSort] = useState("");

  const sortHandler = (option, value) => {
    setSort(value);
    sortOption(option, value);
  };

  useEffect(() => {
    onSubmit();
  }, [page, onSubmit]);

  const validateText = useCallback(
    (index, text) => {
      if (postData.searchText === "") return text;
      if (index === postData.searchType && bandSearched) {
        const regText = new RegExp(postData.searchText, "gi");
        return text.replace(regText, "<span>$&</span>");
      }
      return text;
    },
    [bandListData, bandSearched],
  );

  const isSearchedTrue = (value) =>
    bandSearched && value !== "" && value !== undefined && value !== null && value !== [];

  useEffect(() => {}, [postData]);

  const theadButton = (th) => {
    switch (th) {
      case "date":
        return (
          <CustomFlexWrapper sort={sort}>
            <button type="button" onClick={() => sortHandler("sortDate", "desc")}>
              <CaretUpOutlined />
            </button>
            <button type="button" onClick={() => sortHandler("sortDate", "asc")}>
              <CaretDownOutlined />
            </button>
          </CustomFlexWrapper>
        );
      default:
        break;
    }
  };

  return (
    <>
      {bandListData && bandListData.length > 0 && (
        <CustomTable>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              {columns.map((v, i) => (
                <th key={i}>
                  {v.title}
                  {theadButton(v.dataIndex)}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {bandListData.slice(0, 20).map((v, i) => (
              <tr key={i} onClick={() => clickHandler(v.bandSn)}>
                <td>
                  <div
                    key={v.bandSn}
                    title={v.bandSn}
                    dangerouslySetInnerHTML={{ __html: validateText(1, v.bandSn) }}
                  />
                </td>
                <td>
                  <CustomColorSearched
                    searched={isSearchedTrue(postData.searchStartDt)}
                    title={moment(v.bandRegDt * 1000).format("YYYY.MM.DD")}
                  >
                    {moment(v.bandRegDt * 1000).format("YYYY.MM.DD")}
                  </CustomColorSearched>
                </td>
                <td>
                  <div
                    title={validateText(2, v.userNm)}
                    key={v.userNm}
                    dangerouslySetInnerHTML={{ __html: validateText(2, v.userNm) }}
                  />
                </td>
                <td>
                  <div
                    title={validateText(3, v.catNm)}
                    key={v.catNm}
                    dangerouslySetInnerHTML={{ __html: validateText(3, v.catNm) }}
                  />
                </td>
                <td>
                  <div title={v.bandVersion}>{v.bandVersion}</div>
                </td>
                <td>
                  <div title={moment(v.synchronizationDt * 1000).format("YYYY.MM.DD HH:mm")}>
                    {moment(v.synchronizationDt * 1000).format("YYYY.MM.DD HH:mm")}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </CustomTable>
      )}
    </>
  );
}

BandTableHandler.propTypes = {
  page: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sortOption: PropTypes.func.isRequired,
  clickHandler: PropTypes.func,
  postData: PropTypes.object.isRequired,
};

BandTableHandler.defaultProps = {
  clickHandler: () => {},
};

export default BandTableHandler;
