import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { smallThanTen, regDateToString, boolToString, genderToString, weightToKor } from "../../utils/tostring";
import { customInfoWrapperCss, customInfoListCss, customInfoTitleCss } from "../../style/theme";

const CustomInfoWrapper = styled.div`
  ${customInfoWrapperCss}
  min-width: 300px;
`;

const CustomInfoList = styled.div`
  ${customInfoListCss}
  border-bottom: 1px solid #eee;
`;

const CustomInfoTitle = styled.span`
  ${customInfoTitleCss}
`;

const EllipsisDesc = styled.span`
  max-width: 60%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
function CatTableHandlerInfo({ ageLang }) {
  const { catDetailData } = useSelector((state) => state.cat);

  const changeKor = (value) => {
    switch (value) {
      case "아깽이":
        return "영유아기";
      case "주니어":
        return "아동청소년기";
      case "성묘":
        return "청년기";
      case "중년":
        return "중장년기";
      case "노령":
        return "노년기";
      default:
        return value;
    }
  };

  return (
    <CustomInfoWrapper>
      <CustomInfoList>
        <p style={{ display: "flex", alignItems: "center" }}>
          <CustomInfoTitle>이름</CustomInfoTitle>
          <EllipsisDesc>{catDetailData?.catNm}</EllipsisDesc>
        </p>
        <p>
          <CustomInfoTitle>등록일</CustomInfoTitle>
          {regDateToString(catDetailData?.regDt)}
        </p>
      </CustomInfoList>
      <CustomInfoList>
        <p>
          <CustomInfoTitle>성별</CustomInfoTitle>
          {genderToString(catDetailData?.catGenderCode)}
        </p>
        <p>
          <CustomInfoTitle>중성화</CustomInfoTitle>
          {boolToString(catDetailData?.neutralizationYn)}
        </p>
      </CustomInfoList>
      <CustomInfoList>
        <p>
          <CustomInfoTitle>품종</CustomInfoTitle>
          {catDetailData?.catBreedKrNm}
        </p>
      </CustomInfoList>
      <CustomInfoList>
        <p>
          <CustomInfoTitle>생년월일</CustomInfoTitle>
          {`${catDetailData?.catBirthYear}.${smallThanTen(catDetailData?.catBirthMonth)}.${smallThanTen(
            catDetailData?.catBirthDay,
          )}`}
        </p>
        <p>
          <CustomInfoTitle>나이</CustomInfoTitle>
          {ageLang === 1
            ? `${catDetailData?.catAgeMonth}개월 (${changeKor(catDetailData?.catAgeStatusKr)})`
            : `${catDetailData?.catAgeMonth}개월 (${catDetailData.catAgeStatusEn})`}
        </p>
      </CustomInfoList>
      <CustomInfoList>
        <p>
          <CustomInfoTitle>접종</CustomInfoTitle>
          {boolToString(catDetailData?.vaccinationYn)}
        </p>
      </CustomInfoList>
      <CustomInfoList>
        <p>
          <CustomInfoTitle>체중</CustomInfoTitle>
          {`${catDetailData?.catWeight}kg (${weightToKor(catDetailData?.catWeightType)})`}
        </p>
      </CustomInfoList>
    </CustomInfoWrapper>
  );
}

CatTableHandlerInfo.propTypes = {
  ageLang: PropTypes.number.isRequired,
};

export default CatTableHandlerInfo;
