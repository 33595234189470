import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './auth';
import alarmPostReducer from './alarm';
import bandPostReducer from './band';
import catPostReducer from './cat';
import mainReducer from './main';
import userPostReducer from './user';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    alarm: alarmPostReducer,
    band: bandPostReducer,
    cat: catPostReducer,
    main: mainReducer,
    user: userPostReducer,
  });
export default rootReducer;
