import axios from "axios";

const fetcher = (url, token) =>
  axios
    .get(url, {
      headers: {
        token,
      },
      lazy: true,
    })
    .then((response) => response.data.payload);

export default fetcher;
