import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { customTableCss } from '../../style/theme';

const CustomTable = styled.table`
  ${customTableCss}
`;

function TableHandler({ columns, data, clickHandler }) {
  return (
    <>
      <CustomTable>
        <thead>
          <tr>
            {columns.map((v, i) => (
              <th key={i}>{v.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.slice(0, 20).map((v, i) => (
              <tr key={i} onClick={() => clickHandler(v)}>
                <td>
                  <div title={v.id}>{v.id}</div>
                </td>
                <td>
                  <div title={v.value}>{v.value}</div>
                </td>
                <td>
                  <div title={v.id}>{v.id}</div>
                </td>
                <td>
                  <div title={v.id}>{v.id}</div>
                </td>
                <td>
                  <div title={v.id}>{v.id}</div>
                </td>
              </tr>
            ))}
        </tbody>
      </CustomTable>
    </>
  );
}

TableHandler.propTypes = {
  clickHandler: PropTypes.func,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
};

TableHandler.defaultProps = {
  clickHandler: () => {},
  data: null,
};

export default TableHandler;
