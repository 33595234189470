export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_ERROR_NULL = 'LOGIN_ERROR_NULL';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

export const CAHNGE_LOGIN_INFO_REQUEST = 'CAHNGE_LOGIN_INFO_REQUEST';
export const CAHNGE_LOGIN_INFO_SUCCESS = 'CAHNGE_LOGIN_INFO_SUCCESS';
export const CAHNGE_LOGIN_INFO_FAILURE = 'CAHNGE_LOGIN_INFO_FAILURE';

export const POPUP_CASE_CHANGE = 'POPUP_CASE_CHANGE';
export const POPUP_LOGOUT = 'POPUP_LOGOUT';

export const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';

export const CLEAR_LOGIN_REQUEST = 'CLEAR_LOGIN_REQUEST';
export const CLEAR_LOGIN_SUCCESS = 'CLEAR_LOGIN_SUCCESS';
export const CLEAR_LOGIN_FAILURE = 'CLEAR_LOGIN_FAILURE';

export const CAHNGE_LOGIN_REQUEST = 'CAHNGE_LOGIN_REQUEST';
export const CAHNGE_LOGIN_SUCCESS = 'CAHNGE_LOGIN_SUCCESS';
export const CAHNGE_LOGIN_FAILURE = 'CAHNGE_LOGIN_FAILURE';

export const LOAD_MAIN_NOTICE_REQUEST = 'LOAD_MAIN_NOTICE_REQUEST';
export const LOAD_MAIN_NOTICE_SUCCESS = 'LOAD_MAIN_NOTICE_SUCCESS';
export const LOAD_MAIN_NOTICE_FAILURE = 'LOAD_MAIN_NOTICE_FAILURE';

export const GET_USER_LIST_DATA_REQUEST = 'GET_USER_LIST_DATA_REQUEST';
export const GET_USER_LIST_DATA_SUCCESS = 'GET_USER_LIST_DATA_SUCCESS';
export const GET_USER_LIST_DATA_FAILURE = 'GET_USER_LIST_DATA_FAILURE';
export const GET_USER_DETAIL_DATA_REQUEST = 'GET_USER_DETAIL_DATA_REQUEST';
export const GET_USER_DETAIL_DATA_SUCCESS = 'GET_USER_DETAIL_DATA_SUCCESS';
export const GET_USER_DETAIL_DATA_FAILURE = 'GET_USER_DETAIL_DATA_FAILURE';

export const GET_CAT_LIST_DATA_REQUEST = 'GET_CAT_LIST_DATA_REQUEST';
export const GET_CAT_LIST_DATA_SUCCESS = 'GET_CAT_LIST_DATA_SUCCESS';
export const GET_CAT_LIST_DATA_FAILURE = 'GET_CAT_LIST_DATA_FAILURE';

export const GET_CAT_BREED_DATA_REQUEST = 'GET_CAT_BREED_DATA_REQUEST';
export const GET_CAT_BREED_DATA_SUCCESS = 'GET_CAT_BREED_DATA_SUCCESS';
export const GET_CAT_BREED_DATA_FAILURE = 'GET_CAT_BREED_DATA_FAILURE';

export const GET_CAT_DETAIL_DATA_REQUEST = 'GET_CAT_DETAIL_DATA_REQUEST';
export const GET_CAT_DETAIL_DATA_SUCCESS = 'GET_CAT_DETAIL_DATA_SUCCESS';
export const GET_CAT_DETAIL_DATA_FAILURE = 'GET_CAT_DETAIL_DATA_FAILURE';

export const GET_CAT_IMAGE_REQUEST = 'GET_CAT_IMAGE_REQUEST';
export const GET_CAT_IMAGE_SUCCESS = 'GET_CAT_IMAGE_SUCCESS';
export const GET_CAT_IMAGE_FAILURE = 'GET_CAT_IMAGE_FAILURE';

export const GET_BAND_LIST_DATA_REQUEST = 'GET_BAND_LIST_DATA_REQUEST';
export const GET_BAND_LIST_DATA_SUCCESS = 'GET_BAND_LIST_DATA_SUCCESS';
export const GET_BAND_LIST_DATA_FAILURE = 'GET_BAND_LIST_DATA_FAILURE';

export const GET_BAND_DETAIL_DATA_REQUEST = 'GET_BAND_DETAIL_DATA_REQUEST';
export const GET_BAND_DETAIL_DATA_SUCCESS = 'GET_BAND_DETAIL_DATA_SUCCESS';
export const GET_BAND_DETAIL_DATA_FAILURE = 'GET_BAND_DETAIL_DATA_FAILURE';

export const GET_BAND_FW_REQUEST = 'GET_BAND_FW_REQUEST';
export const GET_BAND_FW_SUCCESS = 'GET_BAND_FW_SUCCESS';
export const GET_BAND_FW_FAILURE = 'GET_BAND_FW_FAILURE';

export const GET_BAND_INFO_FW_REQUEST = ' GET_BAND_INFO_FW_REQUEST';
export const GET_BAND_INFO_FW_SUCCESS = ' GET_BAND_INFO_FW_SUCCESS';
export const GET_BAND_INFO_FW_FAILURE = ' GET_BAND_INFO_FW_FAILURE';

export const POST_UPDATE_BAND_FW_REQUEST = 'POST_UPDATE_BAND_FW_REQUEST';
export const POST_UPDATE_BAND_FW_SUCCESS = 'POST_UPDATE_BAND_FW_SUCCESS';
export const POST_UPDATE_BAND_FW_FAILURE = 'POST_UPDATE_BAND_FW_FAILURE';

export const GET_ALARM_LIST_DATA_REQUEST = 'GET_ALARM_LIST_DATA_REQUEST';
export const GET_ALARM_LIST_DATA_SUCCESS = 'GET_ALARM_LIST_DATA_SUCCESS';
export const GET_ALARM_LIST_DATA_FAILURE = 'GET_ALARM_LIST_DATA_FAILURE';

export const GET_ALARM_DETAIL_DATA_REQUEST = 'GET_ALARM_DETAIL_DATA_REQUEST';
export const GET_ALARM_DETAIL_DATA_SUCCESS = 'GET_ALARM_DETAIL_DATA_SUCCESS';
export const GET_ALARM_DETAIL_DATA_FAILURE = 'GET_ALARM_DETAIL_DATA_FAILURE';

export const REMOVE_ALARM_DATA_REQUEST = 'REMOVE_ALARM_DATA_REQUEST';
export const REMOVE_ALARM_DATA_SUCCESS = 'REMOVE_ALARM_DATA_SUCCESS';
export const REMOVE_ALARM_DATA_FAILURE = 'REMOVE_ALARM_DATA_FAILURE';

export const POST_ALARM_DATA_REQUEST = 'POST_ALARM_DATA_REQUEST';
export const POST_ALARM_DATA_SUCCESS = 'POST_ALARM_DATA_SUCCESS';
export const POST_ALARM_DATA_FAILURE = 'POST_ALARM_DATA_FAILURE';

export const POST_ALARM_DATA_MOD_REQUEST = 'POST_ALARM_DATA_MOD_REQUEST';
export const POST_ALARM_DATA_MOD_SUCCESS = 'POST_ALARM_DATA_MOD_SUCCESS';
export const POST_ALARM_DATA_MOD_FAILURE = 'POST_ALARM_DATA_MOD_FAILURE';

export const ALL_LOAD_REQUEST = ' ALL_LOAD_REQUEST';
export const ALL_LOAD_SUCCESS = 'ALL_LOAD_SUCCESS';
export const ALL_LOAD_FAILURE = 'ALL_LOAD_FAILURE';

export const CAT_SEARCHED_SUCCESS = 'CAT_SEARCHED_SUCCESS';
export const BAND_SEARCHED_SUCCESS = 'BAND_SEARCHED_SUCCESS';
export const USER_SEARCHED_SUCCESS = 'USER_SEARCHED_SUCCESS';
