import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Form, Input, Button } from "antd";

import useInput from "../../hooks/useInputs";
import { removeAllSessionStorage } from "../../utils/login";
import usePortals from "../../hooks/usePortals";
import { CAHNGE_LOGIN_INFO_REQUEST, POPUP_CASE_CHANGE, POPUP_LOGOUT } from "../../Module/type";
import catmosLogo from "../../assets/img_logo_168.png";
import { CustomPopUpWrapper } from "../../style/theme";

const CustomMain = styled.main`
  width: 100%;
  height: 100%;
`;

const CustomForm = styled(Form)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #f0f2f5;
  text-align: center;
  width: 465px;
  input {
    width: 300px;
    margin-top: 10px;
    background: #e8f0fe;
  }
  label {
    white-space: nowrap
    display: inline-block;
    margin-top: 20px;
    margin-right: 30px;
  }
  .ant-input-affix-wrapper {
    margin-top: 10px;
    input {
      margin-top: 0;
    }
  }
`;

const CustominputWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomInputPassword = styled(Input.Password)`
  background: #e8f0fe;
  width: 300px;
`;

const CustomButton = styled(Button)`
  margin: 30px auto 0;
  width: 30%;
`;

const CustomSpan = styled.div`
  font-size: 10px;
  text-align: right;
`;

const CustomLogo = styled.h5`
  position: absolute;
  left: 0;
  top: 60px;
  margin-left: 16px;
  width: 106px;
  height: 18px;
  background-image: url(${catmosLogo});
  background-size: cover;
`;

function LoginInfo() {
  const { isLogInPopUp } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();

  const [nowPassword, setNowPassword] = useInput("");
  const [newPassword, setNewPassword] = useInput("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useInput("");
  const [isAlert, setIsAlert] = useState(false);
  const formRef = useRef();
  const btnRef = useRef();
  const newPasswordConfirmPass = newPassword === newPasswordConfirm;

  const { RenderModals } = usePortals();

  const validatePasswordPattern = (pwd) => {
    const validateNumber = /[0-9]/;
    const validateAlphabet = /[a-zA-Z]/;
    const validateCharacters = /[~!@#$%^&*()_+|<>?:{}]/;
    if (
      validateNumber.test(pwd) &&
      validateAlphabet.test(pwd) &&
      validateCharacters.test(pwd) &&
      pwd.length < 16 &&
      pwd.length > 6
    ) {
      return true;
    }
  };

  const alertPopUpText = () => {
    switch (isLogInPopUp) {
      case true:
        return "비밀번호 변경이 완료되었습니다.";
      case false:
        return "현재 비밀번호 입력이 잘못되었습니다. 다시 입력해주세요..";
      case "notsame":
        return "비밀번호가 불일치 합니다. 다시 입력해주세요";
      case "incorrect":
        return "입력하신 비밀번호가 올바르지 않습니다. 다시 입력해주세요";
      default:
        return null;
    }
  };

  const submitPopUp = newPasswordConfirmPass && validatePasswordPattern(newPassword);
  const notSamePopUp = !newPasswordConfirmPass;
  const inCorrectPopUp = newPasswordConfirmPass && !validatePasswordPattern(newPassword);

  const onSubmit = useCallback(() => {
    const data = {
      userPwNew: newPassword,
      userPwOld: nowPassword,
    };
    if (notSamePopUp) {
      dispatch({
        type: POPUP_CASE_CHANGE,
        payload: "notsame",
      });
    }
    if (submitPopUp) {
      dispatch({
        type: CAHNGE_LOGIN_INFO_REQUEST,
        data,
      });
    }
    if (inCorrectPopUp) {
      dispatch({
        type: POPUP_CASE_CHANGE,
        payload: "incorrect",
      });
    }

    formRef.current.blur();

    return setIsAlert(true);
  }, [nowPassword, newPassword, newPasswordConfirm]);

  useEffect(() => {
    if (isAlert) {
      btnRef.current.focus();
    }
  }, [isAlert]);

  const popUpclose = () => {
    setIsAlert(false);
    dispatch({
      type: POPUP_CASE_CHANGE,
      payload: null,
    });
    if (isLogInPopUp === true) {
      removeAllSessionStorage();
      dispatch({
        type: POPUP_LOGOUT,
      });
    }
  };

  return (
    <>
      <RenderModals>
        {isAlert && (
          <CustomPopUpWrapper>
            <div>
              <p>{alertPopUpText()}</p>
              <Button ref={btnRef} type="button" onClick={() => popUpclose()}>
                확인
              </Button>
            </div>
          </CustomPopUpWrapper>
        )}
      </RenderModals>
      <button type="button" onClick={() => history.push("/main")}>
        <CustomLogo />
      </button>
      <CustomMain role="main">
        <CustomForm onFinish={onSubmit}>
          <h3>관리자 페이지 비밀번호 변경</h3>
          <CustominputWrapper>
            <label>현재 비밀번호</label>
            <CustomInputPassword name="id" type="text" placeholder="현재 비밀번호" onChange={setNowPassword} />
          </CustominputWrapper>
          <CustominputWrapper>
            <label>새 비밀번호</label>
            <CustomInputPassword name="newpw" placeholder="비밀번호" autoComplete="on" onChange={setNewPassword} />
          </CustominputWrapper>
          <CustomSpan>
            <span>5자~16자의 숫자, 영문(소문자, 대문자), 특수문자(!, @, #, $, %, ^, &, *’) 입력</span>
          </CustomSpan>
          <CustominputWrapper>
            <label>새 비밀번호 확인</label>
            <CustomInputPassword
              name="newpwconfirm"
              placeholder="비밀번호 확인"
              autoComplete="on"
              onChange={setNewPasswordConfirm}
            />
          </CustominputWrapper>
          <CustomButton htmlType="submit" ref={formRef}>
            확인
          </CustomButton>
        </CustomForm>
      </CustomMain>
    </>
  );
}

export default LoginInfo;
