import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { UserOutlined, MobileOutlined } from "@ant-design/icons";
import DetailPopUpCatTableImg from "../DetailPopUpCatTableImg";

const CustomProfileWrapper = styled.div`
  position: relative;
  padding-top: 50px;
  padding-bottom: 30px;
  flex: 1;
  text-align: center;
  background: #f2f2f2;
  min-width: 300px;
`;

const CustomUSerInfoWrapper = styled.div`
  width: 100%;
  margin-top: 20%;
  margin-left: 10%;
  > div {
    display: flex;
    > div {
      :first-of-type {
        width: 20%;
      }
      :last-of-type {
        width: 60%;
        text-align: left;
        > div {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
`;

const CustomFlexWrapper = styled.div`
  display: flex;
  padding: 0 4%;
  align-items: center;
  :nth-child(2) {
    margin-top: 20px;
  }
`;

const CutomFlexInner = styled.div`
  display: flex;
  font-size: 20px;
`;

const CutomFlexInnerRight = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const CustomDivFontSize = styled.div`
  font-size: 12px;
`;

function CatDetailPopUpProfile() {
  const { catDetailData } = useSelector((state) => state.cat);
  return (
    <CustomProfileWrapper>
      <DetailPopUpCatTableImg number={catDetailData?.catImageFileSeqNo} size={180} />
      <CustomUSerInfoWrapper>
        <CustomFlexWrapper>
          <CutomFlexInner>
            <UserOutlined />
          </CutomFlexInner>
          <CutomFlexInnerRight>
            <div>{catDetailData?.userNm}</div>
            <CustomDivFontSize>{`(${catDetailData?.userEmail})`}</CustomDivFontSize>
          </CutomFlexInnerRight>
        </CustomFlexWrapper>
        <CustomFlexWrapper>
          <CutomFlexInner>
            <MobileOutlined />
          </CutomFlexInner>
          <CutomFlexInnerRight>
            <div>{catDetailData?.bandSn}</div>
            <CustomDivFontSize>{catDetailData?.bandVersion}</CustomDivFontSize>
          </CutomFlexInnerRight>
        </CustomFlexWrapper>
      </CustomUSerInfoWrapper>
    </CustomProfileWrapper>
  );
}

export default CatDetailPopUpProfile;
