import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { customInfoListCss, customInfoTitleCss } from "../../style/theme";
import { regDateToString } from "../../utils/tostring";
import useEmailIcon from "../../hooks/useEmailIcon";

const CustomPopUpInner = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 0 20px;
`;

const CustomInfoList = styled.div`
  ${customInfoListCss}
  padding:20px 0 0;
  width: 100%;
  p {
    display: flex;
    align-items: center;
  }
`;

const CustomInfoTitle = styled.span`
  ${customInfoTitleCss}
`;

const EmailIcon = styled.span`
  display: inline-block;
  margin-left: 4px;
  height: 14px;
`;

function UserDetailInfo({ userInfo, snsList }) {
  return (
    <CustomPopUpInner>
      <CustomInfoList>
        <p>
          <CustomInfoTitle>이름</CustomInfoTitle>
          {userInfo?.userNm}
        </p>
        <p>
          <CustomInfoTitle>가입일</CustomInfoTitle>
          {regDateToString(userInfo?.regDt)}
        </p>
      </CustomInfoList>
      <CustomInfoList>
        <p>
          <CustomInfoTitle>이메일 계정</CustomInfoTitle>
          {userInfo?.userEmail}
          {snsList && snsList.map((sns) => <EmailIcon key={sns.email}>{useEmailIcon(sns.snsProvider)}</EmailIcon>)}
        </p>
      </CustomInfoList>
    </CustomPopUpInner>
  );
}

UserDetailInfo.propTypes = {
  userInfo: PropTypes.object,
  snsList: PropTypes.array,
};

UserDetailInfo.defaultProps = {
  userInfo: null,
  snsList: [],
};

export default UserDetailInfo;
