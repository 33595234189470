import React, { useState, useCallback, useEffect } from "react";
import { Radio } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import RangePickers from "./RangePickers";

const dateArray = [
  {
    id: 1,
    title: "1일",
    value: "day",
  },
  {
    id: 2,
    title: "1주일",
    value: "week",
  },
  {
    id: 3,
    title: "1개월",
    value: "month",
  },
];

const toUtc = (number) => Date.parse(number) / 1000;

function DatePickers({ isRadio, searchDate, setSearchDate, name }) {
  const [radioValue, setRadioValue] = useState(null);

  const dateResetHandler = useCallback(() => {
    setRadioValue(null);
  }, [radioValue]);

  // const toUtc = (date) => new Date(date).toLocaleString();

  const useStateHandler = useCallback(
    (value) => {
      const startDate = toUtc(moment(value[0], "YY.MM.DD").format("YYYY-MM-DD"));
      const endDate = toUtc(moment(value[1], "YY.MM.DD").format("YYYY-MM-DD"));

      if (name === "search") {
        return setSearchDate({
          ...searchDate,
          [`${name}StartDt`]: startDate,
          [`${name}EndDt`]: endDate,
        });
      }
      setSearchDate({
        ...searchDate,
        [`${name}Start`]: startDate,
        [`${name}End`]: endDate,
      });
    },

    [radioValue, searchDate],
  );

  const dateHandler = (value, string) => {
    if (string[0] === "" || string[1] === "") {
      return setSearchDate({});
    }

    if (!radioValue) {
      return useStateHandler(string);
    }
  };

  const radioHandler = useCallback(
    (event) => {
      event.preventDefault();
      const { value } = event.target;
      setRadioValue(value);
      useStateHandler(name, value);
      const newDate = new Date();
      if (name === "search") {
        if (value === radioValue) {
          setSearchDate({});
          return dateResetHandler();
        }

        if (value === "day") {
          return setSearchDate({
            ...searchDate,
            [`${name}StartDt`]: toUtc(moment(newDate).subtract(1, 0).format("YYYY-MM-DD")),
            [`${name}EndDt`]: toUtc(new Date()),
          });
        }

        return setSearchDate({
          ...searchDate,
          [`${name}StartDt`]: toUtc(moment(newDate).subtract(1, value).format("YYYY-MM-DD")),
          [`${name}EndDt`]: toUtc(new Date()),
        });
      }

      if (value === "day") {
        setSearchDate({
          ...searchDate,
          [`${name}Start`]: toUtc(moment(newDate).subtract(1, 0).format("YYYY-MM-DD")),
          [`${name}End`]: toUtc(new Date()),
        });
      } else {
        setSearchDate({
          ...searchDate,
          [`${name}Start`]: toUtc(moment(newDate).subtract(1, value).format("YYYY-MM-DD")),
          [`${name}End`]: toUtc(new Date()),
        });
      }

      if (value === radioValue) {
        setSearchDate({});
        return dateResetHandler();
      }
    },
    [radioValue],
  );

  useEffect(() => {
    if (Object.keys(searchDate).length === 0) {
      setRadioValue(null);
    }
  }, [searchDate]);

  return (
    <>
      <RangePickers onChangeHandler={dateHandler} searchDate={searchDate} />
      {isRadio && (
        <Radio.Group value={radioValue} onChange={radioHandler}>
          {dateArray.map((date, i) => (
            <Radio.Button
              value={date.value}
              onClick={radioHandler}
              key={date.id}
              style={{ margin: i === 1 && "0 7px" }}
            >
              {date.title}
            </Radio.Button>
          ))}
        </Radio.Group>
      )}
    </>
  );
}

DatePickers.propTypes = {
  isRadio: PropTypes.bool,
  searchDate: PropTypes.object.isRequired,
  setSearchDate: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

DatePickers.defaultProps = {
  isRadio: true,
};

export default DatePickers;
