import moment from "moment-timezone";

export const smallThanTen = (value) => {
  if (Number(value) < 10) return `0${value}`;
  return value;
};

export const boolToString = (value) => {
  if (value) {
    return "O";
  }
  return "X";
};

export const genderToString = (value) => {
  if (value === "M") {
    return "남";
  }
  return "여";
};

export const repeatToString = (value) => {
  switch (value) {
    case 0:
      return "완료";
    case 1:
      return "예약";
    default:
      return "완료";
  }
};

export const langCodeToString = (value) => {
  if (value === 0) {
    return "한국어";
  }
  return "영어";
};

export const langCodeToStringEnglish = (value) => {
  switch (value) {
    case 0:
      return "한국어";
    case 1:
      return "English";
    default:
      return "한국어";
  }
};

export const regDateToString = (regDate) => {
  const newDate = new Date(regDate * 1000);

  return moment(newDate).tz("Asia/Seoul").format("YYYY.MM.DD");
};

export const ageToKor = (age) => {
  switch (true) {
    case age < 5:
      return `${age}개월 (야깽이)`;
    case age < 24:
      return `${age}개월 (주니어)`;
    case age < 60:
      return `${age}개월 (성묘)`;
    case age < 156:
      return `${age}개월 (중년)`;
    case age < 400:
      return `${age}개월 (노령)`;
    default:
      return "전체";
  }
};

export const ageToEng = (age) => {
  switch (true) {
    case age < 7:
      return `${age}개월 (kitten)`;
    case age < 36:
      return `${age}개월 (Junior)`;
    case age < 84:
      return `${age}개월 (adult)`;
    case age < 132:
      return `${age}개월 (mature)`;
    case age < 180:
      return `${age}개월 (senior)`;
    case age < 400:
      return `${age}개월 (super seniors)`;
    default:
      return "전체";
  }
};

export const weightToKor = (weightType) => {
  switch (weightType) {
    case 1:
      return "마른";
    case 2:
      return "정상";
    case 3:
      return "비만경향";
    case 4:
      return "뚱냥";
    default:
      return "전체";
  }
};

export const hairTypeToKor = (hairType) => {
  switch (hairType) {
    case 1:
      return "단묘";
    case 2:
      return "장묘";
    default:
      return "전체";
  }
};

export const toUtc = (number) => Date.parse(number) / 1000;
