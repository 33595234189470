import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import qs from 'qs';

import { posts, postsForm } from './index';

import {
  GET_BAND_LIST_DATA_REQUEST,
  GET_BAND_LIST_DATA_SUCCESS,
  GET_BAND_LIST_DATA_FAILURE,
  GET_BAND_DETAIL_DATA_REQUEST,
  GET_BAND_DETAIL_DATA_SUCCESS,
  GET_BAND_DETAIL_DATA_FAILURE,
  GET_BAND_FW_REQUEST,
  GET_BAND_FW_SUCCESS,
  GET_BAND_FW_FAILURE,
  GET_BAND_INFO_FW_REQUEST,
  GET_BAND_INFO_FW_SUCCESS,
  GET_BAND_INFO_FW_FAILURE,
  POST_UPDATE_BAND_FW_REQUEST,
  POST_UPDATE_BAND_FW_SUCCESS,
  POST_UPDATE_BAND_FW_FAILURE,
  BAND_SEARCHED_SUCCESS,
} from '../type';

function getBandListAPI(data) {
  return posts.get('device/list', {
    params: data,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, skipNulls: true }, ''),
  });
}

function getBandDetailDataAPI(bandSn) {
  return posts.get(`device/info/${bandSn}`);
}

function postBandFwUpdateAPI(data) {
  const firmware = new FormData();
  firmware.append('firmware', data.firmwareList);
  const config = {
    headers: {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data',
    },
    params: {
      firmware: null,
      majorVersion: data.majorVersion,
      minorVersion: data.minorVersion,
    },
  };
  return postsForm.post('device/firmware', firmware, config);
}

function getBandFwAPI() {
  return posts.get('device/firmware');
}

function getBandInfoFwAPI() {
  return posts.get('device/firmware-info');
}

function* getBandListData(action) {
  try {
    const result = yield call(getBandListAPI, action.data, action.searched);
    yield put({
      type: GET_BAND_LIST_DATA_SUCCESS,
      data: result.data,
    });
    if (action.searched) {
      yield put({
        type: BAND_SEARCHED_SUCCESS,
      });
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_BAND_LIST_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

function* getBandDetailData(action) {
  try {
    const result = yield call(getBandDetailDataAPI, action.userUuid);
    yield put({
      type: GET_BAND_DETAIL_DATA_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_BAND_DETAIL_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

function* postBandFwUpdate(action) {
  try {
    const result = yield call(postBandFwUpdateAPI, action.data);
    yield put({
      type: POST_UPDATE_BAND_FW_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: POST_UPDATE_BAND_FW_FAILURE,
      error: e.response.data,
    });
  }
}

function* getBandFw() {
  try {
    const result = yield call(getBandFwAPI);
    yield put({
      type: GET_BAND_FW_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_BAND_FW_FAILURE,
      error: e.response.data,
    });
  }
}

function* getBandInfoFw() {
  try {
    const result = yield call(getBandInfoFwAPI);
    yield put({
      type: GET_BAND_INFO_FW_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_BAND_INFO_FW_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchGetBandListData() {
  yield takeLatest(GET_BAND_LIST_DATA_REQUEST, getBandListData);
}

function* watchGetBandDetailData() {
  yield takeLatest(GET_BAND_DETAIL_DATA_REQUEST, getBandDetailData);
}
function* watchPostBandFwUpdate() {
  yield takeLatest(POST_UPDATE_BAND_FW_REQUEST, postBandFwUpdate);
}
function* watchGetBandFw() {
  yield takeLatest(GET_BAND_FW_REQUEST, getBandFw);
}
function* watchGetBandInfoFw() {
  yield takeLatest(GET_BAND_INFO_FW_REQUEST, getBandInfoFw);
}

export default function* BandPostSaga() {
  yield all([
    fork(watchGetBandListData),
    fork(watchGetBandDetailData),
    fork(watchPostBandFwUpdate),
    fork(watchGetBandFw),
    fork(watchGetBandInfoFw),
  ]);
}
