import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";

import BandDetailInfo from "./BandDetailInfo";
import DetailPopUpCatTable from "../DetailPopUpCatTable";
import { customPopUpWrapperCss, customPopUpCloseCss, customTableCss, detailTableCss } from "../../style/theme";
import { regDateToString } from "../../utils/tostring";
import useEmailIcon from "../../hooks/useEmailIcon";

const CustomPopUpWrapper = styled.div`
  ${customPopUpWrapperCss}
  h5 {
    font-size: 16px;
    font-weight: bold;
    margin: 40px 0 0 10px;
  }
`;

const CustomPopUpInner = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const EmailIcon = styled.span`
  display: inline-block;
  margin-left: 4px;
  height: 14px;
  color: #444 !important;
  > span {
    color: #444 !important;
  }
`;

const CustomPopUpCloseButton = styled.button`
  ${customPopUpCloseCss}
`;

const CustomTable = styled.table`
  ${customTableCss}
  ${detailTableCss}
`;

const columns = [
  {
    title: "회원명",
    dataIndex: "userNm",
  },
  {
    title: "이메일계정",
    dataIndex: "userEmail",
  },
  {
    title: "가입일",
    dataIndex: "regDt",
  },
];

function BandDetailPopUp({ bandDetailOpen, bandDetailToggle }) {
  const { bandDetailDatabandInfo, bandDetailDataLoading } = useSelector((state) => state.band);

  return (
    <CustomPopUpWrapper
      style={{
        display: bandDetailOpen ? "block" : "none",
        overflowY: bandDetailOpen && "scroll",
      }}
    >
      {bandDetailDatabandInfo && !bandDetailDataLoading && (
        <>
          <h4>밴드 정보</h4>
          <BandDetailInfo />
          <>
            <h5>등록 회원</h5>
            <CustomPopUpInner>
              <CustomTable>
                <thead>
                  <tr>
                    {columns.map((v, i) => (
                      <th key={i}>{v.title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div title={bandDetailDatabandInfo.userNm}>{bandDetailDatabandInfo.userNm}</div>
                    </td>
                    <td>
                      <div title={bandDetailDatabandInfo.userEmail}>
                        {bandDetailDatabandInfo.userEmail}
                        {bandDetailDatabandInfo.snsList &&
                          bandDetailDatabandInfo.snsList.map((sns) => (
                            <EmailIcon key={sns.email}>{useEmailIcon(sns.snsProvider)}</EmailIcon>
                          ))}
                      </div>
                    </td>
                    <td>
                      <div title={regDateToString(bandDetailDatabandInfo.userRegDt)}>
                        {regDateToString(bandDetailDatabandInfo.userRegDt)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </CustomTable>
            </CustomPopUpInner>
          </>
          <>
            <h5>등록된 고양이 </h5>
            <CustomPopUpInner>
              <DetailPopUpCatTable data={bandDetailDatabandInfo} size={40} />
            </CustomPopUpInner>
          </>
          <CustomPopUpCloseButton type="button" onClick={bandDetailToggle}>
            <CloseOutlined />
          </CustomPopUpCloseButton>
        </>
      )}
    </CustomPopUpWrapper>
  );
}

BandDetailPopUp.propTypes = {
  bandDetailOpen: PropTypes.bool.isRequired,
  bandDetailToggle: PropTypes.func.isRequired,
};

export default BandDetailPopUp;
