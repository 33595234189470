/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import styled from "styled-components";
import { Layout } from "antd";
import { Alarm, Band, Cat, Login, Main, User, LoginInfo } from "../pages";

import Nav from "../components/Nav";
import PrivateRouter from "./PrivateRouter";

const CustomLayout = styled(Layout)`
  margin-left: 50px;
  padding-right: 50px;
  min-height: 100vh;
  @media (max-width: 991px) {
    margin-left: 40px;
    padding-right: 30px;
  }
`;

function Router() {
  const location = useLocation().pathname;
  const token = window.sessionStorage.getItem("access_token");

  axios.defaults.baseURL =
    process.env.NODE_ENV === "development" ? "http://127.0.0.1:8080/rest/admin/" : "https://api.petmos.pet/rest/admin/";

  return (
    <>
      <Layout>
        <Nav location={location} />
        <CustomLayout>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                if (token) {
                  return <Redirect to="/main" />;
                }
                return <Redirect to="/login" />;
              }}
            />
            <Route exact path="/login" component={Login} />
            <PrivateRouter exact path="/band" component={Band} />
            <PrivateRouter exact path="/cat" component={Cat} />
            <PrivateRouter exact path="/main" component={Main} />
            <PrivateRouter exact path="/user" component={User} />
            <PrivateRouter exact path="/alarm" component={Alarm} />
            <PrivateRouter exact path="/login/info" component={LoginInfo} />
          </Switch>
        </CustomLayout>
      </Layout>
    </>
  );
}

export default Router;
