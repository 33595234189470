import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";

import CatDetailPopUpInfo from "./CatDetailPopUpInfo";
import CatDetailPopUpProfile from "./CatDetailPopUpProfile";
import { customPopUpWrapperCss, customPopUpCloseCss } from "../../style/theme";

const CustomPopUpWrapper = styled.div`
  ${customPopUpWrapperCss}
  padding: 25px 50px 20px 30px;
`;

const CustomPopUpInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 24px;
`;

const CustomPopUpCloseButton = styled.button`
  ${customPopUpCloseCss}
`;

function CatDetailPopUp({ catDetailOpen, catDetailToggle, ageLang }) {
  const { catDetailDataLoading } = useSelector((state) => state.cat);

  return (
    <CustomPopUpWrapper
      style={{
        display: catDetailOpen ? "block" : "none",
        overflowY: catDetailOpen && "scroll",
      }}
    >
      {!catDetailDataLoading && (
        <>
          <h4>고양이 정보</h4>
          <CustomPopUpInner>
            <CatDetailPopUpProfile />
            <CatDetailPopUpInfo ageLang={ageLang} />
          </CustomPopUpInner>
          <CustomPopUpCloseButton type="button" onClick={catDetailToggle}>
            <CloseOutlined />
          </CustomPopUpCloseButton>
        </>
      )}
    </CustomPopUpWrapper>
  );
}

CatDetailPopUp.propTypes = {
  catDetailOpen: PropTypes.bool.isRequired,
  catDetailToggle: PropTypes.func.isRequired,
  ageLang: PropTypes.number.isRequired,
};

export default CatDetailPopUp;
