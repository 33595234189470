/* eslint-disable global-require */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DetailPopUpCatTableImg from "./DetailPopUpCatTableImg";
import { customTableCss, detailTableCss } from "../style/theme";
import { genderToString, boolToString, smallThanTen, regDateToString, weightToKor } from "../utils/tostring";

import { detailCatDatacolumns } from "../pages/User/config";

const CustomTable = styled.table`
  ${customTableCss}
  ${detailTableCss}
  col:first-of-type {
    width: 10%;
  }
  col:nth-of-type(2) {
    width: 13%;
  }
  col:nth-of-type(3) {
    width: 7%;
  }
  col:nth-of-type(4) {
    width: 7%;
  }
  col:nth-of-type(5) {
    width: 16%;
  }
  col:nth-of-type(6) {
    width: 10%;
  }
  col:nth-of-type(7) {
    width: 9%;
  }
  col:nth-of-type(8) {
    width: 6%;
  }
  col:last-of-type {
    width: 10%;
  }
  th {
    padding: 5px;
  }
  td {
    font-size: 12px;
    max-width: 145px;
    vertical-align: middle;
    > div {
      max-height: 60px !important;
      p {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;

function DetailPopUpCatTable({ data, size }) {
  const changeKor = (value) => {
    switch (value) {
      case "아깽이":
        return "영유아기";
      case "주니어":
        return "아동청소년기";
      case "성묘":
        return "청년기";
      case "중년":
        return "중장년기";
      case "노령":
        return "노년기";
      default:
        return value;
    }
  };
  return (
    <CustomTable>
      <colgroup>
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <thead>
        <tr>
          {detailCatDatacolumns.map((v, i) => (
            <th key={i}>{v.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.isArray(data) ? (
          data.map((v, i) => (
            <tr key={i}>
              <td style={{ padding: "10px 0" }}>
                <DetailPopUpCatTableImg number={v.catImageFileSeqNo} size={size} />
              </td>
              <td>
                <div title={v.catNm}>{v.catNm}</div>
              </td>
              <td>
                <div title={genderToString(v.catGenderCode)}>{genderToString(v.catGenderCode)}</div>
              </td>
              <td>
                <div title={boolToString(v.neutralizationYn)}>{boolToString(v.neutralizationYn)}</div>
              </td>
              <td>
                <div title={v.catBreedKrNm || data.breedKrNm}>{v.catBreedKrNm || data.breedKrNm}</div>
              </td>
              <td>
                <div title={`${v.catBirthYear}.${smallThanTen(v.catBirthMonth)}.${smallThanTen(v.catBirthDay)}`}>
                  {`${v.catBirthYear}.${smallThanTen(v.catBirthMonth)}.${smallThanTen(v.catBirthDay)}`}
                </div>
              </td>
              <td>
                <div title={`${v.catAgeMonth || v.catBirthMonth}개월 (${changeKor(v.catAgeStatusKr)})`}>
                  <p>{`${v.catAgeMonth || v.catBirthMonth}개월`}</p>
                  <p>{`(${changeKor(v.catAgeStatusKr)})`}</p>
                </div>
              </td>
              <td>
                <div title={boolToString(v.vaccinationYn)}>{boolToString(v.vaccinationYn)}</div>
              </td>
              <td>
                <div title={`${v.catWeight}kg (${weightToKor(v.catWeightType)})`}>
                  <p>{`${v.catWeight}kg`}</p>
                  <p>{`(${weightToKor(v.catWeightType || v.catWeight)})`}</p>
                </div>
              </td>
              <td>
                <div title={regDateToString(v.regDt)}>{regDateToString(v.regDt)}</div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td style={{ padding: "10px 0" }}>
              <DetailPopUpCatTableImg number={data.catImageFileSeqNo} size={size} />
            </td>
            <td>
              <div title={data.catNm}>{data.catNm}</div>
            </td>
            <td>
              <div title={genderToString(data.catGenderCode)}>{genderToString(data.catGenderCode)}</div>
            </td>
            <td>
              <div title={boolToString(data.neutralizationYn)}>{boolToString(data.neutralizationYn)}</div>
            </td>
            <td>
              <div title={data.catBreedKrNm || data.breedKrNm}>{data.catBreedKrNm || data.breedKrNm}</div>
            </td>
            <td>
              <div title={`${data.catBirthYear}.${smallThanTen(data.catBirthMonth)}.${smallThanTen(data.catBirthDay)}`}>
                {`${data.catBirthYear}.${smallThanTen(data.catBirthMonth)}.${smallThanTen(data.catBirthDay)}`}
              </div>
            </td>
            <td>
              <div title={`${data.catMoonage}개월 (${changeKor(data.catAgeStatusKr)})`}>
                <p>{`${data.catAgeMonth || data.catBirthMonth}개월`}</p>
                <p>{`(${changeKor(data.catAgeStatusKr)})`}</p>
              </div>
            </td>
            <td>
              <div title={boolToString(data.vaccinationYn)}>{boolToString(data.vaccinationYn)}</div>
            </td>
            <td>
              <div title={`${data.catWeight}kg (${weightToKor(data.catWeightType || data.catWeight)})`}>
                <p>{`${data.catWeight}kg`}</p>
                <p>{`(${weightToKor(data.catWeightType || data.catWeight)})`}</p>
              </div>
            </td>
            <td>
              <div title={regDateToString(data.regDt)}>{regDateToString(data.regDt)}</div>
            </td>
          </tr>
        )}
      </tbody>
    </CustomTable>
  );
}

DetailPopUpCatTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  size: PropTypes.number.isRequired,
};

export default DetailPopUpCatTable;
