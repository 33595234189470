import { all, fork } from "redux-saga/effects";
import axios from "axios";
import qs from "qs";
import dotenv from "dotenv";
import authSaga from "./auth";
import mainSaga from "./main";
import catPostSaga from "./cat";
import userPostSaga from "./user";
import bandPostSaga from "./band";
import alarmPostSaga from "./alarm";

import store from "../../store";

import { logOutRedirectLogin } from "../../utils/login";

import { ALL_LOAD_REQUEST, ALL_LOAD_SUCCESS, ALL_LOAD_FAILURE, POPUP_LOGOUT } from "../type";

dotenv.config();

axios.defaults.baseURL = process.env.REACT_APP_API;

export const paramSerializerOptions = {
  paramsSerializer: (params) => qs.stringify(params, { allowDots: true, arrayFormat: "comma", skipNulls: true }, ""),
};

const setInterceptors = (instance, formdata) => {
  instance.interceptors.request.use(
    (config) => {
      const token = window.sessionStorage.getItem("access_token");
      if (token) {
        if (formdata) {
          config.headers.token = token;
          // config.headers['Content-Type'] = 'multipart/form-data';
        } else {
          config.headers.token = token;
        }
      }
      store.dispatch({
        type: ALL_LOAD_REQUEST,
      });
      return config;
    },
    (error) => {
      Promise.reject(error);
      store.dispatch({
        type: ALL_LOAD_FAILURE,
      });
      if (error.response.status === 401) {
        store.dispatch({
          type: POPUP_LOGOUT,
        });
        logOutRedirectLogin();
      }
    },
  );
  instance.interceptors.response.use(
    (response) => {
      Promise.resolve(response);
      store.dispatch({
        type: ALL_LOAD_SUCCESS,
      });
      return response;
    },
    (error) => {
      Promise.reject(error);
      store.dispatch({
        type: ALL_LOAD_FAILURE,
      });
      if (error.response.status === 401) {
        store.dispatch({
          type: POPUP_LOGOUT,
        });
        logOutRedirectLogin();
      }
    },
  );

  return instance;
};

export const createInstanceWithAuth = () => {
  const instance = axios.create({
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  return setInterceptors(instance);
};

export const createInstanceWithAuthPostFormData = () => {
  const instance = axios.create({
    Accept: "*/*",
    headers: {
      "Content-Type": "multipart/form-data; charset=utf-8",
    },
  });

  return setInterceptors(instance, "formdata");
};

export const posts = createInstanceWithAuth("posts");
export const postsForm = createInstanceWithAuthPostFormData("postsForm");

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(mainSaga),
    fork(catPostSaga),
    fork(userPostSaga),
    fork(bandPostSaga),
    fork(alarmPostSaga),
  ]);
}
