import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  regDateToString,
  repeatToString,
  weightToKor,
  hairTypeToKor,
  langCodeToStringEnglish,
} from "../../utils/tostring";
import { columns } from "../../pages/Alarm/config";
import { customTableCss } from "../../style/theme";

const CustomTable = styled.table`
  ${customTableCss}
`;

function MainTableHandler({ data }) {
  return (
    <>
      <CustomTable>
        <thead>
          <tr>
            {columns.map((v, i) => (
              <th key={i}>{v.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((v, i) => (
            <tr key={i}>
              <td>
                <div title={repeatToString(v.reserveStatus)}>{repeatToString(v.reserveStatus)}</div>
              </td>
              <td>
                <div title={regDateToString(v.regDt)}>{regDateToString(v.regDt)}</div>
              </td>
              <td>
                <div title={regDateToString(v.compDt)}>{regDateToString(v.compDt)}</div>
              </td>
              <td>
                <div title={v.message}>{v.message}</div>
              </td>
              <td>
                <div title={langCodeToStringEnglish(v.langCode)}>{langCodeToStringEnglish(v.langCode)}</div>
              </td>
              <td>
                <div title={v.catAgeStatusKr ? v.catAgeStatusKr : "전체"}>
                  {v.catAgeStatusKr ? v.catAgeStatusKr : "전체"}
                </div>
              </td>
              <td>
                <div title={`${weightToKor(v.catWeightType)}`}>{`${weightToKor(v.catWeightType)}`}</div>
              </td>
              <td>
                <div title={hairTypeToKor(v.hairType)}>{hairTypeToKor(v.hairType)}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTable>
    </>
  );
}

MainTableHandler.propTypes = {
  data: PropTypes.array,
};

MainTableHandler.defaultProps = {
  data: [],
};

export default MainTableHandler;
