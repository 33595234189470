/* eslint-disable object-shorthand */
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Upload, Input } from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { isNoString } from "../../utils/validate";
import { customPopUpWrapperCss, customTableCss, customPopUpCloseCss } from "../../style/theme";
import { POST_UPDATE_BAND_FW_REQUEST } from "../../Module/type";

const CustomWrapper = styled.div`
  ${customPopUpWrapperCss}
  width: 500px;
  height: 270px;
  padding: 25px 25px 20px;
  background: #fafafa;
  .ant-btn {
    opacity: 1;
  }
`;
// const CustomFormWrapper = styled(Form)`
//   margin-bottom: 0;
//   padding: 0 !important;
//   .ant-form-item {
//     margin-bottom: 0;
//   }
// `;

const CustomPopUpWrapper = styled.div`
  h4 {
    font-size: 20px;
  }
`;

const CustomTable = styled.table`
  ${customTableCss}
  margin-top: 20px;
  th {
    padding: 4px 10px;
    font-size: 14px;
  }
  td {
    padding: 8px 10px;
  }
`;

const CustomUpload = styled(Upload)`
  position: relative;
  width: 350px;
  height: 30px;
  margin-left: 18px;
  display: flex;
  .ant-btn {
    position: absolute;
    left: 0;
    height: 30px;
    span {
      font-size: 12px;
    }
  }

  .ant-upload-list {
    position: absolute;
    left: 100px;
    height: 100%;
  }
  .ant-upload-list-text-container {
    height: 30px;
    .ant-upload-list-item-card-actions {
      position: absolute;
      right: -7px;
      top: 50%;
      transform: translateY(-50%);
      &:hover {
        border: none;
      }
    }
    .ant-btn {
      position: static;
      height: 30px;
      border: none;
      &:hover {
        background: none;
        border: none;
      }
    }
  }
  .ant-upload-list-item-name {
    width: 190px;
    overflow: hidden;
  }
  .ant-upload-list-item {
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 0;
  }
`;

const CustomPopUpCloseButton = styled.button`
  ${customPopUpCloseCss}
  top: 23px;
`;

const CustomUploadButton = styled(Button)`
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: 30px;
`;

const CustomInputAreaWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: 30px;
  overflow: hidden;
  h5 {
    white-space: nowrap;
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
  }
`;

const CustomInputArea = styled.div`
  display: flex;
  input {
    width: 32px;
    height: 30px;
    padding: 4px 0;
    text-align: center;
    font-size: 12px;
  }
`;

const CustomDot = styled.div`
  position: relative;
  width: 8px;
  height: 30px;
  &:after {
    display: block;
    content: "";
    position: absolute;
    left: 1px;
    bottom: 2px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #000;
  }
`;

const CustomInput = styled(Input)`
  width: 45px;
`;

function BandUploadPopUp({ bandUploadOpen, bandUploadToggle }) {
  const dispatch = useDispatch();
  const { bandFwData, bandInfo } = useSelector((state) => state.band);

  //   const [form] = Form.useForm();
  const [fileData, setFileData] = useState({ fileList: [] });
  const [vesrion, setVersion] = useState({
    majorVersion: "",
    minorVersion: "",
  });
  const [isVersionTrue, setIsVersionTrue] = useState(false);
  const { fileList } = fileData;

  const props = {
    accept: "application/zip" || "application/x-zip-compressed",
    multiple: false,
    onRemove: () => {
      setFileData({ fileList: [] });
    },
    beforeUpload: (file) => {
      setFileData({ fileList: [file] });
      return false;
    },

    fileList,
  };
  const onReset = () => {
    bandUploadToggle();
    // form.resetFields();
    setVersion({
      majorVersion: "",
      minorVersion: "",
    });
    setFileData({ fileList: [] });
  };

  const requestPostData = (data) => {
    try {
      dispatch({
        type: POST_UPDATE_BAND_FW_REQUEST,
        data,
      });
    } catch (e) {
      return console.log(e);
    }
  };

  const onSubmit = () => {
    // const formData = new FormData();
    // const firmware = formData.append('file', fileList[0]);
    const data = {
      firmwareList: fileList[0],
      ...vesrion,
    };

    requestPostData(data);

    onReset();
  };

  const InputHandler = useCallback(
    (e) => {
      const { value, name } = e.target;
      if (isNoString(value) || value > 257) {
        return setIsVersionTrue(false);
      }
      setIsVersionTrue(true);
      setVersion({
        ...vesrion,
        [name]: value,
      });
    },
    [vesrion, isVersionTrue],
  );

  return (
    <CustomWrapper
      style={{
        display: bandUploadOpen ? "block" : "none",
      }}
      bandUploadOpen={bandUploadOpen}
    >
      <CustomPopUpCloseButton type="button" onClick={() => onReset()}>
        <CloseOutlined />
      </CustomPopUpCloseButton>
      <CustomPopUpWrapper>
        <h4>F/W 업데이트</h4>
        <CustomTable>
          <thead>
            <tr>
              <th key={1}>현재버전</th>
              <th key={2}>업로드 날짜</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{bandFwData}</td>
              <td>{moment(bandInfo.regDt * 1000).format("YYYY-MM-DD")}</td>
            </tr>
          </tbody>
        </CustomTable>
        <CustomInputAreaWrapper>
          <h5 style={{ lineHeight: "30px" }}>버전명</h5>
          <CustomInputArea>
            <CustomInput name="majorVersion" maxLength={3} onChange={InputHandler} value={vesrion.majorVersion} />
            <CustomDot />
            <CustomInput name="minorVersion" maxLength={3} onChange={InputHandler} value={vesrion.minorVersion} />
          </CustomInputArea>
          <CustomUpload
            // onRemove={onRemove}
            // //   onChange={onChange}
            // accept={'application/zip' || 'application/x-zip-compressed'}
            key={1}
            {...props}
            enctype="multipart/form-data"
          >
            <Button icon={<UploadOutlined />}>파일선택</Button>
          </CustomUpload>
        </CustomInputAreaWrapper>
        <CustomUploadButton type="button" onClick={() => onSubmit()}>
          업데이트
        </CustomUploadButton>
      </CustomPopUpWrapper>
    </CustomWrapper>
  );
}

BandUploadPopUp.propTypes = {
  bandUploadOpen: PropTypes.bool.isRequired,
  bandUploadToggle: PropTypes.func.isRequired,
};

export default BandUploadPopUp;
