import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Form, Select, Input, Button, Checkbox } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import { GET_CAT_LIST_DATA_REQUEST, GET_CAT_DETAIL_DATA_REQUEST } from "../../Module/type";
import { DatePickers, SelectHandler, DetailSelector } from "../../components/Form";
import Paginations from "../../components/Form/Paginations";
import { CatDetailPopUp, CatTableHandler, CatTransferHandler } from "../../components/Cat";
import useInputs from "../../hooks/useInputs";

import { selectNeutralizationData, selectVaccinationData } from "./config";
import {
  userFormInnerCss,
  detailCustomToggleButtonCss,
  customInputCss,
  filterWrapperCss,
  hasDetailFormCss,
  customSecondTitleButton,
  labelCss,
} from "../../style/theme";

const { Option } = Select;

const CustomForm = styled(Form)`
  position: relative;
  ${hasDetailFormCss}
`;

const Margin20 = styled.div`
  margin-top: 20px;
`;

const UserFormInner = styled.div`
  ${userFormInnerCss}
`;

const FilterWrapper = styled.div`
  ${filterWrapperCss}
`;

const CustomInput = styled(Input)`
  ${customInputCss}
`;

const CutomLabel = styled.label`
  ${labelCss}
`;

const CustomButton = styled.button`
  ${detailCustomToggleButtonCss}
`;

const CustomCheckboxWrapper = styled.div`
  position: absolute;
  left: 20px;
  bottom: 10px;
  font-size: 12px;
  label {
    margin-right: 8px;
  }
`;

const CumtomRouterButton = styled.div`
  ${customSecondTitleButton}
  margin-top:40px;
`;

function Cat() {
  const dispatch = useDispatch();
  const { catListPageData, catSearched } = useSelector((state) => state.cat);

  const [filterOpen, setFilterOpen] = useState(false);
  const [page, nowPage] = useState(1);
  const [catDetailOpen, setCatDetailOpen] = useState(false);
  const [detailSearchOpen, setDetailSearchOpen] = useState(false);
  const [delYn, setDelYn] = useState(0);
  const [langCode, setLangCode] = useState(1);
  const [language, setLanguage] = useState(1);
  const [searchData, setSearchData] = useState({});
  const [searchDate, setSearchDate] = useState({});
  const [searchBirth, setSearchBirth] = useState({});
  const [searchText, setSearchText] = useInputs("");
  const [pagingSortOption, setPagingSortOption] = useState({
    sortLang: "ko",
  });
  const [postData, setPostData] = useState({});

  const onReset = () => {
    setPagingSortOption({ sortLang: "ko" });
    setDelYn(0);
    setLanguage(1);
    setLangCode(1);
    setSearchData({});
    setSearchDate({});
    setSearchBirth({});
    setSearchText({ target: { value: "" } });
  };

  const toggleFilter = useCallback(() => {
    setFilterOpen(!filterOpen);
  }, [filterOpen]);

  const catDetailToggle = useCallback(() => {
    setCatDetailOpen(!catDetailOpen);
  }, [catDetailOpen]);

  const detailToggleFilter = useCallback(() => {
    setDetailSearchOpen(!detailSearchOpen);
  }, [detailSearchOpen]);

  const useStateHandler = useCallback(
    (name, value) => {
      setSearchData({ ...searchData, [name]: value });
    },
    [searchData],
  );

  const selectOnChange = useCallback(
    (value, data) => {
      const { name } = data;
      setSearchData({ ...searchData, [name]: value });
    },
    [searchData],
  );

  const delYnHandler = useCallback(
    (e) => {
      if (e.target.checked) return setDelYn(1);
      return setDelYn(0);
    },
    [delYn],
  );

  const tableItemClicked = useCallback(
    (id) => {
      try {
        dispatch({
          type: GET_CAT_DETAIL_DATA_REQUEST,
          id,
        });
      } catch (e) {
        return console.log(e);
      }
      setCatDetailOpen(true);
    },
    [GET_CAT_DETAIL_DATA_REQUEST],
  );

  const requestListData = (data, searched) => {
    try {
      dispatch({
        type: GET_CAT_LIST_DATA_REQUEST,
        data,
        searched,
      });
    } catch (e) {
      return console.log(e);
    }
  };

  const onSubmit = useCallback(() => {
    setLanguage(langCode);

    const submitData = {
      ...postData,
      langCode,
      ...pagingSortOption,
      paging: {
        page,
        size: 20,
      },
    };
    if (Object.keys(postData).length === 0) {
      const searched = "searched";
      setPostData(submitData);
      requestListData(submitData, searched);
    } else {
      setPostData(submitData);
      requestListData(submitData);
    }
  }, [page, postData, pagingSortOption]);

  const onSearchSubmit = () => {
    const { regDtStart, regDtEnd } = searchDate;
    const { birthdayStart, birthdayEnd } = searchBirth;

    const submitData = {
      ...searchData,
      ...(regDtStart && { regDtStart }),
      ...(regDtEnd && { regDtEnd: +regDtEnd + 86400 }),
      ...(birthdayStart && { birthdayStart }),
      ...(birthdayEnd && { birthdayEnd: +birthdayEnd + 86400 }),
      searchText,
      langCode,
      delYn,
      searchType: "catNm",
      ...pagingSortOption,
      paging: {
        page: 1,
        size: 20,
      },
    };

    setLanguage(langCode);
    const searched = "searched";
    setPostData(submitData);
    requestListData(submitData, searched);
    nowPage(1);
  };

  const sortOptionHandler = (name, value) => {
    const { regDtStart, regDtEnd } = searchDate;
    const { birthdayStart, birthdayEnd } = searchBirth;

    setPagingSortOption({
      [name]: value,
    });

    const submitData = {
      ...searchData,
      ...(regDtStart && { regDtStart }),
      ...(regDtEnd && { regDtEnd: +regDtEnd + 86400 }),
      ...(birthdayStart && { birthdayStart }),
      ...(birthdayEnd && { birthdayEnd: +birthdayEnd + 86400 }),
      searchText,
      langCode,
      delYn,
      searchType: "catNm",
      [name]: value,
      paging: {
        page,
        size: 20,
      },
    };
    setPostData(submitData);
    requestListData(submitData);
  };

  return (
    <main role="main">
      <h3>고양이 관리</h3>
      <CustomForm onFinish={onSearchSubmit}>
        <UserFormInner>
          <Select defaultValue="고양이 이름" style={{ width: 120, margin: 0 }}>
            <Option value="catname">고양이 이름</Option>
          </Select>
          <CustomInput placeholder="고양이 이름 검색" value={searchText} onChange={setSearchText} />
          <Button type="button" onClick={onReset}>
            초기화
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            style={{ color: "#fff", border: "1px solid #1890ff", marginLeft: 6 }}
          >
            검색
          </Button>
        </UserFormInner>
        <Margin20 style={{ marginBottom: 8 }}>
          <DetailSelector
            selectOnChange={selectOnChange}
            setLangCode={setLangCode}
            isTransfer
            toggleFilter={toggleFilter}
            searchData={searchData}
            langCode={langCode}
          />
        </Margin20>
        {/* 상세보기 */}
        <div style={{ display: detailSearchOpen ? "block" : "none", paddingBottom: 8 }}>
          <UserFormInner style={{ marginBottom: 5 }}>
            <label>생일ㅤ</label>
            <DatePickers isRadio={false} searchDate={searchBirth} setSearchDate={setSearchBirth} name="birthday" />
            <label>중성화</label>
            <SelectHandler
              data={selectNeutralizationData}
              onChange={selectOnChange}
              isValue={searchData.neutralization}
            />
            <CutomLabel>접종</CutomLabel>
            <SelectHandler data={selectVaccinationData} onChange={selectOnChange} isValue={searchData.vaccination} />
          </UserFormInner>
          <UserFormInner style={{ marginBottom: 5 }}>
            <label>등록일</label>
            <DatePickers searchDate={searchDate} setSearchDate={setSearchDate} name="regDt" />
          </UserFormInner>
        </div>
        <CustomCheckboxWrapper style={{ display: detailSearchOpen ? "block" : "none" }}>
          <Checkbox onChange={delYnHandler} checked={delYn} />
          삭제 고양이 데이터 포함
        </CustomCheckboxWrapper>
        <CustomButton type="button" onClick={detailToggleFilter}>
          상세검색
          {detailSearchOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </CustomButton>
      </CustomForm>

      <CumtomRouterButton>
        <h5>
          {catSearched ? "검색 결과:" : "Total:"}
          {catListPageData.total}
        </h5>
      </CumtomRouterButton>
      <>
        <CatTableHandler
          onSubmit={onSubmit}
          sortOption={sortOptionHandler}
          clickHandler={tableItemClicked}
          page={page}
          ageLang={language}
          postData={postData}
          pagingSortOption={pagingSortOption}
        />
        {catListPageData && (
          <Paginations total={catListPageData?.total} onSubmit={onSubmit} page={page} nowPage={nowPage} />
        )}
        <CatDetailPopUp catDetailOpen={catDetailOpen} catDetailToggle={catDetailToggle} ageLang={langCode} />
        <FilterWrapper style={{ display: filterOpen ? "block" : "none" }}>
          <CatTransferHandler
            transferOnChange={useStateHandler}
            toggleFilter={toggleFilter}
            ageLang={langCode}
            onReset={onReset}
          />
        </FilterWrapper>
      </>
    </main>
  );
}

export default Cat;
