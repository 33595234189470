import styled, { css } from "styled-components";

export const userFormInnerCss = css`
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
  }
  button {
    height: 100%;
  }
`;

export const hasDetailFormCss = css`
  padding: 30px 20px 45px;
  margin-bottom: 30px;
`;

export const labelCss = css`
  margin-left: 15px;
`;

export const detailCustomToggleButtonCss = css`
  position: absolute;
  right: 30px;
  bottom: 10px;
  font-size: 12px;
  color: #40a9ff;
`;

export const filterWrapperCss = css`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 55;
`;

export const customSecondTitleButton = css`
  margin-top: 70px;
  font-size: 28px;
  font-weight: 800;
  h5 {
    font-size: 20px;
    font-weight: 800;
  }
`;

export const customInputCss = css`
  margin: 0 30px;
`;

export const customTableCss = css`
  margin-top: 18px;
  min-width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-spacing: 0;
  overflow: hidden;
  table-layout: fixed;
  text-align: center;
  font-size: 14px;
  th:first-child {
    border-top-left-radius: 2px;
  }

  th {
    position: relative;
    padding: 16px 20px;
    font-weight: 500;
    text-align: center;
    background: #666;
    border-bottom: 1px solid #f0f0f0;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid #ccc;
    color: #fff;
  }
  tbody {
    background: #fff;
  }
  tbody > tr {
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  tbody > tr:hover > td {
    background: #fafafa;
    transition: background 0.3s;
  }
  td {
    position: relative;
    padding: 16px 10px;
    max-width: 450px;
  }
  td > div {
    overflow: hidden;
    width: 100%;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      color: #1890ff;
    }
  }

  td > div::after {
    content: attr(title);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: transparent;
    overflow: hidden;
  }

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

export const customFlexWrapperCss = css`
  position: absolute;
  float: right;
  font-size: 10px;
`;

export const customInfoWrapperCss = css`
  margin: 20px 0 0 50px;
  padding-bottom: 20px;
  flex: 2;
`;

export const customPopUpWrapperCss = css`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  max-width: 900px;
  max-height: 530px;
  width: 80%;
  height: 60%;
  background: #fff;
  box-shadow: 2px 2px 8px rgb(0 0 0 / 50%);
  z-index: 55;
  h4 {
    font-size: 24px;
    font-weight: bold;
  }
`;

export const customInfoListCss = css`
  display: flex;
  flex-wrap: wrap;
  align-item: center;
  padding: 20px 0;
  p {
    font-size: 14px;
    flex: 1;
  }
  span {
    font-size: 14px;
  }
`;

export const customInfoTitleCss = css`
  position: relative;
  margin-right: 10px;
  padding-left: 15px;
  font-size: 18px;
  font-weight: bold;
  :after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #000;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
`;

export const customPopUpCloseCss = css`
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 5px 2px;
  border: 1px solid #000;
`;

export const alarmPopUpFormInner = css`
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-top: 5px;
  span {
    font-size: 12px;
  }
  button {
    height: 100%;
  }

  :first-child,
  :nth-child(9) {
    border-bottom: none;
  }

  :last-child {
    margin-top: 20px;
    border-bottom: none;
    justify-content: flex-end;
  }

  label {
    font-weight: normal;
    margin-left: 24px;
    &:first-child {
      margin-left: 0;
    }
  }
  textarea {
    resize: none;
  }
`;

export const alarmPopUpCss = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 30px 20px;
  width: 50vw;
  min-width: 340px;
  max-width: 650px;
  background: #fff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  z-index: 99;
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 16px;
    font-weight: bold;
  }

  button:first-child {
    background: #40a9ff;
    color: #fff;
  }

  button:last-child {
    text-align: right;
    margin-left: 10px;
  }
  .ant-picker-input {
    border: none;
  }
`;

export const customLangButtonWrapperCss = css`
  float: right;
  button {
    color: #fff;
    font-size: 14px;
    :first-child {
      color: ${(props) => (props.lang === "ko" ? "#1890ff" : "#fff")};
      text-decoration: ${(props) => (props.lang === "ko" ? "underline" : "none")};
    }
    &:last-child {
      margin-left: 6px;
      color: ${(props) => (props.lang === "en" ? "#1890ff" : "#fff")};
      text-decoration: ${(props) => (props.lang === "en" ? "underline" : "none")};
    }
  }
`;

export const customDateFlexWrapperCss = css`
  float: right;
  font-size: 10px;
  button {
    color: #fff;
    :first-child {
      color: ${(props) => (props.sort === "desc" ? "#1890ff" : "#fff")};
    }
    &:last-child {
      margin-top: 3px;
      color: ${(props) => (props.sort === "asc" ? "#1890ff" : "#fff")};
    }
  }
`;

export const detailTableCss = css`
  th {
    padding: 5px;
    font-size: 14px;
  }
  td {
    font-size: 12px;
  }
  tr {
    border-bottom: 1px solid #ccc;
  }
`;

export const customGroupCss = css`
  display: flex;
  width: 100%;

  label {
    flex: 1;
    text-align: center;
  }

  label:last-child {
    margin-left: 10px;
  }
`;

export const customAlarmDetailWrapper = css`
  margin: 20px 0 0 10px;
  display: flex;
  align-items: center;
`;

export const customAlarmTitleCss = css`
  font-size: 14px;
  margin-left: 22px;
  margin-right: 10px;
`;

export const customAlarmInputTimeCss = css`
  width: 30px;
  height: 32px;
  text-align: center;
  padding: 3px 0;
  margin-left: 7px;
`;

export const customAlarmWarningTextCss = css`
  position: absolute;
  left: 24px;
  bottom: 0;
  color: red;
  font-size: 12px;
`;

export const CustomAvatarImage = css`
  position: absolute;
  left: 45%;
  top: 57%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
`;

export const CutomTitleWrapperCss = css`
  display: flex;
  justify-content: space-between;
`;

export const CustomAlarmButtonCss = css`
  padding: 5px 15px;
  height: 100%;
  border-color: #1890ff;
  span {
    color: #1890ff;
  }
`;

export const CustomPopUpWrapper = styled.div`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    height: 180px;
    background: #fff;
    box-shadow: 2px -1px 10px rgb(0 0 0 / 30%);
  }
  p {
    width: 75%;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    font-size: 14px;
  }
  button {
    position: absolute;
    bottom: 15px;
    :first-of-type {
      right: 85px;
    }

    :last-of-type {
      right: 20px;

      background: #1890ff;
      border: 1px solid #1890ff;
      color: #fff;
    }
  }
`;
