import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Form, Input, Button } from "antd";

import useInputs from "../../hooks/useInputs";
import { LOGIN_REQUEST, LOGIN_ERROR_NULL } from "../../Module/type";
import catmosLogo from "../../assets/img_logo_168.png";
import usePortals from "../../hooks/usePortals";
import { CustomPopUpWrapper } from "../../style/theme";

const CustomForm = styled(Form)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #f0f2f5;
  z-index: 54;
  input {
  }
  text-align: center;
  width: 465px;
  input {
    width: 300px;
  }
  h1 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  img {
    max-width: 200px;
  }
`;

// const CustomInputPassword = styled(Input.Password)`
//   margin-top: 20px;
//   width: 300px;
//   background: #e8f0fe;
//   input {
//     color: #000;
//   }
// `;

const CustomButton = styled(Button)`
  margin-top: 20px;
  width: 300px;
`;

const PopUpContainer = styled(CustomPopUpWrapper)`
  z-index: 9999;
`;

function Login() {
  const history = useHistory();
  const { isLogInError, isLogin, isToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const formRef = useRef();
  const btnRef = useRef(null);
  const [notInput, setNotInput] = useState(false);
  const [id, onChangeId] = useInputs("");
  const [pw, onChangePw] = useInputs("");

  const { RenderModals } = usePortals();

  const onSubmit = useCallback(() => {
    const data = { id, pw };

    if (id === "" || pw === "") {
      return setNotInput(true);
    }
    dispatch({
      type: LOGIN_REQUEST,
      data,
    });
    formRef.current.blur();
  }, [id, pw]);

  const loginErrorToNull = () => {
    dispatch({ type: LOGIN_ERROR_NULL });
  };

  useEffect(() => {
    if (isLogin || isToken) return history.replace("/main");
  }, [isLogin, isToken]);

  useEffect(() => {
    if (isLogInError) btnRef.current.focus();
  }, [isLogInError]);

  return (
    <>
      <RenderModals>
        {isLogInError && (
          <PopUpContainer>
            <div>
              <p>아이디 혹은 비밀번호가 다릅니다. 다시 확인해주세요.</p>
              <Button type="button" ref={btnRef} onClick={() => loginErrorToNull()}>
                확인
              </Button>
            </div>
          </PopUpContainer>
        )}
        {notInput && (
          <PopUpContainer>
            <div>
              <p>아이디와 비밀번호를 모두 입력 해주세요.</p>
              <Button type="button" onClick={() => setNotInput(false)}>
                확인
              </Button>
            </div>
          </PopUpContainer>
        )}
      </RenderModals>
      <div>
        <CustomForm onFinish={onSubmit}>
          <div>
            <img src={catmosLogo} alt="logo" />
          </div>
          <h1>admin page</h1>
          <Input name="id" type="text" placeholder="id" onChange={onChangeId} value={id} autoComplete="off" />
          <Input.Password
            style={{ marginTop: 20, width: 300 }}
            name="pw"
            placeholder="pw"
            onChange={onChangePw}
            value={pw}
            autoComplete="off"
          />
          <CustomButton htmlType="submit" ref={formRef}>
            로그인
          </CustomButton>
        </CustomForm>
      </div>
    </>
  );
}

export default Login;
