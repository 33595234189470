import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import { regDateToString } from "../../utils/tostring";
import { columns } from "../../pages/User/config";
import { customTableCss, customLangButtonWrapperCss, customDateFlexWrapperCss } from "../../style/theme";

const CustomTable = styled.table`
  ${customTableCss}
  col:first-of-type {
    width: 20%;
  }
  col:nth-of-type(2) {
    width: 15%;
  }
  col:nth-of-type(3) {
    width: 23%;
  }
  col:nth-of-type(4) {
    width: 30%;
  }
  col:nth-of-type(5) {
    width: 12%;
  }
  tbody {
    span {
      position: relative;
    }
  }
  td {
    &:nth-child(1) {
      max-width: 120px;
    }
    &:nth-child(2) {
      max-width: 164px;
    }
    &:nth-child(4) {
      max-width: 380px;
    }
  }
`;

const CustomLangButtonWrapper = styled.div`
  ${customLangButtonWrapperCss}
`;

const CustomFlexWrapper = styled.div`
  ${customDateFlexWrapperCss}
`;

const CustomColorSearched = styled.div`
  color: ${(props) => (props.searched ? "#1890ff" : "#000")}!important;
`;

function UserTableHandler({ onSubmit, sortOption, page, clickHandler, postData, pagingSortOption }) {
  const { userListData, userSearched } = useSelector((state) => state.user);

  const [sort, setSort] = useState("");

  useEffect(() => {
    onSubmit();
  }, [page, sort]);

  const langHandler = (option, value) => {
    sortOption(option, value);
  };

  const sortHandler = (option, value) => {
    setSort(value);
    sortOption(option, value);
  };

  const isSearchedTrue = (value) =>
    userSearched && value !== "" && value !== undefined && value !== null && value !== [];

  const validateText = useCallback(
    (index, text) => {
      if (postData.searchText === "") return text;
      if (index === postData.searchType && userSearched) {
        return text.replace(`${postData.searchText}`, "<span>$&</span>");
      }
      return text;
    },
    [userListData, userSearched],
  );

  useEffect(() => {}, [postData]);

  const theadButton = (th) => {
    switch (th) {
      case "userNm":
        return (
          <CustomLangButtonWrapper lang={pagingSortOption?.sortLang}>
            <button type="button" onClick={() => langHandler("sortLang", "ko")}>
              가
            </button>
            <button type="button" onClick={() => langHandler("sortLang", "en")}>
              A
            </button>
          </CustomLangButtonWrapper>
        );
      case "regDt":
        return (
          <CustomFlexWrapper sort={pagingSortOption?.sortDate}>
            <button type="button" onClick={() => sortHandler("sortDate", "desc")}>
              <CaretUpOutlined />
            </button>
            <button type="button" onClick={() => sortHandler("sortDate", "asc")}>
              <CaretDownOutlined />
            </button>
          </CustomFlexWrapper>
        );
      default:
        break;
    }
  };

  return (
    <>
      {userListData && (
        <CustomTable>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              {columns.map((v, i) => (
                <th key={i}>
                  {v.title}
                  {theadButton(v.dataIndex)}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {userListData.slice(0, 20).map((v, i) => (
              <tr key={i} onClick={() => clickHandler(v.userUuid)}>
                <td>
                  <div
                    title={validateText("userNm", v.userNm)}
                    dangerouslySetInnerHTML={{ __html: validateText("userNm", v.userNm) }}
                  />
                </td>
                <td>
                  <CustomColorSearched searched={isSearchedTrue(postData.regDtStart)} title={regDateToString(v.regDt)}>
                    {regDateToString(v.regDt)}
                  </CustomColorSearched>
                </td>
                <td>
                  <div
                    title={v.userEmail}
                    dangerouslySetInnerHTML={{ __html: validateText("userEmail", v.userEmail) }}
                  />
                </td>
                <td>
                  {v.catNameList && (
                    <div
                      title={v.catNameList}
                      dangerouslySetInnerHTML={{ __html: validateText("catNm", v.catNameList) }}
                    />
                  )}
                </td>
                <td>
                  <span title={v.bandCount}>{v.bandCount}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </CustomTable>
      )}
    </>
  );
}

UserTableHandler.propTypes = {
  page: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sortOption: PropTypes.func.isRequired,
  clickHandler: PropTypes.func,
  postData: PropTypes.object.isRequired,
  pagingSortOption: PropTypes.object.isRequired,
};

UserTableHandler.defaultProps = {
  clickHandler: () => {},
};

export default React.memo(UserTableHandler);
