import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { Radio, Button, DatePicker, Input, Checkbox, Switch, Form } from "antd";
import useInputs from "../../hooks/useInputs";
import SelectHandler from "../Form/SelectHandler";
import { selectWeightData, languageOption, timeSelect } from "../../pages/Cat/config";

import { selectRepeatData, selectKindsData, selectKorAgeData, selectEngAgeData } from "../../pages/Alarm/config";
import usePortals from "../../hooks/usePortals";
import {
  alarmPopUpCss,
  alarmPopUpFormInner,
  customGroupCss,
  customAlarmDetailWrapper,
  customAlarmTitleCss,
  customAlarmInputTimeCss,
  customAlarmWarningTextCss,
  CustomPopUpWrapper,
} from "../../style/theme";
import { isHourLargerThanTwelve, isMinLargerThanSixty, isNoString } from "../../utils/validate";
import { toUtc } from "../../utils/tostring";
import { POST_ALARM_DATA_REQUEST } from "../../Module/type";

const { Group } = Radio;
const { TextArea } = Input;

const PopUpWrapper = styled.div`
  ${alarmPopUpCss}
  .ant-radio-button-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  label {
  }
`;

const UserFormInner = styled.div`
  ${alarmPopUpFormInner}
  border-bottom: 1px solid #cecece;
`;

const FlexWrapper = styled.div`
  ${customAlarmDetailWrapper}
`;

const CustomGroup = styled(Group)`
  ${customGroupCss}
`;

const CutomTitle = styled.p`
  ${customAlarmTitleCss}
`;

const InputTime = styled(Input)`
  ${customAlarmInputTimeCss}
`;

const WarningText = styled.div`
  ${customAlarmWarningTextCss}
`;

const CustomTextArea = styled(TextArea)`
  border-radius: 10px;
  height: 300px;
`;

const CustomDatePicker = styled(DatePicker)`
  margin-right: 30px;
  .ant-picker-input {
    width: 104px;
    padding-right: 6px;
    border: 1px solid #d9d9d9 !important;
  }
  .ant-picker-clear {
    right: 6px;
  }
`;

const CustomCheckBox = styled(Checkbox)`
  margin-left: 10px;
`;

const CustomCheckBoxEnd = styled(Checkbox)`
  margin: 0 5px 0 10px;
`;

function AlarmSendPopUp({ toggleFilter, lang, filterOpen }) {
  const dispatch = useDispatch();

  const [language, onChangeLanguage, setLang] = useInputs(lang);
  const [message, setmessage, clearMessage] = useInputs("");
  const [popupText, setPopupText] = useState("");
  const [searchData, setSearchData] = useState({
    catAgeStatus: "",
    catWeightType: 0,
    hairType: 0,
  });
  const [repeatCycle, setRepeatCycle] = useState("W");
  const [isReservation, setIsReservation] = useState(false);
  const [isSelectTiemCorrect, setIsSelectTiemCorrect] = useState(true);
  const [reserveEndYn, setReserveEndYn] = useState(false);
  const [repeatType, setRepeatType] = useState(false);
  const [selecetReserveDt, setReserveDt] = useState({
    date: "",
    hour: "",
    min: "",
    time: "date",
  });
  const [isPopup, setIsPopUp] = useState(false);
  const [reserveEndDt, setReserveEndDt] = useState(0);
  const [form] = Form.useForm();

  const { RenderModals } = usePortals();

  const selectOnChange = useCallback(
    (value, data) => {
      const { name } = data;
      setSearchData({ ...searchData, [name]: value });
    },
    [searchData],
  );

  const resetPopUp = () => {
    setLang(lang);
    setSearchData({ catAgeStatus: "", catWeightType: 0, hairType: 0 });
    setReserveDt({
      ...selecetReserveDt,
      date: "",
      hour: "",
      min: "",
      time: "date",
    });
    setRepeatType(false);
    setIsReservation(false);
    setIsSelectTiemCorrect(true);
    setReserveEndYn(false);
    setReserveEndDt(0);
    clearMessage("");
  };

  useEffect(() => {
    resetPopUp();
  }, [filterOpen]);

  const selectTImeType = useCallback(
    (value, data) => {
      const { name } = data;

      setReserveDt({ ...selecetReserveDt, [name]: value });
    },
    [selecetReserveDt],
  );

  const onChnageDate = (value, date) => {
    setReserveDt({
      ...selecetReserveDt,
      date,
    });
  };

  const onChangeEndDate = (_, string) => {
    if (string) {
      setReserveEndDt(toUtc(moment(string, "YY.MM.DD").format("YYYY-MM-DD")));
    } else {
      setReserveEndDt(Math.floor(Date.now() / 1000));
    }
  };

  const requestAddPost = (data) => {
    try {
      dispatch({
        type: POST_ALARM_DATA_REQUEST,
        data,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const InputHandler = useCallback(
    (e) => {
      const { value, name } = e.target;
      if (isHourLargerThanTwelve(name, value) || isMinLargerThanSixty(name, value) || isNoString(value)) {
        return setIsSelectTiemCorrect(false);
      }
      setIsSelectTiemCorrect(true);
      setReserveDt({
        ...selecetReserveDt,
        [name]: value,
      });
    },
    [onChnageDate, selecetReserveDt],
  );

  const calcSendTime = () => {
    const { date, hour, min, time } = selecetReserveDt;
    let times;
    if (date === "" || hour === "" || min === "" || time === "") {
      return null;
    }
    if (time === "night") {
      times = `${date} ${+hour + 12}:${min}`;
      if (+hour === 12) {
        times = `${date} ${12}:${min}`;
      }
    } else {
      times = `${date} ${+hour === 12 ? 0 : +hour}:${min}`;
    }

    return moment(times, "YYYY.MM.DD HH:mm").utc().valueOf() / 1000;
  };

  const reservationValidate = () => {
    if (isReservation) {
      return calcSendTime();
    }
    return Math.floor(Date.now() / 1000);
  };

  const isReservations = () => {
    if (isReservation) return 1;
    return 0;
  };

  // const isReservationPassed =
  //   (isReservation && reservationValidate() === undefined) || (isReservation && reservationValidate() === false);

  const changeReserveEndYn = () => {
    if (reserveEndYn) return 1;
    return 0;
  };

  const switchReserve = (e) => {
    if (e === true) {
      setSearchData({ ...searchData });
      setRepeatCycle("W");
      setRepeatType(1);
    } else {
      setReserveEndYn(false);
      setRepeatType(0);
    }
  };

  const closePopUp = () => {
    toggleFilter();
    form.resetFields();
  };

  const isRepeatTypeFalse = !repeatType || !isReservation;

  const disabledDate = (current) => current && current < moment().startOf("day");

  const openPopUp = (text) => {
    setPopupText(text);
    setIsPopUp(true);
  };

  const onSubmit = () => {
    if (message === "") return openPopUp("메시지를 입력해주세요");

    let newData = {
      ...searchData,
      reserveEndYn: changeReserveEndYn(),
      message,
      repeatType: isRepeatTypeFalse ? 0 : 1,
      langCode: language === "한글" ? 1 : 2,
      reserveType: isReservations(),
    };

    const { date, hour, min, time } = selecetReserveDt;

    const isSelectedReserveDt = date === "" || hour === "" || min === "" || time === "";

    if (isReservation) {
      if (isSelectedReserveDt) {
        return openPopUp("예약 발송일을 확인해주세요");
      }

      newData = { ...newData, reserveDt: reservationValidate() };

      if (repeatType) {
        if (!repeatCycle) return;

        newData = { ...newData, repeatCycle };

        if (reserveEndYn) {
          if (reserveEndDt) {
            if (
              moment(moment.unix(reserveEndDt).format("YYYY-MM-DD")).isBefore(
                moment(date, "YY.MM.DD").format("YYYY-MM-DD"),
              )
            ) {
              return openPopUp("종료일을 확인해주세요");
            }

            newData = { ...newData, reserveEndDt };
          } else {
            return openPopUp("종료일을 확인해주세요");
          }
        }
      }
    }

    setIsSelectTiemCorrect(true);
    requestAddPost(newData);
    return closePopUp();
  };

  return (
    <>
      <RenderModals>
        {isPopup && (
          <CustomPopUpWrapper>
            <div>
              <p>{popupText}</p>
              <Button type="button" onClick={() => setIsPopUp(false)}>
                확인
              </Button>
            </div>
          </CustomPopUpWrapper>
        )}
      </RenderModals>
      <Form form={form} onFinish={onSubmit}>
        <PopUpWrapper>
          <UserFormInner>
            <CustomGroup options={languageOption} onChange={onChangeLanguage} value={language} optionType="button" />
          </UserFormInner>
          <h3>발송 조건 선택</h3>
          <UserFormInner>
            <Form.Item noStyle>
              <label>품종</label>
              <Form.Item name="hairType" noStyle>
                <SelectHandler data={selectKindsData} onChange={selectOnChange} isValue={searchData.hairType} />
              </Form.Item>
            </Form.Item>
            <Form.Item noStyle>
              <label>체중</label>
              <Form.Item name="catWeightType" noStyle>
                <SelectHandler data={selectWeightData} onChange={selectOnChange} isValue={searchData.catWeightType} />
              </Form.Item>
            </Form.Item>
            <Form.Item noStyle>
              <label>나이</label>
              <Form.Item name="catAgeStatus" noStyle>
                <SelectHandler
                  data={language === "English" ? selectEngAgeData : selectKorAgeData}
                  onChange={selectOnChange}
                  isValue={searchData.catAgeStatus}
                />
              </Form.Item>
            </Form.Item>
          </UserFormInner>
          <FlexWrapper>
            <h5>예약 발송</h5>
            <CustomCheckBox onChange={() => setIsReservation(!isReservation)} checked={isReservation} />
          </FlexWrapper>
          <UserFormInner>
            <Form.Item name="resDt" noStyle>
              <CustomDatePicker
                bordered={false}
                disabled={!isReservation}
                onChange={onChnageDate}
                format="YY.MM.DD"
                placeholder="YY.MM.DD"
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item name="resTime" noStyle>
              <SelectHandler data={timeSelect} onChange={selectTImeType} width={100} disable={!isReservation} />
            </Form.Item>
            <InputTime
              maxLength={2}
              name="hour"
              onChange={InputHandler}
              disabled={!isReservation}
              value={selecetReserveDt.hour}
            />
            <InputTime
              maxLength={2}
              name="min"
              onChange={InputHandler}
              disabled={!isReservation}
              value={selecetReserveDt.min}
            />
            <WarningText style={{ display: isSelectTiemCorrect ? "none" : "block" }}>
              입력시간을 확인해주세요
            </WarningText>
          </UserFormInner>
          <FlexWrapper>
            <h5>반복 알림</h5>
            <Switch onChange={switchReserve} checked={repeatType} disabled={!isReservation} />
          </FlexWrapper>
          <UserFormInner>
            <CutomTitle style={{ marginLeft: 12 }}>주기</CutomTitle>
            <Form.Item name="repeatType" noStyle>
              <SelectHandler
                data={selectRepeatData}
                onChange={setRepeatCycle}
                disable={!repeatType}
                isValue={repeatCycle}
              />
            </Form.Item>
            <CutomTitle style={{ marginLeft: 40 }}>
              <CustomCheckBoxEnd
                onChange={(e) => {
                  const isCheck = e.target.checked;
                  setReserveEndYn(isCheck);
                }}
                checked={reserveEndYn}
                disabled={!repeatType}
              />
              종료일
            </CutomTitle>
            <Form.Item name="resEndDt" noStyle>
              <CustomDatePicker
                bordered={false}
                disabled={!reserveEndYn}
                onChange={onChangeEndDate}
                format="YY.MM.DD"
                placeholder="YY.MM.DD"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </UserFormInner>
          <h3>내용 입력</h3>
          <UserFormInner style={{ paddingBottom: 0 }}>
            <CustomTextArea maxLength={300} placeholder="알람 발송 내용 입력" onChange={setmessage} value={message} />
          </UserFormInner>
          <UserFormInner>
            <Button
              htmlType="submit"
              type="primary"
              style={{ border: "1px solid rgb(24, 144, 255)", background: "#1890ff" }}
            >
              확인
            </Button>
            <Button type="button" onClick={() => closePopUp()}>
              닫기
            </Button>
          </UserFormInner>
        </PopUpWrapper>
      </Form>
    </>
  );
}

AlarmSendPopUp.propTypes = {
  toggleFilter: PropTypes.func.isRequired,
  filterOpen: PropTypes.bool.isRequired,
  lang: PropTypes.string,
};

AlarmSendPopUp.defaultProps = {
  lang: "한글",
};

export default AlarmSendPopUp;
