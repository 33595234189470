import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { RightOutlined } from "@ant-design/icons";
import styled from "styled-components";

const DashBoardCards = styled.div`
  flex: 1;
  border-radius: 11%;
  height: 400px;
  background: #d9d9d9;
  overflow: hidden;
  :nth-child(2) {
    margin: 0 20px;
  }
`;

const CustomRouterWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 30%;
  background: #ccc;
  display: flex;
  align-items: center;

  cursor: pointer;
  > span {
    margin-left: 5px;
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    left: 35px;
    top: 55%;
    transform: translateY(-50%);
    span {
      margin-left: 8px;
    }
  }
`;

const CustomTotalWrapper = styled.div`
  margin: 35px 0 0 35px;
  span {
    font-weight: normal;
    font-size: 18px;
  }
  p {
    margin-top: 8px;
    font-weight: bold;
    font-size: 48px;
    span {
      font-size: 14px;
      margin-left: 5px;
    }
  }
`;

const CustomDescWrapper = styled.div`
  margin-top: 15px;
  display: flex;

  div {
    padding: 15px 0 0 35px;
    flex: 1;
    span {
      display: inline-block;
      margin-top: 20px;
      padding-bottom: 15px;
      font-size: 30px;
      font-weight: bold;
    }
    &:first-child {
      span {
        color: ${(props) => (props.title === "band" ? "black" : "#1890ff")};
      }
    }

    &:last-child {
      display: ${(props) => (props.title === "band" ? "none" : "block")};
      span {
        color: ${(props) => props.title !== "band" && "#dc143c"};
      }
      border-left: 2px solid #ccc;
    }
  }
`;

function DashBoardCard({ title, total, newData, deleteData }) {
  const history = useHistory();

  const titleToKor = () => {
    switch (title) {
      case "user":
        return "회원 관리";
      case "cat":
        return "고양이 관리";
      case "band":
        return "밴드 관리";
      default:
        return false;
    }
  };

  const titleTotalToKor = () => {
    switch (title) {
      case "user":
        return "명";
      case "cat":
        return "마리";
      case "band":
        return "대";
      default:
        return false;
    }
  };

  const titleRouting = () => history.push(`/${title}`);

  return (
    <DashBoardCards>
      <CustomRouterWrapper type="button" onClick={() => titleRouting(title)}>
        <span>
          {titleToKor(title)}
          <RightOutlined />
        </span>
      </CustomRouterWrapper>
      <CustomTotalWrapper>
        <span>Total</span>
        <p>
          {total}
          <span>{titleTotalToKor(total)}</span>
        </p>
      </CustomTotalWrapper>
      <CustomDescWrapper title={title}>
        <div>
          <p>신규 등록</p>
          <span>{newData || 0}</span>
        </div>
        <div>
          {title === "user" ? <p>탈퇴</p> : <p>삭제</p>}
          <span>{deleteData || 0}</span>
        </div>
      </CustomDescWrapper>
    </DashBoardCards>
  );
}

DashBoardCard.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number,
  newData: PropTypes.number,
  deleteData: PropTypes.number,
};

DashBoardCard.defaultProps = {
  deleteData: null,
  total: 0,
  newData: 0,
};

export default DashBoardCard;
