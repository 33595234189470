import { put, takeLatest, all, fork, call } from 'redux-saga/effects';
import { posts } from './index';
import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAILURE,
  CAHNGE_LOGIN_INFO_REQUEST,
  CAHNGE_LOGIN_INFO_SUCCESS,
  CAHNGE_LOGIN_INFO_FAILURE,
} from '../type';

function logInAPI(data) {
  const config = {
    params: data,
  };

  return posts.post('account/login', data, config);
}

function logOutAPI() {
  return posts.get('account/logout');
}

function chageInfoAPI(data) {
  return posts.post('account/reset-pw', data);
}

function* loginUser(action) {
  try {
    const result = yield call(logInAPI, action.data);
    yield put({
      type: LOGIN_SUCCESS,
      payload: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: LOGIN_FAILURE,
      error: e.message,
    });
  }
}

function* logOutUser(action) {
  try {
    const result = yield call(logOutAPI, action.data);
    yield put({
      type: LOG_OUT_SUCCESS,
      payload: result.data,
    });
  } catch (e) {
    console.log(e.message);
    yield put({
      type: LOG_OUT_FAILURE,
      error: e.message,
    });
  }
}

function* changeInfo(action) {
  try {
    const result = yield call(chageInfoAPI, action.data);
    yield put({
      type: CAHNGE_LOGIN_INFO_SUCCESS,
      payload: result.data,
    });
    // if (result.data.errorCode === 401) {
    //   yield call(history.push('/'));
    // }
  } catch (e) {
    console.log(e.message);
    yield put({
      type: CAHNGE_LOGIN_INFO_FAILURE,
      error: e.message,
    });
  }
}

function* watchLoginUser() {
  yield takeLatest(LOGIN_REQUEST, loginUser);
}

function* watchLogOutUser() {
  yield takeLatest(LOG_OUT_REQUEST, logOutUser);
}

function* watchChangeInfo() {
  yield takeLatest(CAHNGE_LOGIN_INFO_REQUEST, changeInfo);
}

export default function* authSaga() {
  yield all([fork(watchLoginUser), fork(watchLogOutUser), fork(watchChangeInfo)]);
}
