import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const globalStyles = createGlobalStyle`
${reset};

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

}

:root {
  font-size: 10px;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
  font-family: 'NanumSquare', sans-serif;
}

body,
div,
ul,
li,
dl,
dd,
dt,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
fieldset,
legend,
p,
select,
table,
th,
td,
tr,
textarea,
button,
form,
figure,
figcaption {
  margin: 0;
  padding: 0;
}

/*clearfix*/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: block;
  content: '';
  line-height: 0;
}
.clearfix:after {
  clear: both;
}


a {
  color: #000;
  text-decoration: none;
}

body,
input,
textarea,
select,
button,
table {
  color: #000;
  font-size: 16px;
  line-height: 1.3;
}

fieldset {
  border: none;
}
button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

em,
address {
  font-style: normal;
}

ul,
li,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 13px;
  font-weight: normal;
}

/* IR 효과 */
.ir_pm {
  display: block;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
}
.ir_wa {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ir_su {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  text-indent: -9999px;
}
.blind {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

main{
  padding: 80px 0 100px;
  width:100%;
  max-width: 1392px;
}

main h2 {
  font-size: 32px;
  font-weight: 800;
}

main h3 {
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 800;
}
form{
  background: #fafafa;
}

label{
  margin-right: 10px;
  font-size:14px;
  font-weight:bold;
}

.ant-form{
  padding:30px 20px 30px;
  margin-bottom:30px;
}
.ant-layout{
  width: 100vw;
}

.ant-layout-sider,
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color: black;
  background :#666
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .active{
  background-color: #222;
}
.ant-btn,
.ant-input{
  border-color: #ccc;
  color:#222;
}
.ant-btn:hover,
.ant-btn:focus {
  font-weight: bold;
}
.ant-transfer-list-content{
 max-height: 300px;
 min-height: 342px;
}

.ant-picker-input{
  padding: 3px 0px 3px 0px;
  border: 1px solid #ccc;
  color: #222;
  width:90px;

}
.ant-picker-input input{
  text-align: center;
}

.ant-empty-description{
  display: none;
} 

.ant-transfer-list{
  min-width: 300px;

  height: auto;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  width: 100px;
}

.ant-input:placeholder-shown{
 border-radius: 5px;
}


@media (max-width: 991px) {
  main{
  margin-right: 50px;
  padding-right: 0px;
  }

  .ant-transfer-list{
    min-width: 100px;
    min-height: 200px;
    height: auto;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    width: 100%;
  }
  .ant-input{
    font-family:sans-serif;
  }

}

textarea.ant-input{
  height: 120px;
}
.ant-avatar{
  position: relative;
  border: 1px solid #666;
}
.ant-avatar .ant-image{
  position: relative;
  width: 100%;
  height: 100%;
  background: #efecec;

}
.ant-spin-spinning{
  position:fixed;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  z-index:9999;
}

.ant-spin {
  animation: opacityAnim 0.7s

}

.ant-upload-list-item-info {
  position: relative;
}
.ant-picker-cell ant-picker-cell-in-view ant-picker-cell-today{
  color:#fff;
}
.ant-picker-cell .ant-picker-cell-inner{
  font-size:14px;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
  background: #1890ff;
  color:#fff;
  border-radius:50% !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border-radius: 50%;
}
.ant-picker-header-view{
  pointer-events:none;
}
.ant-picker-date-panel .ant-picker-content th{
  font-size: 12px;
  :first-of-type{
    color: red;
  }
}
.ant-transfer-list-body{
  min-height: 342px;
}
@keyframes opacityAnim {
  0% {
   opacity: 0
  }

  50%{
    opacity: 1
  }

  100%{
    opacity: 1
  }

}

`;
export default globalStyles;
