export const setFirstPaginationNumber = (number, lastpage) => {
  const changefirstNumber = Math.floor(number / 10) * 10;
  const changelastNumber = Math.ceil(number / 10) * 10;
  if (changelastNumber >= lastpage) {
    return changefirstNumber;
  }
  if (number === lastpage) {
    return changefirstNumber;
  }
  if (number % 10 === 0) {
    return changefirstNumber;
  }

  return changefirstNumber + 10;
};

export const setLastPaginationNumber = (number, lastPage) => {
  const changelastNumber = Math.ceil(number / 10) * 10;
  if (changelastNumber >= lastPage) {
    return lastPage;
  }

  return changelastNumber + 10;
};

export const setPrevFirstPaginationNumber = (number) => {
  const changefirstNumber = Math.floor(number / 10) * 10;
  if (number % 10 === 0) {
    if (changefirstNumber - 20 < 0) {
      return 0;
    }
    return changefirstNumber - 20;
  }
  if (changefirstNumber < 0 || changefirstNumber - 10 < 0) {
    return 0;
  }

  return changefirstNumber - 10;
};

export const setPrevLastPaginationNumber = (number, lastPage) => {
  const changelastNumber = Math.ceil(number / 10) * 10;
  if (lastPage < 10 || changelastNumber <= 0) return lastPage;

  if (changelastNumber <= 10) return changelastNumber;

  return changelastNumber - 10;
};
