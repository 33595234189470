import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { posts } from './index';
import {
  LOAD_MAIN_NOTICE_REQUEST,
  LOAD_MAIN_NOTICE_SUCCESS,
  LOAD_MAIN_NOTICE_FAILURE,
} from '../type';

function getMainNoticeAPI(fixMin) {
  return posts.get(`home/dashboard/${fixMin}`);
}

function* getMainNotice(action) {
  try {
    const result = yield call(getMainNoticeAPI, action.fixMin);
    yield put({
      type: LOAD_MAIN_NOTICE_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: LOAD_MAIN_NOTICE_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchGetMainNotice() {
  yield takeLatest(LOAD_MAIN_NOTICE_REQUEST, getMainNotice);
}

export default function* mainSaga() {
  yield all([fork(watchGetMainNotice)]);
}
