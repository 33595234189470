export const columns = [
  {
    title: 'S/N',
    dataIndex: 'sn',
  },
  {
    title: '등록일',
    dataIndex: 'date',
  },
  {
    title: '등록 회원',
    dataIndex: 'email',
  },
  {
    title: '등록 고양이',
    dataIndex: 'cat',
  },
  {
    title: '펌웨어',
    dataIndex: 'firm',
  },
  {
    title: '마지막 동기화 시간',
    dataIndex: 'dt',
  },
];

export const selectData = [
  {
    id: 0,
    title: '밴드 S/N ',
    value: 1,
  },
  {
    id: 1,
    title: '등록 회원',
    value: 2,
  },
  {
    id: 2,
    title: '등록 고양이',
    value: 3,
  },
];
