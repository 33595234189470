import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";

import DetailPopUpCatTable from "../DetailPopUpCatTable";
import { customTableCss, detailTableCss } from "../../style/theme";
import { regDateToString } from "../../utils/tostring";
import { detailBandDatacolumns } from "../../pages/User/config";

const CustomTable = styled.table`
  ${customTableCss}
  ${detailTableCss}
  td {
    height: auto !important;
  }
`;

function UserDetailTable({ data, userInfo }) {
  return (
    <>
      {data && (
        <>
          <h5>{`고양이 정보 (${data.length})`}</h5>
          <DetailPopUpCatTable data={data} size={40} />
          <h5>{`밴드 연동  (${userInfo && userInfo.bandCount})`}</h5>
          {userInfo?.bandCount > 0 && (
            <CustomTable>
              <thead>
                <tr>
                  {detailBandDatacolumns.map((v, i) => (
                    <th key={i}>{v.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map(
                  (v, i) =>
                    v.bandSn && (
                      <tr key={i}>
                        <td>
                          <div title={v.bandSn}>{v.bandSn}</div>
                        </td>
                        <td>
                          <div title={v.catNm}>{v.catNm}</div>
                        </td>
                        <td>
                          <div title={v.bandVersion}>{v.bandVersion}</div>
                        </td>
                        <td>
                          <div
                            title={v.synchronizationDt && moment(v.synchronizationDt * 1000).format("YYYY.MM.DD HH:mm")}
                          >
                            {v.synchronizationDt && moment(v.synchronizationDt * 1000).format("YYYY.MM.DD HH:mm")}
                          </div>
                        </td>
                        <td>
                          <div title={regDateToString(v.regDt)}>{regDateToString(v.regDt)}</div>
                        </td>
                      </tr>
                    ),
                )}
              </tbody>
            </CustomTable>
          )}
        </>
      )}
    </>
  );
}

UserDetailTable.propTypes = {
  data: PropTypes.array.isRequired,
  userInfo: PropTypes.object,
};

UserDetailTable.defaultProps = {
  userInfo: [],
};

export default UserDetailTable;
