/* eslint-disable no-plusplus */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Transfer, Button, Input } from "antd";
import useSWR from "swr";

import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { customPopUpCloseCss } from "../../style/theme";
import fetcher from "../../utils/fetcher";

const CustomTransferWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 60px 40px 100px;
  background: #fff;
  box-shadow: 2px 2px 8px rgb(0 0 0 / 50%);
  z-index: 55;
  h5 {
    position: absolute;
    left: 35px;
    top: 28px;
    color: #222;
    font-size: 22px;
    font-weight: bold;
  }

  .ant-transfer-list-body-search-wrapper {
    position: relative;
    padding: 12px 8px;
  }
  .ant-transfer-operation .ant-btn {
    color: #fff;
  }
  .ant-transfer-operation .ant-btn:disabled {
    color: #ccc;
  }
  .ant-checkbox-wrapper {
    display: none;
  }
  .ant-transfer-list-header-selected {
    display: none;
  }
  .anticon.anticon-down {
    display: none;
  }

  .ant-transfer-list-body-search-wrapper {
    margin-bottom: 30px;
  }
  .ant-transfer-list-content {
    border-top: 1px solid #ccc;
    height: 400px;
  }

  .ant-transfer-list-header-title {
    text-align: left !important;
  }
`;
const CustomButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 30px;
  button {
    &:last-of-type {
      margin-left: 7px;
      border: 1px solid #1890ff;
      background: #1890ff;
      color: #fff;
    }
  }
`;
const CustomLangBtnWrapper = styled.div`
  position: absolute;
  font-size: 16px;
  left: 260px;
  top: 8px;
  button {
    text-decoration: underline;
    :last-of-type {
      margin-left: 5px;
    }
  }
`;

const SearchInputs = styled.div`
  margin: 15px 0;
  display: flex;
  width: 300px;
  input {
    width: 230px;
  }
  button {
    margin-left: auto;
  }
`;
const CustomPopUpCloseButton = styled.button`
  ${customPopUpCloseCss}
`;
const CustomActiveButton = styled.button`
  color: ${(props) => (props.isLang ? "#1890ff" : "#000")};
`;
function CatTransferHandler({ transferOnChange, toggleFilter, onReset }) {
  const [targetKeys, setTargetKeys] = useState();
  const [breedLang, setBreedLang] = useState("kr");
  const [searchText, setSearchText] = useState("");
  const [breedArray, setBreedArray] = useState([]);
  const [breedEnArray, setBreedEnArray] = useState([]);
  const token = window.sessionStorage.getItem("access_token");
  const { data: korData } = useSWR([`${process.env.REACT_APP_API}cat/breed/${1}`, token], fetcher);
  const { data: enData } = useSWR([`${process.env.REACT_APP_API}cat/breed/${2}`, token], fetcher);

  const onChange = useCallback((nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  }, []);

  const onSubmit = () => {
    transferOnChange("catBreedSeqNo", targetKeys);
    toggleFilter();
  };

  const languagetoggleHandler = (lang) => {
    switch (lang) {
      case "kr":
        setBreedLang("kr");
        setSearchText("");
        return;
      case "en":
        setBreedLang("en");
        setSearchText("");
        return;
      default:
        return setBreedLang("kr");
    }
  };

  useEffect(() => {
    languagetoggleHandler("kr");
  }, []);

  useEffect(() => {
    setTargetKeys();
    setBreedLang("kr");
  }, [onReset]);

  const setDataArray = () => {
    setBreedEnArray(enData);
    setBreedArray(korData);
  };

  useEffect(() => {
    setBreedArray(korData);
    setBreedEnArray(enData);
  }, [enData, korData, breedLang]);

  const onInputChange = (e) => {
    if (e.target.value === "") setDataArray();
    setSearchText(e.target.value);
  };

  const onSearchDataSubmit = () => {
    if (breedLang === "kr") {
      setBreedArray((prev) => prev.filter((value) => value.krNm.includes(searchText)));
    } else {
      setBreedEnArray((prev) => prev.filter((value) => value.enNm.toLowerCase().includes(searchText.toLowerCase())));
    }
  };

  const ButtonWrapper = () => (
    <CustomLangBtnWrapper>
      <CustomActiveButton type="button" onClick={() => languagetoggleHandler("kr")} isLang={breedLang === "kr"}>
        가
      </CustomActiveButton>
      <CustomActiveButton type="button" onClick={() => languagetoggleHandler("en")} isLang={breedLang === "en"}>
        A
      </CustomActiveButton>
    </CustomLangBtnWrapper>
  );

  return (
    <CustomTransferWrapper>
      <h5>검색할 품종을 선택해주세요</h5>
      <SearchInputs>
        <Input type="text" onChange={onInputChange} value={searchText} />
        <Button type="button" onClick={onSearchDataSubmit}>
          검색
        </Button>
      </SearchInputs>

      <CustomPopUpCloseButton type="button" onClick={toggleFilter}>
        <CloseOutlined />
      </CustomPopUpCloseButton>
      {korData && enData && breedLang === "kr" ? (
        <Transfer
          dataSource={breedArray}
          titles={[ButtonWrapper(), `검색 품종 (${targetKeys ? targetKeys.length : 0})`]}
          targetKeys={targetKeys}
          onChange={onChange}
          render={(item) => item.krNm}
          rowKey={(record) => record.catBreedSeqNo}
        />
      ) : (
        <Transfer
          dataSource={breedEnArray}
          titles={[ButtonWrapper(), `검색 품종 (${targetKeys ? targetKeys.length : 0})`]}
          targetKeys={targetKeys}
          onChange={onChange}
          render={(item) => item.enNm}
          rowKey={(record) => record.catBreedSeqNo}
        />
      )}
      <CustomButtonWrapper>
        <Button type="button" onClick={toggleFilter}>
          닫기
        </Button>
        <Button type="submit" onClick={onSubmit}>
          확인
        </Button>
      </CustomButtonWrapper>
    </CustomTransferWrapper>
  );
}
CatTransferHandler.propTypes = {
  transferOnChange: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default CatTransferHandler;
