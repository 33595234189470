import produce from "immer";
import {
  GET_CAT_LIST_DATA_REQUEST,
  GET_CAT_LIST_DATA_SUCCESS,
  GET_CAT_LIST_DATA_FAILURE,
  GET_CAT_BREED_DATA_REQUEST,
  GET_CAT_BREED_DATA_SUCCESS,
  GET_CAT_BREED_DATA_FAILURE,
  GET_CAT_DETAIL_DATA_REQUEST,
  GET_CAT_DETAIL_DATA_SUCCESS,
  GET_CAT_DETAIL_DATA_FAILURE,
  CAT_SEARCHED_SUCCESS,
} from "../type";

export const initalState = {
  catListData: [],
  catListPageData: [],
  catBreedData: [],
  catDetailData: [],
  catImage: "",
  catSearched: false,
  catListDataLoading: false,
  catListDataDone: false,
  catListDataError: false,
  catBreedDataLoading: false,
  catBreedDataDone: false,
  catBreedDataError: false,
  catDetailDataLoading: false,
  catDetailDataDone: false,
  catDetailDataError: false,
  catImageDataLoading: false,
  catImageDataDone: false,
  catImageDataError: false,
};

const catPostReducer = (state = initalState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_CAT_BREED_DATA_REQUEST:
        draft.catBreedDataLoading = true;
        draft.catBreedDataError = null;
        break;
      case GET_CAT_BREED_DATA_SUCCESS: {
        draft.catBreedData = [];
        action.data.payload.forEach((v) => {
          draft.catBreedData.push({ ...v, key: v.catBreedSeqNo });
        });
        draft.catBreedDataDone = false;
        draft.catBreedDataError = true;
        break;
      }
      case GET_CAT_BREED_DATA_FAILURE:
        draft.catBreedDataLoading = false;
        draft.catBreedDataError = action.error;
        break;
      case GET_CAT_LIST_DATA_REQUEST:
        draft.catListDataLoading = true;
        draft.catListDataError = null;
        draft.catListData = [];
        break;
      case GET_CAT_LIST_DATA_SUCCESS: {
        draft.catListDataLoading = false;
        draft.catListDataDone = true;
        draft.catListData = action.data.payload.catList;
        draft.catListPageData = action.data.payload.paging;
        break;
      }
      case GET_CAT_LIST_DATA_FAILURE:
        draft.catListDataLoading = false;
        draft.catListDataError = action.error;
        break;
      case GET_CAT_DETAIL_DATA_REQUEST:
        draft.catDetailDataLoading = true;
        draft.catDetailDataError = null;
        draft.catDetailData = [];
        break;
      case GET_CAT_DETAIL_DATA_SUCCESS: {
        draft.catDetailDataLoading = false;
        draft.catDetailDataDone = true;
        draft.catDetailData = action.data.payload;
        break;
      }
      case GET_CAT_DETAIL_DATA_FAILURE:
        draft.catDetailDataLoading = false;
        draft.catDetailDataError = action.error;
        break;
      case CAT_SEARCHED_SUCCESS:
        draft.catSearched = true;
        break;
      default:
        return state;
    }
  });
export default catPostReducer;
