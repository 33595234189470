import produce from 'immer';

import {
  LOAD_MAIN_NOTICE_REQUEST,
  LOAD_MAIN_NOTICE_SUCCESS,
  LOAD_MAIN_NOTICE_FAILURE,
  ALL_LOAD_REQUEST,
  ALL_LOAD_SUCCESS,
  ALL_LOAD_FAILURE,
} from '../type';

import { setSessionStoragepostTime } from '../../utils/login';

export const initalState = {
  mainNotice: [],
  mainAlarmNotice: [],
  isMainNoticeDone: false,
  isMainNoticeLoading: false,
  isMainNoticeError: false,
  allLoading: false,
};

const nullData = {
  bandNew: 0,
  bandTotal: 0,
  catDeleted: 0,
  catNew: 0,
  catTotal: 0,
  userNew: 0,
  userTotal: 0,
  userWithdrawal: 0,
};

const mainReducer = (state = initalState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_MAIN_NOTICE_REQUEST:
        draft.isMainNoticeLoading = true;
        draft.isMainNoticeError = null;
        break;
      case LOAD_MAIN_NOTICE_SUCCESS:
        {
          const postDate = new Date().toLocaleTimeString();
          setSessionStoragepostTime(postDate);

          if (action.data.payload.statics) {
            draft.mainNotice = action.data.payload.statics;
          } else {
            draft.mainNotice = nullData;
          }
          draft.mainAlarmNotice = action.data.payload.notificationList;
          draft.isMainNoticeLoading = false;
          draft.isMainNoticeDone = true;
        }
        break;
      case LOAD_MAIN_NOTICE_FAILURE:
        draft.isMainNoticeLoading = false;
        draft.isMainNoticeError = action.error;
        break;
      case ALL_LOAD_REQUEST:
        draft.allLoading = true;
        break;
      case ALL_LOAD_SUCCESS:
        draft.allLoading = false;
        break;
      case ALL_LOAD_FAILURE:
        draft.allLoading = false;
        break;
      default:
        return state;
    }
  });
export default mainReducer;
