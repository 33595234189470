import React from "react";
import { DatePicker, Space } from "antd";
import PropTypes from "prop-types";
import moment from "moment";

const { RangePicker } = DatePicker;

function RangePickers({ onChangeHandler, searchDate }) {
  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        onChange={onChangeHandler}
        bordered={false}
        placeholder={["YY.MM.DD", "YY.MM.DD"]}
        format="YY.MM.DD"
        value={
          Object.keys(searchDate).length !== 0 && [
            moment(Object.values(searchDate)[0] * 1000),
            moment(Object.values(searchDate)[1] * 1000),
          ]
        }
        disabledDate={(current) => current && moment(new Date()) < current}
      />
    </Space>
  );
}

RangePickers.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  searchDate: PropTypes.object,
};
RangePickers.defaultProps = {
  searchDate: null,
};

export default RangePickers;
