import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Radio, Button, DatePicker, Input, Checkbox, Switch, Form, Select } from "antd";
import {
  alarmPopUpCss,
  alarmPopUpFormInner,
  customGroupCss,
  customAlarmDetailWrapper,
  customAlarmTitleCss,
  customAlarmInputTimeCss,
  customAlarmWarningTextCss,
  CustomPopUpWrapper,
} from "../../style/theme";
import { selectRepeatData, selectWeightData, selectKindsData } from "../../pages/Alarm/config";
import {
  selectKorAgeData,
  selectEngAgeData,
  languageOption,
  timeSelect,
  selectDetailKorAgeData,
  selectDetailEngAgeData,
} from "../../pages/Cat/config";
import SelectHandler from "../Form/SelectHandler";
import usePortals from "../../hooks/usePortals";
import useInputs from "../../hooks/useInputs";
import { toUtc } from "../../utils/tostring";
import { isHourLargerThanTwelve, isMinLargerThanSixty, isNoString } from "../../utils/validate";
import { POST_ALARM_DATA_MOD_REQUEST, REMOVE_ALARM_DATA_REQUEST } from "../../Module/type";

const { Group } = Radio;
const { TextArea } = Input;

const { Option } = Select;

const PopUpWrapper = styled.div`
  ${alarmPopUpCss}
  button:first-child {
    background: #fff;
    color: #000;
    :hover {
      color: #40a9ff;
    }
  }
  button:last-child {
    background: #1890ff;
    border: 1px solid rgb(24, 144, 255);
    color: #fff;
    :hover {
      color: #fff;
    }
  }
  position: fixed;
  background: #fafafa;
`;

const UserFormInner = styled.div`
  ${alarmPopUpFormInner}
`;

const CustomGroup = styled(Group)`
  ${customGroupCss}
`;

const FlexWrapper = styled.div`
  ${customAlarmDetailWrapper}
  button {
    :last-child {
      background: rgba(0, 0, 0, 0.25);
      &.ant-switch-checked {
        background: #1890ff;
      }
    }
  }
`;

const CutomTitle = styled.p`
  ${customAlarmTitleCss}
`;

const InputTime = styled(Input)`
  ${customAlarmInputTimeCss}
`;

const CustomDatePicker = styled(DatePicker)`
  margin-right: 30px;
  .ant-picker-input {
    width: 104px;
    padding-right: 6px;
    border: 1px solid #d9d9d9 !important;
  }
  .ant-picker-clear {
    right: 6px;
  }
`;

const WarningText = styled.div`
  ${customAlarmWarningTextCss}
`;

const CustomTextArea = styled(TextArea)`
  border-radius: 10px;
`;

const CustomCheckBox = styled(Checkbox)`
  margin-left: 10px;
`;

const CustomCheckBoxEnd = styled(Checkbox)`
  margin: 0 5px 0 10px;
`;

const CustomForm = styled(Form)`
  padding: 0 !important;
`;

function AlarmDetailPopUp({ alarmDetailOpen, alarmDetailToggle, selected }) {
  const { alarmDetailInfo } = useSelector((state) => state.alarm);
  const dispatch = useDispatch();
  const [language, onChangeLanguage, setLang] = useInputs("");
  const [message, setMessage, setBeforeMessage] = useInputs("");
  const [searchData, setSearchData] = useState({});
  const [selecetReserveDt, setReserveDt] = useState({
    date: "",
    hour: "",
    min: "",
    time: "date",
  });
  const [repeatCycle, setRepeatCycle] = useState("W");
  const [reserveEndYn, setReserveEndYn] = useState(false);
  const [reserveEndDt, setReserveEndDt] = useState("");
  const [isReservation, setIsReservation] = useState(false);
  const [repeatType, setRepeatType] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [isSelectTiemCorrect, setIsSelectTiemCorrect] = useState(true);
  const complete = "완료";
  const [isAlert, setIsAlert] = useState(false);
  const [isPopup, setIsPopUp] = useState(false);
  const [sendChangeAlarmPopup, setSendChangeAlarmPopup] = useState(false);
  const [sendData, setSendData] = useState({});

  const isSelected = selected === complete;

  const { RenderModals } = usePortals();

  const resetPopUp = () => {
    // setLang(lang);
    setSearchData({ catAgeStatus: "", catWeightType: 0, hairType: 0 });
    setReserveDt({
      ...selecetReserveDt,
      date: "",
      hour: "",
      min: "",
      time: "date",
    });
    setIsReservation(false);
    setIsSelectTiemCorrect(true);
    setReserveEndYn(false);
    setReserveEndYn(false);
    setReserveEndDt("");
    setBeforeMessage("");
  };

  const setReserveDtState = (value) => {
    const dateValue = moment.unix(value).format("a");

    if (value !== 0 && value !== undefined) {
      const isDate = () => {
        if (dateValue === "am") return "date";
        return "night";
      };

      const hour = dateValue === "am" ? moment.unix(value).format("HH") : moment.unix(value).format("HH") - 12;

      const setZero = hour === "00" || hour === 0 ? 12 : hour;

      const min = moment.unix(value).format("mm");
      const date = moment.unix(value).format("YYYY-MM-DD");
      setReserveDt({
        ...selecetReserveDt,
        date,
        hour: setZero,
        min,
        time: isDate(),
      });
    }
  };

  const requestModPost = (data) => {
    try {
      dispatch({
        type: POST_ALARM_DATA_MOD_REQUEST,
        data,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const requestRemoveAlarm = (number) => {
    try {
      dispatch({
        type: REMOVE_ALARM_DATA_REQUEST,
        number,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const disabledDate = (current) => current && current < moment().startOf("day");

  const langCodeChangeToString = (langCode) => {
    switch (langCode) {
      case 1:
        return "한글";
      case 2:
        return "English";
      default:
        return "한글";
    }
  };

  const dateToTitle = (value) => {
    if (value === "date") return "오전";
    return "오후";
  };

  const selectTImeType = useCallback(
    (value, data) => {
      const { name } = data;
      setReserveDt({ ...selecetReserveDt, [name]: value });
    },
    [selecetReserveDt],
  );

  const selectOnChange = useCallback(
    (value, data) => {
      const { name } = data;
      setSearchData({ ...searchData, [name]: value });
    },
    [searchData],
  );

  const calcSendTime = () => {
    const { date, hour, min, time } = selecetReserveDt;
    let times;
    if (date === "" || hour === "" || min === "" || time === "") {
      return null;
    }
    if (time === "night") {
      times = `${date} ${+hour + 12}:${min}`;
      if (+hour === 12) {
        times = `${date} ${12}:${min}`;
      }
    } else {
      times = `${date} ${+hour === 12 ? 0 : +hour}:${min}`;
    }

    return moment(times, "YYYY.MM.DD HH:mm").utc().valueOf() / 1000;
  };

  const reservationValidate = () => {
    if (isReservation) {
      return calcSendTime();
    }
    return false;
  };

  const isReservationPassed =
    (isReservation && reservationValidate() === undefined) || (isReservation && reservationValidate() === false);

  const onChnageDate = (_, string) => {
    setReserveDt({
      ...selecetReserveDt,
      date: string,
    });
  };

  const InputHandler = useCallback(
    (e) => {
      const { value, name } = e.target;
      if (isHourLargerThanTwelve(name, value) || isMinLargerThanSixty(name, value) || isNoString(value)) {
        return setIsSelectTiemCorrect(false);
      }
      setIsSelectTiemCorrect(true);
      setReserveDt({
        ...selecetReserveDt,
        [name]: value,
      });
    },
    [selecetReserveDt],
  );

  const changeReserveEndYn = () => {
    if (reserveEndYn) return 1;
    return 0;
  };

  const repeatTypeTypeFalse = !repeatType || !isReservation;

  const closePopUp = () => {
    resetPopUp();
    return alarmDetailToggle();
  };

  const openPopUp = (text) => {
    setPopupText(text);
    setIsPopUp(true);
  };

  const compareObject = (object) =>
    Object.keys(object)
      .sort()
      .reduce((newObj, key) => {
        if (key === "catAgeStatus") {
          newObj[key] = object[key] === null ? "" : object[key];
        } else {
          newObj[key] = object[key];
        }
        return newObj;
      }, {});

  const onSubmit = () => {
    if (message === "") return openPopUp("메시지를 입력해주세요");
    if (selected === complete) return;
    if (isReservationPassed) return setIsSelectTiemCorrect(false);

    let newData = {
      pushReserveSeqNo: alarmDetailInfo.pushReserveSeqNo,
      ...searchData,
      message,
      repeatType: repeatTypeTypeFalse ? 0 : 1,
      reserveDt: reservationValidate(),
      reserveEndYn: changeReserveEndYn(),
      reserveType: 1,
    };

    const { date, hour, min, time } = selecetReserveDt;

    const isSelectedReserveDt = date === "" || hour === "" || min === "" || time === "";

    if (isSelectedReserveDt) {
      return openPopUp("예약 발송일을 확인해주세요");
    }

    if (repeatType) {
      if (!repeatCycle) return;

      newData = { ...newData, repeatCycle };

      if (reserveEndYn) {
        if (reserveEndDt) {
          if (moment(moment.unix(reserveEndDt).format("YYYY-MM-DD")).isBefore(date)) {
            return openPopUp("종료일을 확인해주세요");
          }
          newData = { ...newData, reserveEndDt: +reserveEndDt };
        } else {
          return openPopUp("종료일을 확인해주세요");
        }
      }
    }

    const compareAlarmDetailInfo = {
      pushReserveSeqNo: alarmDetailInfo.pushReserveSeqNo,
      catAgeStatus: alarmDetailInfo.catAgeStatus,
      hairType: alarmDetailInfo.hairType,
      catWeightType: alarmDetailInfo.catWeightType,
      message: alarmDetailInfo.message,
      repeatType: alarmDetailInfo.repeatType,
      reserveDt: +alarmDetailInfo.reserveDt,
      reserveEndYn: alarmDetailInfo.reserveEndYn,
      reserveType: alarmDetailInfo.reserveType,
      reserveEndDt: +alarmDetailInfo.reserveEndDt,
      repeatCycle: alarmDetailInfo.repeatCycle,
    };

    if (
      JSON.stringify(compareObject(compareAlarmDetailInfo)) ===
      JSON.stringify(
        compareObject({
          ...newData,
          ...(repeatCycle && repeatType ? { repeatCycle } : { repeatCycle: null }),
          ...(reserveEndYn && reserveEndDt ? { reserveEndDt: +reserveEndDt } : { reserveEndDt: 0 }),
        }),
      )
    ) {
      closePopUp();
    } else {
      setSendChangeAlarmPopup(true);
      setSendData(newData);
    }
  };

  const sendChangedPopUp = () => {
    requestModPost(sendData);
    setSendData({});
    setSendChangeAlarmPopup(false);
    closePopUp();
  };

  const deleteAlarm = (number) => {
    requestRemoveAlarm(number);
    alarmDetailToggle();
  };

  const onChangeEndDate = (_, string) => {
    if (string) {
      setReserveEndDt(toUtc(moment(string, "YY.MM.DD").format("YYYY-MM-DD")));
    }
  };

  const setCatDefaultName = () => {
    if (alarmDetailInfo.catAgeStatus !== "" && alarmDetailInfo.catAgeStatus !== "string") {
      if (language === "English") {
        // eslint-disable-next-line no-unused-expressions
        const findAge = selectEngAgeData.find((age) => age.value === alarmDetailInfo.catAgeStatus).value;
        // eslint-disable-next-line no-unused-expressions
        return findAge;
      }
      // eslint-disable-next-line no-unused-expressions
      const findAge = selectKorAgeData.find((age) => age.value === alarmDetailInfo.catAgeStatus).value;
      // eslint-disable-next-line no-unused-expressions
      return findAge;
    }
    return selectKorAgeData[0].value;
  };

  const switchReserve = (e) => {
    if (e === true) {
      setSearchData({ ...searchData });
      setRepeatType(1);
    } else {
      setRepeatType(0);
    }
  };

  useEffect(() => {
    if (alarmDetailInfo !== null) {
      setLang(langCodeChangeToString(alarmDetailInfo.langCode));
      setBeforeMessage(alarmDetailInfo.message);
      setRepeatType(alarmDetailInfo.repeatType);

      setSearchData({
        catAgeStatus: setCatDefaultName(),
        hairType: selectKindsData[alarmDetailInfo.hairType]?.value || selectKindsData[0].value,
        catWeightType: selectWeightData[alarmDetailInfo.catWeightType]?.value || selectWeightData[0].value,
      });

      if (alarmDetailInfo.reserveType !== 0) {
        if (alarmDetailInfo.reserveType === 1) {
          setIsReservation(true);
        } else {
          setIsReservation(false);
        }
        setReserveDtState(alarmDetailInfo.reserveDt);
      }

      if (alarmDetailInfo.repeatType) {
        setRepeatCycle(alarmDetailInfo.repeatCycle);
        setReserveEndYn(alarmDetailInfo.reserveEndYn);

        if (alarmDetailInfo.reserveEndDt) {
          setReserveEndDt(alarmDetailInfo.reserveEndDt);
        }
      }
      // eslint-disable-next-line no-unused-expressions
    }
  }, [alarmDetailInfo]);

  const popUpclose = () => {
    deleteAlarm(alarmDetailInfo.pushReserveSeqNo);
    setIsAlert(false);
  };

  return (
    <>
      <RenderModals>
        {isAlert && (
          <CustomPopUpWrapper>
            <div>
              <p>{popupText}</p>
              <Button type="button" onClick={() => setIsAlert(false)}>
                취소
              </Button>
              <Button type="button" onClick={() => popUpclose()}>
                확인
              </Button>
            </div>
          </CustomPopUpWrapper>
        )}
      </RenderModals>
      <RenderModals>
        {sendChangeAlarmPopup && (
          <CustomPopUpWrapper>
            <div>
              <p>
                수정 사항이 있습니다.
                <br />
                이대로적용하시겠습니까?
              </p>
              <Button type="button" onClick={() => setSendChangeAlarmPopup(false)}>
                취소
              </Button>
              <Button type="button" onClick={() => sendChangedPopUp()}>
                확인
              </Button>
            </div>
          </CustomPopUpWrapper>
        )}
      </RenderModals>
      <RenderModals>
        {isPopup && (
          <CustomPopUpWrapper>
            <div>
              <p>{popupText}</p>
              <Button type="button" onClick={() => setIsPopUp(false)}>
                확인
              </Button>
            </div>
          </CustomPopUpWrapper>
        )}
      </RenderModals>

      <CustomForm
        onFinish={onSubmit}
        style={{
          display: alarmDetailOpen ? "block" : "none",
        }}
      >
        {alarmDetailInfo !== null && (
          <PopUpWrapper>
            <UserFormInner>
              <CustomGroup
                options={languageOption}
                onChange={onChangeLanguage}
                value={language}
                optionType="button"
                disabled
              />
            </UserFormInner>
            <h3>발송 조건 선택</h3>
            <UserFormInner>
              <label>품종</label>
              <Form.Item name="hairType" noStyle>
                <SelectHandler
                  data={selectKindsData}
                  onChange={selectOnChange}
                  isValue={searchData.hairType}
                  disable={isSelected}
                />
              </Form.Item>
              <label>체중</label>
              <Form.Item name="catWeightType" noStyle>
                <SelectHandler
                  data={selectWeightData}
                  onChange={selectOnChange}
                  isValue={searchData.catWeightType}
                  disable={isSelected}
                />
              </Form.Item>
              <Form.Item noStyle>
                <label>나이</label>
                <Form.Item name="catAgeStatus" noStyle>
                  <SelectHandler
                    data={language === "English" ? selectDetailEngAgeData : selectDetailKorAgeData}
                    onChange={selectOnChange}
                    isValue={searchData.catAgeStatus}
                    disable={isSelected}
                  />
                </Form.Item>
              </Form.Item>
            </UserFormInner>
            <FlexWrapper>
              <h5>예약 발송</h5>
              <CustomCheckBox checked={isReservation} disabled />
            </FlexWrapper>
            <UserFormInner>
              <CustomDatePicker
                bordered={false}
                disabled={!isReservation || isSelected}
                onChange={onChnageDate}
                defaultValue={
                  alarmDetailInfo.reserveDt != null &&
                  alarmDetailInfo.reserveType !== 0 &&
                  moment(moment.unix(alarmDetailInfo.reserveDt).format("YY-MM-DD"), "YY-MM-DD")
                }
                format="YY-MM-DD"
                placeholder="YY-MM-DD"
                disabledDate={disabledDate}
              />
              <Select
                value={searchData.catWeightType}
                onChange={selectTImeType}
                style={{ width: 100 }}
                disabled={!isReservation || isSelected}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                value={selecetReserveDt.time ? dateToTitle(selecetReserveDt.time) : null}
              >
                {timeSelect.map((dataValue) => (
                  <Option name={dataValue.name} value={dataValue.value} key={dataValue.id}>
                    {dataValue.title}
                  </Option>
                ))}
              </Select>
              <InputTime
                maxLength={2}
                name="hour"
                onChange={InputHandler}
                disabled={!isReservation || isSelected}
                value={selecetReserveDt.hour}
              />
              <InputTime
                maxLength={2}
                name="min"
                onChange={InputHandler}
                disabled={!isReservation || isSelected}
                value={selecetReserveDt.min}
              />
              <WarningText style={{ display: isSelectTiemCorrect ? "none" : "block" }}>
                입력시간을 확인해주세요
              </WarningText>
            </UserFormInner>
            <FlexWrapper>
              <h5>반복 알림</h5>
              <Switch
                style={{ border: "none" }}
                onChange={switchReserve}
                checked={repeatType}
                disabled={isSelected || !isReservation}
              />
            </FlexWrapper>
            <UserFormInner style={{ opacity: !repeatType || isSelected ? 0 : 1 }}>
              <CutomTitle style={{ marginLeft: 12 }}>주기</CutomTitle>
              <SelectHandler
                data={selectRepeatData}
                onChange={setRepeatCycle}
                disable={!repeatType}
                isValue={repeatCycle}
              />
              <CutomTitle style={{ marginLeft: 40 }}>
                <CustomCheckBoxEnd onChange={() => setReserveEndYn(!reserveEndYn)} checked={reserveEndYn} />
                종료일
              </CutomTitle>
              <CustomDatePicker
                bordered={false}
                disabled={!reserveEndYn || !repeatType}
                onChange={onChangeEndDate}
                format="YY.MM.DD"
                placeholder="YY.MM.DD"
                defaultValue={
                  alarmDetailInfo.reserveEndDt &&
                  moment(moment.unix(alarmDetailInfo.reserveEndDt).format("YY-MM-DD"), "YY-MM-DD")
                }
                disabledDate={disabledDate}
              />
            </UserFormInner>
            <h3>내용 입력</h3>
            <UserFormInner>
              <CustomTextArea
                maxLength={300}
                placeholder="알람 발송 내용 입력"
                onChange={setMessage}
                value={message}
                disabled={isSelected}
              />
            </UserFormInner>
            <UserFormInner>
              {isSelected ? (
                <Button type="button" onClick={() => alarmDetailToggle()}>
                  확인
                </Button>
              ) : (
                // onClick={() => deleteAlarm(alarmDetailInfo.pushReserveSeqNo)}
                <>
                  <Button
                    type="button"
                    onClick={() => {
                      setPopupText("예약 알림을 삭제하시겠습니까?");
                      setIsAlert(true);
                    }}
                  >
                    알림삭제
                  </Button>
                  <Button htmlType="submit" type="primary">
                    확인
                  </Button>
                </>
              )}
            </UserFormInner>
          </PopUpWrapper>
        )}
      </CustomForm>
    </>
  );
}

AlarmDetailPopUp.propTypes = {
  alarmDetailOpen: PropTypes.bool.isRequired,
  alarmDetailToggle: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

AlarmDetailPopUp.defaultProps = {
  selected: "완료",
};

export default AlarmDetailPopUp;
