import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import qs from 'qs';
import { posts } from './index';
import {
  GET_USER_LIST_DATA_REQUEST,
  GET_USER_LIST_DATA_SUCCESS,
  GET_USER_LIST_DATA_FAILURE,
  GET_USER_DETAIL_DATA_REQUEST,
  GET_USER_DETAIL_DATA_SUCCESS,
  GET_USER_DETAIL_DATA_FAILURE,
  USER_SEARCHED_SUCCESS,
} from '../type';

function getUserListAPI(data) {
  return posts.get('user/list', {
    params: data,
    paramsSerializer: (params) =>
      qs.stringify(params, { allowDots: true, arrayFormat: 'comma', skipNulls: true }),
  });
}

function getUserDetailDataAPI(id) {
  return posts.get(`user/info/${id}`);
}

function* getUserListData(action) {
  try {
    const result = yield call(getUserListAPI, action.data, action.searched);
    yield put({
      type: GET_USER_LIST_DATA_SUCCESS,
      data: result.data,
    });
    if (action.searched) {
      yield put({
        type: USER_SEARCHED_SUCCESS,
      });
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_USER_LIST_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

function* getUserDetailData(action) {
  try {
    const result = yield call(getUserDetailDataAPI, action.id);

    yield put({
      type: GET_USER_DETAIL_DATA_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_USER_DETAIL_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchGetUserListData() {
  yield takeLatest(GET_USER_LIST_DATA_REQUEST, getUserListData);
}

function* watchGetUserDetailData() {
  yield takeLatest(GET_USER_DETAIL_DATA_REQUEST, getUserDetailData);
}

export default function* userPostSaga() {
  yield all([fork(watchGetUserListData), fork(watchGetUserDetailData)]);
}
