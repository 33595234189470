/* eslint-disable no-buffer-constructor */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types";
import styled from "styled-components";
import { Avatar, Image } from "antd";
import { CustomAvatarImage } from "../style/theme";
import catImage from "../assets/cat1.png";
import { posts } from "../Module/saga/index";

const CustomImage = styled(Image)`
  ${CustomAvatarImage}
`;

const CustomImageSrc = styled(Image)`
  width: 100%;
  height: 100%;
`;

const ImgWrapper = styled.div`
  .ant-avatar {
    background: #fff;
  }
  .ant-avatar .ant-image {
    background: #fff;
  }
`;

function DetailPopUpCatTableImg({ number, size }) {
  const [imageSrc, setImageSrc] = useState("");
  const { allLoading } = useSelector((state) => state.main);

  async function getData() {
    const result = await posts.get(`utils/file/${number}`, { responseType: "arraybuffer" });
    return result.data;
  }

  useEffect(() => {
    getData()
      .then((data) => setImageSrc(new Buffer(data, "binary").toString("base64")))
      .catch(() => {
        setImageSrc(catImage);
      });
  }, []);

  return (
    <ImgWrapper title="picture">
      {imageSrc === "" ? (
        <Avatar size={size} />
      ) : (
        <Avatar
          size={size}
          icon={
            imageSrc === catImage ? (
              <CustomImage preview={false} src={catImage} />
            ) : (
              !allLoading && imageSrc && <CustomImageSrc preview={false} src={`data:image/png;base64, ${imageSrc}`} />
            )
          }
        />
      )}
    </ImgWrapper>
  );
}

DetailPopUpCatTableImg.propTypes = {
  number: PropTypes.number,
  size: PropTypes.number.isRequired,
};

DetailPopUpCatTableImg.defaultProps = {
  number: null,
};

export default DetailPopUpCatTableImg;
