/* eslint-disable prefer-const */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Layout, Menu, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import catmosLogo from "../assets/img_logo_168.png";

import { getSessionStorageLoginTime } from "../utils/login";
import { LOG_OUT_REQUEST } from "../Module/type";
import usePortals from "../hooks/usePortals";
import { CustomPopUpWrapper } from "../style/theme";

const { Sider } = Layout;

const CustomSider = styled(Sider)`
  padding: 60px 0 20px;
  min-height: 100vh;
  z-index: 55;
  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 24px;
    font-size: 22px;
    height: auto;
    font-weight: 600;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    font-weight: 600;
  }
`;

const CustomLogo = styled.h5`
  margin-left: 16px;
  width: 106px;
  height: 18px;
  background-image: url(${catmosLogo});
  background-size: cover;
`;

const AdminInfo = styled.div`
  margin: 40px 0;
  text-align: center;
  color: #000;
  p {
    font-size: 28px;
    font-weight: 800;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 16px;
  justify-content: center;
  button {
    margin-top: 10px;

    &:first-child {
      margin-right: 10px;
    }
  }
`;

const LogOutPopup = styled(CustomPopUpWrapper)`
  button {
    :first-of-type {
      right: 80px;
    }
  }
`;

function Nav({ location }) {
  const [isPopup, setPopup] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const timer = useRef(null);
  const [time, setTime] = useState(() => new Date().getTime() - Number(getSessionStorageLoginTime()));

  const { RenderModals } = usePortals();

  const activeItem = () => {
    switch (location) {
      case "/main":
        return 0;
      case "/user":
        return 1;
      case "/cat":
        return 2;
      case "/band":
        return 3;
      case "/alarm":
        return 4;
      default:
        return null;
    }
  };

  const list = [
    { title: "홈", link: "/main" },
    { title: "회원관리", link: "/user" },
    { title: "고양이관리", link: "/cat" },
    { title: "밴드관리", link: "/band" },
    { title: "알람관리", link: "/alarm" },
  ];

  const isCUstomSider = () => {
    if (location === "/login" || location === "/login/info") {
      return "none";
    }
    return "block";
  };

  const historyTo = (path) => history.push(path);

  const onLogOut = () => {
    try {
      dispatch({
        type: LOG_OUT_REQUEST,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setPopup(false);
        setTime(0);
        clearInterval(timer.current);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    timer.current = setInterval(() => {
      const newTime = new Date().getTime();
      if (newTime < Number(getSessionStorageLoginTime())) {
        return clearInterval(timer.current);
      }

      setTime(newTime - Number(getSessionStorageLoginTime()));
    }, 1000);
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const setTimeText = () => {
    let min = Math.floor(time / 60000);
    let hour = Math.floor(min / 60);

    if (time === 0 || time < 0) return "00:00";
    return `${hour > 10 ? hour : `0${hour}`}:${min % 60 > 10 ? min % 60 : `0${min % 60}`}`;
  };

  console.log();

  return (
    <>
      <RenderModals>
        {isPopup && (
          <LogOutPopup>
            <div>
              <p>로그아웃 하시겠습니까?</p>
              <Button type="button" onClick={() => setPopup(false)}>
                취소
              </Button>
              <Button
                type="button"
                onClick={() => {
                  onLogOut();
                  setPopup(false);
                  setTime(0);
                  clearInterval(timer.current);
                }}
              >
                확인
              </Button>
            </div>
          </LogOutPopup>
        )}
      </RenderModals>
      <CustomSider width={300} collapsedWidth="0" style={{ display: isCUstomSider() }}>
        <button type="button" onClick={() => history.push("/main")}>
          <CustomLogo />
        </button>
        <AdminInfo>
          <p>Administrator</p>
          <span>
            접속시간:
            {setTimeText()}
          </span>
          <ButtonWrapper>
            <Button type="button" onClick={() => setPopup(true)}>
              로그아웃
            </Button>
            <Button type="button" onClick={() => historyTo("/login/info")}>
              비밀번호 변경
            </Button>
          </ButtonWrapper>
        </AdminInfo>
        <Menu theme="dark" mode="inline">
          {list.map((value, index) => (
            <Menu.Item
              key={index}
              className={activeItem() === index && "active"}
              onClick={() => historyTo(`${value.link}`)}
            >
              {value.title}
              <RightOutlined />
            </Menu.Item>
          ))}
        </Menu>
      </CustomSider>
    </>
  );
}

Nav.propTypes = {
  location: PropTypes.string.isRequired,
};

export default Nav;
