import produce from "immer";
import {
  GET_BAND_LIST_DATA_REQUEST,
  GET_BAND_LIST_DATA_SUCCESS,
  GET_BAND_LIST_DATA_FAILURE,
  GET_BAND_DETAIL_DATA_REQUEST,
  GET_BAND_DETAIL_DATA_SUCCESS,
  GET_BAND_DETAIL_DATA_FAILURE,
  BAND_SEARCHED_SUCCESS,
  GET_BAND_FW_REQUEST,
  GET_BAND_FW_SUCCESS,
  GET_BAND_FW_FAILURE,
  GET_BAND_INFO_FW_REQUEST,
  GET_BAND_INFO_FW_SUCCESS,
  GET_BAND_INFO_FW_FAILURE,
  POST_UPDATE_BAND_FW_REQUEST,
  POST_UPDATE_BAND_FW_SUCCESS,
  POST_UPDATE_BAND_FW_FAILURE,
} from "../type";

export const initalState = {
  bandListData: [],
  bandListPageData: [],
  bandDetailDatabandInfo: [],
  bandInfo: {},
  bandSearched: false,
  bandListDataLoading: false,
  bandListDataDone: false,
  bandListDataError: false,
  bandDetailDataLoading: false,
  bandDetailDataDone: false,
  bandDetailDataError: false,
  bandFwLoading: false,
  bandFwDone: false,
  bandFwError: false,
  bandFwData: "",
  bandFwUpdateLoading: false,
  bandFwUpdateDone: false,
  bandFwUpdateError: false,
  bandInfoLoading: false,
  bandInfoDone: false,
  bandInfoError: false,
};

const bandPostReducer = (state = initalState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_BAND_LIST_DATA_REQUEST:
        draft.bandListDataLoading = true;
        draft.bandListDataError = null;
        draft.bandListData = [];
        break;
      case GET_BAND_LIST_DATA_SUCCESS: {
        draft.bandListDataLoading = false;
        draft.bandListDataDone = true;
        draft.bandListData = action.data.payload.items;
        draft.bandListPageData = action.data.payload.paging;
        break;
      }
      case GET_BAND_LIST_DATA_FAILURE:
        draft.bandListDataLoading = false;
        draft.bandListDataError = action.error;
        break;
      case GET_BAND_DETAIL_DATA_REQUEST:
        draft.bandDetailDatabandInfo = [];
        draft.bandDetailDataLoading = true;
        draft.bandDetailDataError = null;
        break;
      case GET_BAND_DETAIL_DATA_SUCCESS: {
        draft.bandDetailDatabandInfo = action.data.payload;
        draft.bandDetailDataLoading = false;
        draft.bandDetailDataDone = true;
        break;
      }
      case GET_BAND_DETAIL_DATA_FAILURE:
        draft.bandDetailDataLoading = false;
        draft.bandDetailDataError = action.error;
        break;
      case BAND_SEARCHED_SUCCESS:
        draft.bandSearched = true;
        break;
      case POST_UPDATE_BAND_FW_REQUEST:
        draft.bandFwUpdateLoading = true;
        draft.bandFwUpdateError = null;
        break;
      case POST_UPDATE_BAND_FW_SUCCESS:
        draft.bandFwUpdateLoading = false;
        draft.bandFwUpdateDone = true;
        break;
      case POST_UPDATE_BAND_FW_FAILURE:
        draft.bandFwUpdateLoading = false;
        draft.bandFwUpdateError = action.error;
        break;
      case GET_BAND_FW_REQUEST:
        draft.bandFwLoading = false;
        draft.bandFwError = action.error;
        break;
      case GET_BAND_FW_SUCCESS:
        draft.bandFwLoading = false;
        draft.bandFwDone = true;
        draft.bandFwData = action.data.payload;
        break;
      case GET_BAND_FW_FAILURE:
        draft.bandFwLoading = false;
        draft.bandFwError = action.error;
        break;
      case GET_BAND_INFO_FW_REQUEST:
        draft.bandInfoLoading = false;
        draft.bandInfoError = action.error;
        break;
      case GET_BAND_INFO_FW_SUCCESS:
        draft.bandInfoLoading = false;
        draft.bandInfoDone = true;
        draft.bandInfo = action.data.payload;
        break;
      case GET_BAND_INFO_FW_FAILURE:
        draft.bandInfoLoading = false;
        draft.bandInfoError = action.error;
        break;
      default:
        return state;
    }
  });
export default bandPostReducer;
