import { useState, useCallback } from 'react';

export default (initValue = null) => {
  const [value, setter] = useState(initValue);
  const onChangeHandler = useCallback(
    (e) => {
      setter(e.target.value);
    },
    [value]
  );
  return [value, onChangeHandler, setter];
};
