export const columns = [
  {
    title: "상태",
    dataIndex: "name",
  },
  {
    title: "등록일",
    dataIndex: "regDt",
  },
  {
    title: "발송일",
    dataIndex: "reverseDt",
  },
  {
    title: "내용",
    dataIndex: "message",
  },
  {
    title: "언어",
    dataIndex: "langCode",
  },
  {
    title: "나이",
    dataIndex: "catAgeStatus",
  },
  {
    title: "체중",
    dataIndex: "catWegiht",
  },
  {
    title: "품종",
    dataIndex: "email",
  },
];

export const resColumns = [
  {
    title: "상태",
    dataIndex: "name",
  },
  {
    title: "등록일",
    dataIndex: "regDt",
  },
  {
    title: "발송예정일",
    dataIndex: "reverseDt",
  },
  {
    title: "내용",
    dataIndex: "message",
  },
  {
    title: "언어",
    dataIndex: "langCode",
  },
  {
    title: "나이",
    dataIndex: "catAgeStatus",
  },
  {
    title: "체중",
    dataIndex: "catWegiht",
  },
  {
    title: "품종",
    dataIndex: "email",
  },
];

export const selectRepeatData = [
  {
    id: 0,
    name: "repeatCycle",
    title: "매주마다",
    value: "W",
  },
  {
    id: 1,
    name: "repeatCycle",
    title: "매월마다",
    value: "M",
  },
  {
    id: 2,
    name: "repeatCycle",
    title: "매년마다",
    value: "Y",
  },
];

export const selectedOption = ["완료", "예약"];
export const selectWeightData = [
  {
    id: 0,
    name: "catWeightType",
    title: "전체",
    value: 0,
  },
  {
    id: 1,
    name: "catWeightType",
    title: "마른",
    value: 1,
  },
  {
    id: 2,
    name: "catWeightType",
    title: "정상",
    value: 2,
  },
  {
    id: 3,
    name: "catWeightType",
    title: "비만경향",
    value: 3,
  },
  {
    id: 4,
    name: "catWeightType",
    title: "비만",
    value: 4,
  },
];

export const selectEngAgeData = [
  {
    id: 0,
    name: "catAgeStatus",
    title: "All",
    value: "",
  },
  {
    id: 1,
    name: "catAgeStatus",
    title: "kitten",
    value: "kitten",
  },
  {
    id: 2,
    name: "catAgeStatus",
    title: "junior",
    value: "junior",
  },
  {
    id: 3,
    name: "catAgeStatus",
    title: "adult",
    value: "adult",
  },
  {
    id: 4,
    name: "catAgeStatus",
    title: "mature",
    value: "mature",
  },
  {
    id: 5,
    name: "catAgeStatus",
    title: "senior",
    value: "senior",
  },
  {
    id: 6,
    name: "catAgeStatus",
    title: "super senior",
    value: "supersenio",
  },
];

export const selectKorAgeData = [
  {
    id: 0,
    name: "catAgeStatus",
    title: "전체",
    value: "",
  },
  {
    id: 1,
    name: "catAgeStatus",
    title: "영유아기",
    value: "kitten",
  },
  {
    id: 2,
    name: "catAgeStatus",
    title: "아동청소년기",
    value: "junior",
  },
  {
    id: 3,
    name: "catAgeStatus",
    title: "청년기",
    value: "adult",
  },
  {
    id: 4,
    name: "catAgeStatus",
    title: "중장년기",
    value: "mature",
  },
  {
    id: 5,
    name: "catAgeStatus",
    title: "노년기",
    value: "senior",
  },
];

export const selectKindsData = [
  {
    id: 0,
    name: "hairType",
    title: "전체",
    value: 0,
  },
  {
    id: 2,
    name: "hairType",
    title: "단묘",
    value: 1,
  },
  {
    id: 1,
    name: "hairType",
    title: "장묘",
    value: 2,
  },
];

export const selectKindsDataValueNull = [
  {
    id: 0,
    name: "hairType",
    title: "전체",
    value: null,
  },
  {
    id: 2,
    name: "hairType",
    title: "단묘",
    value: 1,
  },
  {
    id: 1,
    name: "hairType",
    title: "장묘",
    value: 2,
  },
];
