import produce from 'immer';
import {
  GET_USER_LIST_DATA_REQUEST,
  GET_USER_LIST_DATA_SUCCESS,
  GET_USER_LIST_DATA_FAILURE,
  GET_USER_DETAIL_DATA_REQUEST,
  GET_USER_DETAIL_DATA_SUCCESS,
  GET_USER_DETAIL_DATA_FAILURE,
  USER_SEARCHED_SUCCESS,
} from '../type';

export const initalState = {
  userListData: [],
  userListPageData: [],
  userDetailDataUserInfo: [],
  userDetailDataUserCatList: [],
  userSearched: false,
  userListDataLoading: false,
  userListDataDone: false,
  userListDataError: false,
  userDetailDataLoading: false,
  userDetailDataDone: false,
  userDetailDataError: false,
};

const userPostReudcer = (state = initalState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_USER_LIST_DATA_REQUEST:
        draft.userListDataLoading = true;
        draft.userListDataError = null;
        break;
      case GET_USER_LIST_DATA_SUCCESS: {
        draft.userListDataLoading = false;
        draft.userListDataDone = true;
        draft.userListData = action.data.payload.userList;
        draft.userListPageData = action.data.payload.paging;
        break;
      }
      case GET_USER_LIST_DATA_FAILURE:
        draft.userListDataLoading = false;
        draft.userListDataError = action.error;
        break;
      case GET_USER_DETAIL_DATA_REQUEST:
        draft.userDetailDataLoading = true;
        draft.userDetailDataError = null;
        break;
      case GET_USER_DETAIL_DATA_SUCCESS: {
        draft.userDetailDataUserInfo = action.data.payload.userInfo;
        draft.userDetailDataUserCatList = action.data.payload.catList;
        draft.userDetailDataLoading = false;
        draft.userDetailDataDone = true;
        break;
      }
      case GET_USER_DETAIL_DATA_FAILURE:
        draft.userDetailDataLoading = false;
        draft.userDetailDataError = action.error;
        break;
      case USER_SEARCHED_SUCCESS:
        draft.userSearched = true;
        break;
      default:
        return state;
    }
  });
export default userPostReudcer;
