import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { regDateToString, repeatToString, hairTypeToKor, weightToKor } from "../../utils/tostring";
import { columns, resColumns } from "../../pages/Alarm/config";
import { customTableCss } from "../../style/theme";
import { selectKorAgeData } from "../../pages/Cat/config";

const CustomTable = styled.table`
  ${customTableCss}
  col {
    :first-of-type {
      width: 8%;
    }
    :nth-of-type(2),
    :nth-of-type(3) {
      width: 12%;
    }
    :nth-of-type(5) {
      width: 9%;
    }
    :nth-of-type(6) {
      width: 13%;
    }
    :nth-of-type(4) {
      width: 30%;
    }
  }
`;

const CustomDivResColor = styled.div`
  :after {
    color: ${(props) => (props.selected === 1 ? "#1890ff" : "#fff")};
  }
  color: ${(props) => (props.selected === 1 ? "#1890ff" : "black")}!important;
`;

function AlarmTableHandler({ onSubmit, page, clickHandler, selected }) {
  const { alarmListData, alarmPostDataDone, alarmModPostDone, alarmRemoveDone } = useSelector((state) => state.alarm);

  const langCodeChangeToString = (langCode) => {
    switch (langCode) {
      case 1:
        return "한글";
      case 2:
        return "English";
      default:
        return "한글";
    }
  };

  useEffect(() => {
    onSubmit();
  }, [page, onSubmit, alarmPostDataDone, alarmModPostDone, alarmRemoveDone, selected]);

  const returnKorAge = (value) => {
    if (!value || !selectKorAgeData.find((data) => data.value === value)) return "전체";
    // eslint-disable-next-line no-unused-expressions
    const titleData = selectKorAgeData.find((data) => data.value === value).title;
    if (titleData) return titleData;
    return "전체";
  };

  return (
    <>
      {alarmListData && (
        <CustomTable>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              {selected === "완료"
                ? columns.map((v, i) => <th key={i}>{v.title}</th>)
                : resColumns.map((v, i) => <th key={i}>{v.title}</th>)}
            </tr>
          </thead>

          <tbody>
            {alarmListData &&
              alarmListData.slice(0, 20).map((v, i) => (
                <tr key={i} onClick={() => clickHandler(v.pushReserveSeqNo)}>
                  <td>
                    <CustomDivResColor selected={v.reserveStatus} title={repeatToString(v.reserveStatus)}>
                      {selected === "완료" ? "완료" : "예약"}
                    </CustomDivResColor>
                  </td>
                  <td>
                    <div title={regDateToString(v.regDt)}>{regDateToString(v.regDt)}</div>
                  </td>
                  <td>
                    {selected === "완료" ? (
                      <CustomDivResColor selected={v.compDt} title={regDateToString(v.compDt)}>
                        {regDateToString(v.compDt)}
                      </CustomDivResColor>
                    ) : (
                      <CustomDivResColor selected={v.reserveStatus} title={regDateToString(v.reserveDt)}>
                        {regDateToString(v.reserveDt)}
                      </CustomDivResColor>
                    )}
                  </td>
                  <td>
                    <CustomDivResColor selected={v.reserveStatus} title={v.message}>
                      {v.message}
                    </CustomDivResColor>
                  </td>
                  <td>
                    <div title={langCodeChangeToString(v.langCode)}>{langCodeChangeToString(v.langCode)}</div>
                  </td>
                  <td>
                    <div title={v.catAgeStatus ? returnKorAge(v.catAgeStatus) : "전체"}>
                      {v.catAgeStatus ? returnKorAge(v.catAgeStatus) : "전체"}
                    </div>
                  </td>
                  <td>
                    <div title={`${weightToKor(v.catWeightType)}`}>{`${weightToKor(v.catWeightType)}`}</div>
                  </td>
                  <td>
                    <div title={hairTypeToKor(v.hairType)}>{hairTypeToKor(v.hairType)}</div>
                  </td>
                </tr>
              ))}
          </tbody>
        </CustomTable>
      )}
    </>
  );
}

AlarmTableHandler.propTypes = {
  page: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
  clickHandler: PropTypes.func,
  selected: PropTypes.string,
};

AlarmTableHandler.defaultProps = {
  onSubmit: () => {},
  clickHandler: () => {},
  selected: "완료",
};

export default AlarmTableHandler;
