/* eslint-disable react/no-typos */
import React from "react";
import PropTypes from "prop-types";

import { Select } from "antd";

const { Option } = Select;

function SelectHandler({ data, onChange, disable, defaultName, isValue }) {
  const setValue = () => {
    const isIndex = data.findIndex((value) => value.value === isValue);

    if (isIndex) {
      return isIndex;
    }

    return 0;
  };

  return (
    <>
      {defaultName !== null ? (
        <Select
          defaultValue={defaultName}
          onChange={onChange}
          style={{ width: 100 }}
          disabled={disable}
          value={data[setValue()]?.title}
        >
          {data.map((dataValue) => (
            <Option name={dataValue.name} value={dataValue.value} key={dataValue.id}>
              {dataValue.title}
            </Option>
          ))}
        </Select>
      ) : (
        <Select
          defaultValue={data[0].title}
          onChange={onChange}
          style={{ width: 100 }}
          disabled={disable}
          value={data[setValue()]?.title}
        >
          {data.map((dataValue) => (
            <Option name={dataValue.name} value={dataValue.value} key={dataValue.id}>
              {dataValue.title}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
}

SelectHandler.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disable: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  defaultName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectHandler.defaultProps = {
  disable: false,
  defaultName: null,
  isValue: null,
};

export default SelectHandler;
