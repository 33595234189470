import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import { genderToString, boolToString, smallThanTen, regDateToString, weightToKor } from "../../utils/tostring";
import { columns } from "../../pages/Cat/config";
import { customTableCss, customLangButtonWrapperCss, customDateFlexWrapperCss } from "../../style/theme";

const CustomTable = styled.table`
  ${customTableCss}
  col:first-of-type {
    width: 13%;
  }
  col:nth-of-type(2) {
    width: 12%;
  }
  col:nth-of-type(3) {
    width: 7%;
  }
  col:nth-of-type(4) {
    width: 7%;
    min-width: 80px;
  }
  col:nth-of-type(5) {
    width: 14%;
  }
  col:nth-of-type(6) {
    width: 10%;
  }
  col:nth-of-type(7) {
    width: 12%;
  }
  col:nth-of-type(8) {
    width: 7%;
  }
  col:last-of-type {
    width: 8%;
  }
  tbody {
    tr {
    }
  }
  th {
    padding: 16px 10px;
  }
`;

const CustomLangButtonWrapper = styled.div`
  ${customLangButtonWrapperCss}
`;

const CustomFlexWrapper = styled.div`
  ${customDateFlexWrapperCss}
`;

const CustomColorSearched = styled.div`
  color: ${(props) => props.searched && "#1890ff"};
`;

function CatTableHandler({ onSubmit, sortOption, page, clickHandler, ageLang, postData, pagingSortOption }) {
  const { catListData, catSearched } = useSelector((state) => state.cat);

  const [sort, setSort] = useState("");

  useEffect(() => {
    onSubmit();
  }, [page, sort]);

  const langHandler = (option, value) => {
    sortOption(option, value);
  };

  const sortHandler = (option, value) => {
    setSort(value);
    sortOption(option, value);
  };

  const theadButton = (th) => {
    switch (th) {
      case "catNm":
        return (
          <CustomLangButtonWrapper lang={pagingSortOption?.sortLang}>
            <button type="button" onClick={() => langHandler("sortLang", "ko")}>
              가
            </button>
            <button type="button" onClick={() => langHandler("sortLang", "en")}>
              A
            </button>
          </CustomLangButtonWrapper>
        );
      case "regDt":
        return (
          <CustomFlexWrapper sort={pagingSortOption?.sortDate}>
            <button type="button" onClick={() => sortHandler("sortDate", "desc")}>
              <CaretUpOutlined />
            </button>
            <button type="button" onClick={() => sortHandler("sortDate", "asc")}>
              <CaretDownOutlined />
            </button>
          </CustomFlexWrapper>
        );
      default:
        break;
    }
  };

  useEffect(() => {}, [postData]);

  const validateText = useCallback(
    (text) => {
      if (postData.searchText === "" || postData.searchText === undefined) return text;
      if (catSearched) {
        const regText = new RegExp(postData.searchText, "gi");
        return text.replace(regText, "<span>$&</span>");
      }
      return text;
    },
    [catListData, catSearched],
  );

  const isSearchedTrue = (value) =>
    catSearched && value !== "" && value !== undefined && value !== null && value !== [];
  const isSearchedBreed = (value) => catSearched && value !== undefined && value.length > 0;

  const changeKor = (value) => {
    switch (value) {
      case "아깽이":
        return "영유아기";
      case "주니어":
        return "아동청소년기";
      case "성묘":
        return "청년기";
      case "중년":
        return "중장년기";
      case "노령":
        return "노년기";
      default:
        return value;
    }
  };

  return (
    <>
      {catListData && (
        <CustomTable>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              {columns.map((v, i) => (
                <th key={i}>
                  {v.title}
                  {theadButton(v.dataIndex)}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {catListData.slice(0, 20).map((v, i) => (
              <tr key={i} onClick={() => clickHandler(v.catUuid)} style={{ color: v.delYn && "#ccc" }}>
                <td>
                  <div key={v.catNm} title={v.catNm} dangerouslySetInnerHTML={{ __html: validateText(v.catNm) }} />
                </td>
                <td>
                  <CustomColorSearched searched={isSearchedTrue(postData.regDtStart)} title={regDateToString(v.regDt)}>
                    {regDateToString(v.regDt)}
                  </CustomColorSearched>
                </td>
                <td>
                  <CustomColorSearched
                    searched={isSearchedTrue(postData.catGenderCode)}
                    title={genderToString(v.catGenderCode)}
                  >
                    {genderToString(v.catGenderCode)}
                  </CustomColorSearched>
                </td>
                <td>
                  <CustomColorSearched
                    searched={isSearchedTrue(postData.neutralization)}
                    title={boolToString(v.neutralizationYn)}
                  >
                    {boolToString(v.neutralizationYn)}
                  </CustomColorSearched>
                </td>
                <td>
                  <CustomColorSearched searched={isSearchedBreed(postData.catBreedSeqNo)} title={v.catBreedKrNm}>
                    {v.catBreedKrNm}
                  </CustomColorSearched>
                </td>
                <td>
                  <CustomColorSearched
                    searched={isSearchedTrue(postData.birthdayStart)}
                    title={`${v.catBirthYear}.${smallThanTen(v.catBirthMonth)}.${smallThanTen(v.catBirthDay)}`}
                  >
                    {`${v.catBirthYear}.${smallThanTen(v.catBirthMonth)}.${smallThanTen(v.catBirthDay)}`}
                  </CustomColorSearched>
                </td>
                <td>
                  {ageLang === 1 ? (
                    <CustomColorSearched
                      searched={isSearchedTrue(postData.catAge)}
                      title={`${v.catAgeMonth}개월 (${v.catAgeStatusKr})`}
                    >
                      {`${v.catAgeMonth}개월 (${changeKor(v.catAgeStatusKr)})`}
                    </CustomColorSearched>
                  ) : (
                    <CustomColorSearched
                      searched={isSearchedTrue(postData.catAge)}
                      title={`${v.catAgeMonth}개월 (${v.catAgeStatusEn})`}
                    >
                      {`${v.catAgeMonth}개월 (${v.catAgeStatusEn})`}
                    </CustomColorSearched>
                  )}
                </td>
                <td>
                  <CustomColorSearched
                    searched={isSearchedTrue(postData.vaccination)}
                    title={boolToString(v.vaccinationYn)}
                  >
                    {boolToString(v.vaccinationYn)}
                  </CustomColorSearched>
                </td>
                <td>
                  <CustomColorSearched
                    searched={isSearchedTrue(postData.catWeightType)}
                    title={`${v.catWeight}kg (${weightToKor(v.catWeightType)})`}
                  >
                    {`${v.catWeight}kg (${weightToKor(v.catWeightType)})`}
                  </CustomColorSearched>
                </td>
              </tr>
            ))}
          </tbody>
        </CustomTable>
      )}
    </>
  );
}

CatTableHandler.propTypes = {
  page: PropTypes.number.isRequired,
  postData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sortOption: PropTypes.func.isRequired,
  clickHandler: PropTypes.func,
  ageLang: PropTypes.number,
  pagingSortOption: PropTypes.object.isRequired,
};

CatTableHandler.defaultProps = {
  clickHandler: () => {},
  ageLang: 1,
};

export default CatTableHandler;
