import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Form, Select, Input, Button, Radio } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { DatePickers, DetailSelector } from "../../components/Form";
import Paginations from "../../components/Form/Paginations";
import { AlarmTableHandler, AlarmSendPopUp, AlarmDetailPopUp } from "../../components/Alarm";
import {
  userFormInnerCss,
  filterWrapperCss,
  customInputCss,
  customSecondTitleButton,
  detailCustomToggleButtonCss,
  hasDetailFormCss,
  CutomTitleWrapperCss,
  CustomAlarmButtonCss,
} from "../../style/theme";
import { GET_ALARM_LIST_DATA_REQUEST, GET_ALARM_DETAIL_DATA_REQUEST } from "../../Module/type";
import useInputs from "../../hooks/useInputs";
import { selectedOption } from "./config";

const { Option } = Select;
const { Group } = Radio;

const CustomForm = styled(Form)`
  position: relative;
  ${hasDetailFormCss}
`;

const TitleWrapper = styled.div`
  ${CutomTitleWrapperCss}
`;

const AlramButton = styled(Button)`
  ${CustomAlarmButtonCss}
`;

const UserFormInner = styled.div`
  ${userFormInnerCss}
  margin-top:10px;
  :nth-child(2) {
    margin-top: 20px;
  }
`;

const CustomInput = styled(Input)`
  ${customInputCss}
`;

const FilterWrapper = styled.div`
  ${filterWrapperCss}
`;

const CumtomRouterButton = styled.div`
  ${customSecondTitleButton}
  margin-top:40px;
  label {
    padding: 0 30px;
  }
`;

const CustomButton = styled.button`
  ${detailCustomToggleButtonCss}
`;

function Alarm() {
  const dispatch = useDispatch();
  const { alarmListPageData } = useSelector((state) => state.alarm);
  const [filterOpen, setFilterOpen] = useState(false);
  const [detailSearchOpen, setDetailSearchOpen] = useState(false);
  const [alarmDetailOpen, setAlarmDetailOpen] = useState(false);
  const [compFlag, setCompFlag] = useState(0);
  const [page, nowPage] = useState(1);
  const [searchText, setSearchText] = useInputs("");
  const [searchData, setSearchData] = useState({});
  const [searchDate, setSearchDate] = useState({});
  const [searchBirth, setSearchBirth] = useState({});
  const [langCode, setLangCode] = useState(1);
  const [selected, setSelected] = useInputs("완료");
  const [postData, setPostData] = useState({});

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const onReset = () => {
    setLangCode(1);
    setSearchData({});
    setSearchDate({});
    setSearchBirth({});
    // setCompFlag(0);
    // setSelected({ target: { value: "완료" } });
    setSearchText({ target: { value: "" } });
    setPostData({});
  };

  const detailToggleFilter = () => {
    setDetailSearchOpen(!detailSearchOpen);
  };

  const selectOnChange = useCallback(
    (value, data) => {
      const { name } = data;
      setSearchData({ ...searchData, [name]: value });
    },
    [searchData],
  );

  const groupChangeHandler = (e) => {
    if (alarmDetailOpen) return;

    setSelected(e);

    if (e.target.value === "완료") {
      return setCompFlag(0);
    }

    return setCompFlag(1);
  };

  const tableItemClicked = (pushReserveSeqNo) => {
    try {
      dispatch({
        type: GET_ALARM_DETAIL_DATA_REQUEST,
        pushReserveSeqNo,
      });
    } catch (e) {
      console.log(e);
    }
    setAlarmDetailOpen(true);
  };

  const requestListData = (data) => {
    try {
      dispatch({
        type: GET_ALARM_LIST_DATA_REQUEST,
        data,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const alarmDetailToggle = useCallback(() => {
    setAlarmDetailOpen(!alarmDetailOpen);
  }, [alarmDetailOpen]);

  const onSubmit = useCallback(() => {
    const submitData = {
      ...postData,
      compFlag,
      langCode,
      paging: {
        page,
        size: 20,
      },
      searchType: "message",
    };
    setPostData(submitData);
    requestListData(submitData);
  }, [page, compFlag]);

  const onSearchSubmit = () => {
    const { regDtStart, regDtEnd } = searchDate;

    const submitData = {
      compFlag,
      ...searchDate,
      ...searchBirth,
      ...searchData,
      ...(regDtStart && { regDtStart }),
      ...(regDtEnd && { regDtEnd: +regDtEnd + 86400 }),
      searchText,
      langCode: langCode === null ? 1 : langCode,
      paging: {
        page,
        size: 20,
      },
      searchType: "message",
    };

    if (langCode === null) setLangCode(1);
    setPostData(submitData);
    requestListData(submitData);
  };

  return (
    <main role="main">
      <TitleWrapper>
        <h3>알람 관리</h3>
        <AlramButton type="button" onClick={toggleFilter}>
          알림발송
        </AlramButton>
      </TitleWrapper>
      <CustomForm onFinish={onSearchSubmit}>
        <UserFormInner>
          <Select defaultValue="내용" style={{ width: 120 }}>
            <Option value="searchText">내용</Option>
          </Select>
          <CustomInput placeholder="내용 검색" value={searchText} onChange={setSearchText} />
          <Button type="button" onClick={onReset}>
            초기화
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            style={{ color: "#fff", border: "1px solid #1890ff", marginLeft: 6 }}
          >
            검색
          </Button>
        </UserFormInner>
        <UserFormInner>
          <div>
            <label>등록일</label>
            <DatePickers searchDate={searchDate} setSearchDate={setSearchDate} name="regDt" />
          </div>
        </UserFormInner>
        <UserFormInner>
          <div>
            <label>발송일</label>
            <DatePickers isRadio={false} searchDate={searchBirth} setSearchDate={setSearchBirth} name="reserveDt" />
          </div>
        </UserFormInner>
        <div style={{ display: detailSearchOpen ? "block" : "none" }}>
          <UserFormInner>
            <DetailSelector
              setLangCode={setLangCode}
              selectOnChange={selectOnChange}
              isTransfer={false}
              searchData={searchData}
              alarmPage
              langCode={langCode}
            />
          </UserFormInner>
        </div>
        <CustomButton type="button" onClick={detailToggleFilter}>
          상세검색
          {detailSearchOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </CustomButton>
      </CustomForm>
      <CumtomRouterButton>
        <Group options={selectedOption} onChange={groupChangeHandler} value={selected} optionType="button" />
      </CumtomRouterButton>
      <AlarmTableHandler onSubmit={onSubmit} clickHandler={tableItemClicked} page={page} selected={selected} />
      {alarmListPageData && <Paginations total={alarmListPageData?.total} page={page} nowPage={nowPage} />}
      <FilterWrapper style={{ display: filterOpen ? "block" : "none" }} toggleFilter={toggleFilter}>
        <AlarmSendPopUp toggleFilter={toggleFilter} filterOpen={filterOpen} />
      </FilterWrapper>
      <AlarmDetailPopUp alarmDetailOpen={alarmDetailOpen} alarmDetailToggle={alarmDetailToggle} selected={selected} />
    </main>
  );
}

export default Alarm;
