import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRouter = ({ component: Component, ...rest }) => {
  const { isToken } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isToken) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        );
      }}
    />
  );
};

PrivateRouter.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRouter;
