/* eslint-disable no-buffer-constructor */
import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import qs from 'qs';
import { posts } from './index';
import {
  GET_CAT_LIST_DATA_REQUEST,
  GET_CAT_LIST_DATA_SUCCESS,
  GET_CAT_LIST_DATA_FAILURE,
  GET_CAT_BREED_DATA_REQUEST,
  GET_CAT_BREED_DATA_SUCCESS,
  GET_CAT_BREED_DATA_FAILURE,
  GET_CAT_DETAIL_DATA_REQUEST,
  GET_CAT_DETAIL_DATA_SUCCESS,
  GET_CAT_DETAIL_DATA_FAILURE,
  CAT_SEARCHED_SUCCESS,
} from '../type';

function getCatListAPI(data) {
  return posts.get('cat/list', {
    params: data,
    paramsSerializer: (params) =>
      qs.stringify(params, { allowDots: true, arrayFormat: 'comma', skipNulls: true }, ''),
  });
}

function getCatBreedDataAPI(data) {
  return posts.get(`cat/breed/${data}`);
}

function getCatDetailDataAPI(id) {
  return posts.get(`cat/info/${id}`);
}

function* getCatListData(action) {
  try {
    const result = yield call(getCatListAPI, action.data, action.searched);
    yield put({
      type: GET_CAT_LIST_DATA_SUCCESS,
      data: result.data,
    });
    if (action.searched) {
      yield put({
        type: CAT_SEARCHED_SUCCESS,
      });
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_CAT_LIST_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

function* getCatBreedData(action) {
  try {
    const result = yield call(getCatBreedDataAPI, action.data);
    yield put({
      type: GET_CAT_BREED_DATA_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_CAT_BREED_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

function* getCatDetailData(action) {
  try {
    const result = yield call(getCatDetailDataAPI, action.id);
    yield put({
      type: GET_CAT_DETAIL_DATA_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_CAT_DETAIL_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchGetCatListData() {
  yield takeLatest(GET_CAT_LIST_DATA_REQUEST, getCatListData);
}

function* watchGetCatBreedData() {
  yield takeLatest(GET_CAT_BREED_DATA_REQUEST, getCatBreedData);
}

function* watchGetCatDetailData() {
  yield takeLatest(GET_CAT_DETAIL_DATA_REQUEST, getCatDetailData);
}

export default function* catPostSaga() {
  yield all([fork(watchGetCatBreedData), fork(watchGetCatListData), fork(watchGetCatDetailData)]);
}
