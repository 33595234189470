import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { customInfoListCss, customInfoTitleCss } from "../../style/theme";
import { regDateToString } from "../../utils/tostring";

const CustomPopUpInner = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 0 20px;
`;

const CustomInfoList = styled.div`
  ${customInfoListCss}
  padding:20px 0 0;
  width: 100%;
`;

const CustomInfoTitle = styled.span`
  ${customInfoTitleCss}
`;

function BandDetailInfo() {
  const { bandDetailDatabandInfo } = useSelector((state) => state.band);

  return (
    <>
      {bandDetailDatabandInfo && (
        <CustomPopUpInner>
          <CustomInfoList>
            <p>
              <CustomInfoTitle>S/N</CustomInfoTitle>
              {bandDetailDatabandInfo.bandSn}
            </p>
            <p>
              <CustomInfoTitle>등록일</CustomInfoTitle>
              {regDateToString(bandDetailDatabandInfo.regDt)}
            </p>
          </CustomInfoList>
          <CustomInfoList>
            <p>
              <CustomInfoTitle>펌웨어</CustomInfoTitle>
              {bandDetailDatabandInfo.bandVersion}
            </p>
            <p>
              <CustomInfoTitle>마지막 동기화시간</CustomInfoTitle>
              {moment(bandDetailDatabandInfo.synchronizationDt * 1000).format("YYYY.MM.DD HH:mm")}
            </p>
          </CustomInfoList>
        </CustomPopUpInner>
      )}
    </>
  );
}

export default BandDetailInfo;
