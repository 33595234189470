export const columns = [
  {
    title: '회원명',
    dataIndex: 'userNm',
  },
  {
    title: '가입일',
    dataIndex: 'regDt',
  },
  {
    title: '이메일 계정',
    dataIndex: 'email',
  },
  {
    title: '고양이',
    dataIndex: 'catNm',
  },
  {
    title: '밴드 개수',
    dataIndex: 'band',
  },
];

export const selectData = [
  {
    id: 1,
    title: '회원명',
    value: 'userNm',
  },
  {
    id: 2,
    title: '고양이 이름',
    value: 'catNm',
  },
  {
    id: 3,
    title: '이메일',
    value: 'userEmail',
  },
];

export const detailCatDatacolumns = [
  {
    title: '사진',
    dataIndex: 'catImageFileSeqNo',
  },
  {
    title: '이름',
    dataIndex: 'date',
  },
  {
    title: '성별',
    dataIndex: 'email',
  },
  {
    title: '중성화',
    dataIndex: 'catNm',
  },
  {
    title: '품종',
    dataIndex: 'CatBreedNo',
  },
  {
    title: '생년월일',
    dataIndex: 'birthDt',
  },
  {
    title: '나이',
    dataIndex: 'catAge',
  },
  {
    title: '접종',
    dataIndex: 'vaccinate',
  },
  {
    title: '체중',
    dataIndex: 'catWeight',
  },
  {
    title: '등록일',
    dataIndex: 'regDt',
  },
];

export const detailBandDatacolumns = [
  {
    title: 'S/N',
    dataIndex: 'S/N',
  },
  {
    title: '등록된 고양이',
    dataIndex: 'catNm',
  },
  {
    title: '펌웨어',
    dataIndex: 'catWeight',
  },
  {
    title: '마지막 동기화 시간',
    dataIndex: 'regDt',
  },
  {
    title: '등록일',
    dataIndex: 'regDt',
  },
];
