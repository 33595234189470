import produce from "immer";

import {
  getSessionStorageToken,
  setSessionStorageToken,
  setSessionStorageLoginTime,
  removeAllSessionStorage,
} from "../../utils/login";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_ERROR_NULL,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAILURE,
  CAHNGE_LOGIN_INFO_REQUEST,
  CAHNGE_LOGIN_INFO_SUCCESS,
  CAHNGE_LOGIN_INFO_FAILURE,
  POPUP_CASE_CHANGE,
  POPUP_LOGOUT,
} from "../type";

export const initalState = {
  isToken: getSessionStorageToken(),
  isLogin: false,
  isLoginLoading: false,
  isLogInError: null,
  isLogOut: false,
  isLogOutLoading: false,
  isLogOutError: false,
  isChangeLogin: false,
  isChangeLoginLoading: false,
  isChangeLoginError: false,
  isLogInPopUp: null,
};

const authReducer = (state = initalState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN_REQUEST:
        draft.isLogInLoading = true;
        draft.isLogInError = null;
        break;
      case LOGIN_SUCCESS: {
        const loginDate = new Date().getTime();
        const { token } = action.payload.payload;
        setSessionStorageToken(token);
        setSessionStorageLoginTime(loginDate);
        draft.isLogInLoading = false;
        draft.isLogin = true;
        draft.isLogOut = false;
        draft.isToken = token;
        break;
      }
      case LOGIN_FAILURE:
        draft.isLogInLoading = false;
        draft.isLogInError = action.error;
        break;
      case LOGIN_ERROR_NULL:
        draft.isLogInError = action.null;
        break;
      case LOG_OUT_REQUEST:
        draft.isLogOutLoading = true;
        draft.isLogInError = null;
        break;
      case LOG_OUT_SUCCESS: {
        draft.isLogOutLoading = false;
        draft.isLogOut = true;
        draft.isLogin = false;
        removeAllSessionStorage();
        draft.isToken = null;
        break;
      }
      case LOG_OUT_FAILURE:
        draft.isLogInLoading = false;
        draft.isLogInError = action.error;
        break;
      case CAHNGE_LOGIN_INFO_REQUEST:
        draft.isChangeLoginLoading = true;
        draft.isChangeLoginError = null;
        break;
      case CAHNGE_LOGIN_INFO_SUCCESS: {
        const isSuccess = action.payload.success;
        console.log(isSuccess);
        draft.isChangeLoginLoading = false;
        draft.isChangeLogin = true;
        draft.isLogInPopUp = isSuccess;
        break;
      }
      case CAHNGE_LOGIN_INFO_FAILURE:
        draft.isChangeLoginLoading = false;
        draft.isChangeLoginError = action.error;
        break;
      case POPUP_CASE_CHANGE:
        draft.isLogInPopUp = action.payload;
        break;
      case POPUP_LOGOUT:
        draft.isLogin = false;
        draft.isToken = null;
        break;
      default:
        return state;
    }
  });
export default authReducer;
