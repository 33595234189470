import React, { useState, useCallback } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import Paginations from "../../components/Form/Paginations";
import { GET_USER_LIST_DATA_REQUEST } from "../../Module/type";
import { UserTableHandler, UserDetailPopUp } from "../../components/User";
import { CommonInputs, DatePickers } from "../../components/Form";

import useInputs from "../../hooks/useInputs";
import { selectData } from "./config";
import { userFormInnerCss, customSecondTitleButton } from "../../style/theme";

const UserFormInner = styled.div`
  ${userFormInnerCss}
  margin-top: 20px;
`;

const CumtomRouterButton = styled.div`
  ${customSecondTitleButton}
  margin-top:40px;
`;

function User() {
  const dispatch = useDispatch();
  const { userListPageData, userSearched } = useSelector((state) => state.user);
  const [userDetailOpen, setUserDetailOpen] = useState(false);
  const [page, nowPage] = useState(1);
  const [searchType, setSearchType] = useState("userNm");
  const [searchText, setSearchText] = useInputs("");
  const [searchDate, setSearchDate] = useState({});
  const [pagingSortOption, setPagingSortOption] = useState({
    // sortDate: "",
    sortLang: "ko",
  });
  const [postData, setPostData] = useState({});
  const [userId, setUserId] = useState(null);

  const tableItemClicked = (id) => {
    setUserId(id);
    setUserDetailOpen(true);
  };

  const onReset = () => {
    setPagingSortOption({ sortLang: "ko" });
    setSearchText({ target: { value: "" } });
    setSearchDate({});
    setSearchType("userNm");
  };

  const userDetailToggle = useCallback(() => {
    setUserDetailOpen(!userDetailOpen);
  }, [userDetailOpen]);

  const requestListData = (data, searched) => {
    try {
      dispatch({
        type: GET_USER_LIST_DATA_REQUEST,
        data,
        searched,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = useCallback(() => {
    const submitData = {
      ...postData,
      paging: {
        page,
        size: 20,
      },
      ...pagingSortOption,
    };

    if (Object.keys(postData).length === 0) {
      const searched = "searched";
      setPostData(submitData);
      requestListData(submitData, searched);
    } else {
      setPostData(submitData);
      requestListData(submitData);
    }
  }, [page, pagingSortOption]);

  const onSearchSubmit = () => {
    const { regDtStart, regDtEnd } = searchDate;

    const submitData = {
      ...(regDtStart && { regDtStart }),
      ...(regDtEnd && { regDtEnd: +regDtEnd + 86400 }),
      searchType,
      searchText,
      paging: {
        page: 1,
        size: 20,
      },
      ...pagingSortOption,
    };

    const searched = "searched";
    setPostData(submitData);
    requestListData(submitData, searched);
  };

  const sortOptionHandler = (name, value) => {
    setPagingSortOption({
      [name]: value,
    });

    const submitData = {
      ...searchDate,
      searchType,
      searchText,
      paging: {
        page: 1,
        size: 20,
      },
      [name]: value,
    };

    setPostData(submitData);
    requestListData(submitData);
  };

  return (
    <main role="main">
      <h3>회원관리</h3>
      {userListPageData && (
        <>
          <Form onFinish={onSearchSubmit}>
            <CommonInputs
              selectData={selectData}
              searchText={searchText}
              setSearchType={setSearchType}
              setSearchText={setSearchText}
              onReset={onReset}
              searchType={searchType}
            />
            <UserFormInner>
              <h5>가입일</h5>
              <DatePickers searchDate={searchDate} setSearchDate={setSearchDate} name="regDt" />
            </UserFormInner>
          </Form>
          <CumtomRouterButton>
            <h5>
              {userSearched.total ? "검색 결과:" : "Total:"}
              {userListPageData.total}
            </h5>
          </CumtomRouterButton>
          <UserTableHandler
            onSubmit={onSubmit}
            sortOption={sortOptionHandler}
            clickHandler={tableItemClicked}
            page={page}
            postData={postData}
            pagingSortOption={pagingSortOption}
          />
          {userListPageData && (
            <Paginations total={userListPageData?.total} onSubmit={onSearchSubmit} page={page} nowPage={nowPage} />
          )}
          <UserDetailPopUp userDetailOpen={userDetailOpen} userDetailToggle={userDetailToggle} userId={userId} />
        </>
      )}
    </main>
  );
}

export default User;
