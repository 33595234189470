import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import useSWR from "swr";
import { CloseOutlined } from "@ant-design/icons";
import { customPopUpWrapperCss, customPopUpCloseCss } from "../../style/theme";
import UserDetailInfo from "./UserDetailInfo";
import UserDetailTable from "./UserDetailTable";
import fetcher from "../../utils/fetcher";

const CustomPopUpWrapper = styled.div`
  ${customPopUpWrapperCss}
  max-height: 800px;
  width: 1000px;
  word-break: break-word;
  padding: 0 0 20px;
  h5 {
    font-size: 18px;
    font-weight: bold;
    margin: 40px 0 0 10px;
  }
  td {
    padding: 15px;
    height: 61px;
  }
`;

const CustomPopUpInner = styled.div`
  width: 100%;
  margin-top: 0;
`;

const CustomPopUpCloseButton = styled.button`
  ${customPopUpCloseCss}
`;

const StickyHeader = styled.div`
  padding: 20px 20px 10px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #fff;
`;

function UserDeetailPopUp({ userDetailOpen, userDetailToggle, userId }) {
  const token = window.sessionStorage.getItem("access_token");
  const { data } = useSWR([`${process.env.REACT_APP_API}user/info/${userId}`, token], fetcher);

  return (
    <CustomPopUpWrapper
      userDetailOpen={userDetailOpen}
      style={{
        display: userDetailOpen ? "block" : "none",
        overflowY: userDetailOpen && "scroll",
      }}
    >
      {data && (
        <>
          <StickyHeader>
            <h4>
              회원 정보
              <CustomPopUpCloseButton type="button" onClick={userDetailToggle}>
                <CloseOutlined />
              </CustomPopUpCloseButton>
            </h4>
          </StickyHeader>
          <div style={{ padding: "0 20px" }}>
            <CustomPopUpInner>
              {data.userInfo && <UserDetailInfo userInfo={data.userInfo} snsList={data.snsList} />}
            </CustomPopUpInner>
            <UserDetailTable data={data.catList} userInfo={data.userInfo} />
          </div>
        </>
      )}
    </CustomPopUpWrapper>
  );
}

UserDeetailPopUp.propTypes = {
  userDetailOpen: PropTypes.bool.isRequired,
  userDetailToggle: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

UserDeetailPopUp.defaultProps = {
  userId: null,
};

export default UserDeetailPopUp;
