export const selectGenderData = [
  {
    id: 0,
    name: "catGenderCode",
    title: "전체",
    value: null,
  },
  {
    id: 1,
    name: "catGenderCode",
    title: "남자",
    value: "M",
  },
  {
    id: 2,
    name: "catGenderCode",
    title: "여자",
    value: "F",
  },
];

export const selectWeightData = [
  {
    id: 0,
    name: "catWeightType",
    title: "전체",
    value: null,
  },
  {
    id: 1,
    name: "catWeightType",
    title: "마른",
    value: 1,
  },
  {
    id: 2,
    name: "catWeightType",
    title: "정상",
    value: 2,
  },
  {
    id: 3,
    name: "catWeightType",
    title: "비만경향",
    value: 3,
  },
  {
    id: 4,
    name: "catWeightType",
    title: "뚱냥",
    value: 4,
  },
];

export const selectEngAgeData = [
  {
    id: 0,
    name: "catAge",
    title: "All",
    value: null,
  },
  {
    id: 1,
    name: "catAge",
    title: "kitten",
    value: "kitten",
  },
  {
    id: 2,
    name: "catAge",
    title: "junior",
    value: "junior",
  },
  {
    id: 3,
    name: "catAge",
    title: "adult",
    value: "adult",
  },
  {
    id: 4,
    name: "catAge",
    title: "mature",
    value: "mature",
  },
  {
    id: 5,
    name: "catAge",
    title: "senior",
    value: "senior",
  },
  {
    id: 6,
    name: "catAge",
    title: "super senior",
    value: "supersenio",
  },
];

export const selectKorAgeData = [
  {
    id: 0,
    name: "catAge",
    title: "전체",
    value: null,
  },
  {
    id: 1,
    name: "catAge",
    title: "영유아기",
    value: "kitten",
  },
  {
    id: 2,
    name: "catAge",
    title: "아동청소년기",
    value: "junior",
  },
  {
    id: 3,
    name: "catAge",
    title: "청년기",
    value: "adult",
  },
  {
    id: 4,
    name: "catAge",
    title: "중장년기",
    value: "mature",
  },
  {
    id: 5,
    name: "catAge",
    title: "노년기",
    value: "senior",
  },
];

export const languageOption = ["한글", "English"];

export const selectNeutralizationData = [
  {
    id: 0,
    name: "neutralization",
    title: "전체",
    value: null,
  },
  {
    id: 1,
    name: "neutralization",
    title: "O",
    value: 1,
  },
  {
    id: 2,
    name: "neutralization",
    title: "X",
    value: 0,
  },
];

export const selectVaccinationData = [
  {
    id: 0,
    name: "vaccination",
    title: "전체",
    value: null,
  },
  {
    id: 1,
    name: "vaccination",
    title: "O",
    value: 1,
  },
  {
    id: 2,
    name: "vaccination",
    title: "X",
    value: 0,
  },
];

export const selectKindsData = [
  {
    id: 0,
    name: "hairType",
    title: "전체",
    value: "",
  },
  {
    id: 2,
    name: "hairType",
    title: "단묘",
    value: 1,
  },
  {
    id: 1,
    name: "hairType",
    title: "장묘",
    value: 2,
  },
];

export const timeSelect = [
  {
    id: 0,
    name: "time",
    title: "오전",
    value: "date",
  },
  {
    id: 1,
    name: "time",
    title: "오후",
    value: "night",
  },
];

export const columns = [
  {
    title: "고양이 이름",
    dataIndex: "catNm",
  },
  {
    title: "등록일",
    dataIndex: "regDt",
  },
  {
    title: "성별",
    dataIndex: "catGenderCode",
  },
  {
    title: "중성화",
    dataIndex: "neutralization",
  },
  {
    title: "품종",
    dataIndex: "catBreedSeqNo",
  },
  {
    title: "생년월일",
    dataIndex: "birth",
  },
  {
    title: "나이",
    dataIndex: "catAge",
  },
  {
    title: "백신",
    dataIndex: "vaccination",
  },
  {
    title: "체중",
    dataIndex: "catWeightType",
  },
];

export const selectDetailEngAgeData = [
  {
    id: 0,
    name: "catAgeStatus",
    title: "All",
    value: null,
  },
  {
    id: 1,
    name: "catAgeStatus",
    title: "kitten",
    value: "kitten",
  },
  {
    id: 2,
    name: "catAgeStatus",
    title: "junior",
    value: "junior",
  },
  {
    id: 3,
    name: "catAgeStatus",
    title: "adult",
    value: "adult",
  },
  {
    id: 4,
    name: "catAgeStatus",
    title: "mature",
    value: "mature",
  },
  {
    id: 5,
    name: "catAgeStatus",
    title: "senior",
    value: "senior",
  },
  {
    id: 6,
    name: "catAgeStatus",
    title: "super senior",
    value: "supersenio",
  },
];

export const selectDetailKorAgeData = [
  {
    id: 0,
    name: "catAgeStatus",
    title: "전체",
    value: null,
  },
  {
    id: 1,
    name: "catAgeStatus",
    title: "영유아기",
    value: "kitten",
  },
  {
    id: 2,
    name: "catAgeStatus",
    title: "아동청소년기",
    value: "junior",
  },
  {
    id: 3,
    name: "catAgeStatus",
    title: "청년기",
    value: "adult",
  },
  {
    id: 4,
    name: "catAgeStatus",
    title: "중장년기",
    value: "mature",
  },
  {
    id: 5,
    name: "catAgeStatus",
    title: "노년기",
    value: "senior",
  },
];
