import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Form, Button } from "antd";
import Paginations from "../../components/Form/Paginations";
import { CommonInputs, DatePickers } from "../../components/Form";
import {
  GET_BAND_LIST_DATA_REQUEST,
  GET_BAND_DETAIL_DATA_REQUEST,
  GET_BAND_FW_REQUEST,
  GET_BAND_INFO_FW_REQUEST,
} from "../../Module/type";
import { BandTableHandler, BandDetailPopUp, BandUploadPopUp } from "../../components/Band";
import useInputs from "../../hooks/useInputs";
import { selectData } from "./config";
import {
  userFormInnerCss,
  customSecondTitleButton,
  CutomTitleWrapperCss,
  CustomAlarmButtonCss,
} from "../../style/theme";

const UserFormInner = styled.div`
  ${userFormInnerCss}
  margin-top: 20px;
`;

const CumtomRouterButton = styled.div`
  ${customSecondTitleButton}
  margin-top:40px;
`;

const TitleWrapper = styled.div`
  ${CutomTitleWrapperCss}
  margin-bottom: 30px;
  h3 {
    margin-bottom: 0;
  }
`;

const AlramButton = styled(Button)`
  ${CustomAlarmButtonCss}
  width: 110px;
  height: 34px;
`;

function Band() {
  const dispatch = useDispatch();
  const { bandListPageData, bandSearched, bandFwData } = useSelector((state) => state.band);
  const [searchType, setSearchType] = useState(1);
  const [page, nowPage] = useState(1);
  const [bandDetailOpen, setBandDetailOpen] = useState(false);
  const [uploadeOpen, setUploadOpen] = useState(false);
  const [searchText, setSearchText] = useInputs("");
  const [searchDate, setSearchDate] = useState({});
  const [pagingSortOption, setPagingSortOption] = useState({
    sortDate: "",
  });
  const [postData, setPostData] = useState({});

  const onReset = () => {
    setPagingSortOption({ sortDate: "ko" });
    setSearchText({ target: { value: "" } });
    setSearchDate({});
    setSearchType(1);
  };

  const bandDetailToggle = useCallback(() => {
    setBandDetailOpen(!bandDetailOpen);
  }, [bandDetailOpen]);

  const uploadeOpenToggle = useCallback(() => {
    setUploadOpen(!uploadeOpen);
  }, [uploadeOpen]);

  const tableItemClicked = (userUuid) => {
    try {
      dispatch({
        type: GET_BAND_DETAIL_DATA_REQUEST,
        userUuid,
      });
    } catch (e) {
      return console.log(e);
    }
    setBandDetailOpen(true);
  };

  const requestListData = (data, searched) => {
    try {
      dispatch({
        type: GET_BAND_LIST_DATA_REQUEST,
        data,
        searched,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const requestFirmwareData = () => {
    try {
      dispatch({
        type: GET_BAND_FW_REQUEST,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const requestFirmwareInfoData = () => {
    try {
      dispatch({
        type: GET_BAND_INFO_FW_REQUEST,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = useCallback(() => {
    const submitData = {
      ...postData,
      searchType,
      searchText,
      paging: {
        page,
        size: 20,
        ...pagingSortOption,
      },
    };

    if (Object.keys(postData).length === 0) {
      const searched = "searched";
      setPostData(submitData);
      requestListData(submitData, searched);
    } else {
      setPostData(submitData);
      requestListData(submitData);
    }
  }, [page]);

  const onSearchSubmit = useCallback(() => {
    const { searchStartDt, searchEndDt } = searchDate;

    const submitData = {
      ...(searchStartDt && { searchStartDt }),
      ...(searchEndDt && { searchEndDt: +searchEndDt + 86400 }),
      searchType,
      searchText,
      paging: {
        page: 1,
        size: 20,
        ...pagingSortOption,
      },
    };
    const searched = "searched";
    setPostData(submitData);
    requestListData(submitData, searched);
  }, [searchType, searchDate, searchText, searchText, page]);

  const sortOptionHandler = (name, value) => {
    setPagingSortOption({
      ...pagingSortOption,
      [name]: value,
    });

    const submitData = {
      ...postData,
      paging: {
        page,
        size: 20,
      },
      [name]: value,
    };
    setPostData(submitData);
    requestListData(submitData);
  };

  useEffect(() => {
    requestFirmwareData();
    requestFirmwareInfoData();
  }, []);

  return (
    <main role="main">
      <TitleWrapper>
        <h3>밴드 관리</h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ marginRight: 10 }}>{`현재버전: ${bandFwData}`}</p>
          <AlramButton type="button" onClick={() => uploadeOpenToggle()}>
            F/W 업데이트
          </AlramButton>
        </div>
      </TitleWrapper>
      <Form onFinish={onSearchSubmit}>
        <CommonInputs
          selectData={selectData}
          searchText={searchText}
          setSearchType={setSearchType}
          setSearchText={setSearchText}
          searchType={searchType}
          onReset={onReset}
        />
        <UserFormInner>
          <label>가입일</label>
          <DatePickers searchDate={searchDate} setSearchDate={setSearchDate} name="search" />
        </UserFormInner>
      </Form>
      <CumtomRouterButton>
        <h5>
          {bandSearched ? "검색 결과:" : "Total:"}
          {bandListPageData.total}
        </h5>
      </CumtomRouterButton>
      <BandTableHandler
        onSubmit={onSubmit}
        sortOption={sortOptionHandler}
        clickHandler={tableItemClicked}
        page={page}
        postData={postData}
      />
      {bandListPageData && (
        <Paginations total={bandListPageData?.total} onSubmit={onSearchSubmit} page={page} nowPage={nowPage} />
      )}

      <BandDetailPopUp bandDetailOpen={bandDetailOpen} bandDetailToggle={bandDetailToggle} />
      <BandUploadPopUp bandUploadOpen={uploadeOpen} bandUploadToggle={uploadeOpenToggle} />
    </main>
  );
}

export default Band;
