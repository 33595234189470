import produce from "immer";
// import faker from 'faker';
// import shortId from 'shortid';
import {
  GET_ALARM_LIST_DATA_REQUEST,
  GET_ALARM_LIST_DATA_SUCCESS,
  GET_ALARM_LIST_DATA_FAILURE,
  GET_ALARM_DETAIL_DATA_REQUEST,
  GET_ALARM_DETAIL_DATA_SUCCESS,
  GET_ALARM_DETAIL_DATA_FAILURE,
  POST_ALARM_DATA_REQUEST,
  POST_ALARM_DATA_SUCCESS,
  POST_ALARM_DATA_FAILURE,
  POST_ALARM_DATA_MOD_REQUEST,
  POST_ALARM_DATA_MOD_SUCCESS,
  POST_ALARM_DATA_MOD_FAILURE,
  REMOVE_ALARM_DATA_REQUEST,
  REMOVE_ALARM_DATA_SUCCESS,
  REMOVE_ALARM_DATA_FAILURE,
} from "../type";

export const initalState = {
  alarmListData: [],
  alarmDetailInfo: null,
  alarmListPageData: [],
  alarmListDataLoading: false,
  alarmListDataDone: false,
  alarmListDataError: false,
  alarmDetailDataLoading: false,
  alarmDetailDataDone: false,
  alarmDetailDataError: false,
  alarmPostDataLoading: false,
  alarmPostDataDone: false,
  alarmPostDataError: false,
  alarmModPostLoading: false,
  alarmModPostDone: false,
  alarmModPostError: false,
  alarmRemoveLoading: false,
  alarmRemoveDone: false,
  alarmRemoveError: false,
};

const alarmPostReducer = (state = initalState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_ALARM_LIST_DATA_REQUEST:
        draft.alarmListDataLoading = true;
        draft.alarmListDataError = null;
        draft.alarmListDataDone = false;
        break;
      case GET_ALARM_LIST_DATA_SUCCESS: {
        draft.alarmListDataLoading = false;
        draft.alarmListDataDone = true;
        draft.alarmListData = action.data.payload.notificationList;
        draft.alarmListPageData = action.data.payload.paging;
        break;
      }
      case GET_ALARM_LIST_DATA_FAILURE:
        draft.alarmListDataLoading = false;
        draft.alarmListDataError = action.error;
        break;
      case GET_ALARM_DETAIL_DATA_REQUEST:
        draft.alarmDetailDataLoading = true;
        draft.alarmDetailDataError = null;
        draft.alarmDetailInfo = null;
        break;
      case GET_ALARM_DETAIL_DATA_SUCCESS: {
        draft.alarmDetailInfo = action.data.payload;
        draft.alarmDetailDataLoading = false;
        draft.alarmDetailDataDone = true;
        break;
      }
      case GET_ALARM_DETAIL_DATA_FAILURE:
        draft.alarmDetailDataLoading = false;
        draft.alarmDetailDataError = action.error;
        break;
      case POST_ALARM_DATA_REQUEST:
        draft.alarmPostDataLoading = true;
        draft.alarmPostDataDone = false;
        draft.alarmPostDataError = action.error;
        break;
      case POST_ALARM_DATA_SUCCESS:
        draft.alarmPostDataLoading = false;
        draft.alarmPostDataDone = true;
        break;
      case POST_ALARM_DATA_FAILURE:
        draft.alarmPostDatLoading = false;
        draft.alarmPostDataError = action.error;
        break;
      case POST_ALARM_DATA_MOD_REQUEST:
        draft.alarmModPostLoading = true;
        draft.alarmModPostError = false;
        draft.alarmModPostDone = false;
        break;
      case POST_ALARM_DATA_MOD_SUCCESS:
        draft.alarmModPostLoading = false;
        draft.alarmModPostDone = true;
        break;
      case POST_ALARM_DATA_MOD_FAILURE:
        draft.alarmModPostLoading = false;
        draft.alarmModPostError = action.error;
        break;
      case REMOVE_ALARM_DATA_REQUEST:
        draft.alarmRemoveLoading = true;
        draft.alarmRemoveError = false;
        draft.alarmRemoveDone = false;
        break;
      case REMOVE_ALARM_DATA_SUCCESS:
        draft.alarmRemoveLoading = false;
        draft.alarmRemoveDone = true;
        break;
      case REMOVE_ALARM_DATA_FAILURE:
        draft.alarmRemoveLoading = false;
        draft.alarmRemoveError = action.error;
        break;
      default:
        return state;
    }
  });

export default alarmPostReducer;
