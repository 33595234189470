import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const ACCESS_TOKEN = 'access_token';
const LOGIN_TIME = 'login_time';
const MAIN_POST_TIME = 'post_time';

export const setSesstionStorage = (key, value) => window.sessionStorage.setItem(key, value);
export const getSesstionStorage = (key) => window.sessionStorage.getItem(key);
export const removeSesstionStorage = (key) => window.sessionStorage.removeItem(key);

export const setSessionStorageToken = (token) => {
  setSesstionStorage(ACCESS_TOKEN, token);
};

export const setSessionStorageLoginTime = (time) => {
  setSesstionStorage(LOGIN_TIME, time);
};

export const setSessionStoragepostTime = (time) => {
  setSesstionStorage(MAIN_POST_TIME, time);
};

export const getSessionStorageToken = () => getSesstionStorage(ACCESS_TOKEN);
export const getSessionStorageLoginTime = () => getSesstionStorage(LOGIN_TIME);
export const getSessionStoragePostTime = () => getSesstionStorage(MAIN_POST_TIME);

export const removeAllSessionStorage = () => {
  removeSesstionStorage(ACCESS_TOKEN);
  removeSesstionStorage(LOGIN_TIME);
  removeSesstionStorage(MAIN_POST_TIME);
};

export const logOutRedirectLogin = () => {
  removeAllSessionStorage('');
  history.replace('/');
};

// export const isTokend =
// export default isAccessToken;
