import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

const usePortals = () => {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  });

  const RenderModals = ({ children }) =>
    rendered && ReactDOM.createPortal(<>{children}</>, document.getElementById("modal-root"));

  return {
    RenderModals,
  };
};

export default usePortals;
