import { all, fork, put, call, takeLatest } from "redux-saga/effects";
import qs from "qs";
import { posts } from "./index";
import {
  GET_ALARM_LIST_DATA_REQUEST,
  GET_ALARM_LIST_DATA_SUCCESS,
  GET_ALARM_LIST_DATA_FAILURE,
  GET_ALARM_DETAIL_DATA_REQUEST,
  GET_ALARM_DETAIL_DATA_SUCCESS,
  GET_ALARM_DETAIL_DATA_FAILURE,
  POST_ALARM_DATA_REQUEST,
  POST_ALARM_DATA_SUCCESS,
  POST_ALARM_DATA_FAILURE,
  POST_ALARM_DATA_MOD_REQUEST,
  POST_ALARM_DATA_MOD_SUCCESS,
  POST_ALARM_DATA_MOD_FAILURE,
  REMOVE_ALARM_DATA_REQUEST,
  REMOVE_ALARM_DATA_SUCCESS,
  REMOVE_ALARM_DATA_FAILURE,
} from "../type";

function getAlarmListAPI(data) {
  return posts.get("notification/list", {
    params: data,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, arrayFormat: "comma", skipNulls: true }, ""),
  });
}

function getAlarmDetailDataAPI(pushReserveSeqNo) {
  return posts.get(`notification/info/${pushReserveSeqNo}`);
}

function postAddAlarmAPI(data) {
  return posts.post("notification/add", data);
}

function postAlarmModAPI(data) {
  return posts.post("notification/mod", data);
}

function removeAlarmAPI(pushReserveSeqNo) {
  return posts.get(`notification/remove/${pushReserveSeqNo}`);
}

function* getAlarmListData(action) {
  try {
    const result = yield call(getAlarmListAPI, action.data);
    yield put({
      type: GET_ALARM_LIST_DATA_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_ALARM_LIST_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

function* getAlarmDetailData(action) {
  try {
    const result = yield call(getAlarmDetailDataAPI, action.pushReserveSeqNo);

    yield put({
      type: GET_ALARM_DETAIL_DATA_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_ALARM_DETAIL_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

// function* getAlarmDetailData() {
//   try {
//     yield put({
//       type: GET_ALARM_DETAIL_DATA_SUCCESS,
//     });
//   } catch (e) {
//     console.log(e);
//     yield put({
//       type: GET_ALARM_DETAIL_DATA_FAILURE,
//       error: e.response.data,
//     });
//   }
// }

function* postAddAlarmData(action) {
  try {
    const result = yield call(postAddAlarmAPI, action.data);

    yield put({
      type: POST_ALARM_DATA_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: POST_ALARM_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

function* postAlarmModData(action) {
  try {
    const result = yield call(postAlarmModAPI, action.data);

    yield put({
      type: POST_ALARM_DATA_MOD_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: POST_ALARM_DATA_MOD_FAILURE,
      error: e.response.data,
    });
  }
}

function* getAlarmRemoveData(action) {
  try {
    yield call(removeAlarmAPI, action.number);

    yield put({
      type: REMOVE_ALARM_DATA_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: REMOVE_ALARM_DATA_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchGetAlarmListData() {
  yield takeLatest(GET_ALARM_LIST_DATA_REQUEST, getAlarmListData);
}

function* watchGetAlarmDetailData() {
  yield takeLatest(GET_ALARM_DETAIL_DATA_REQUEST, getAlarmDetailData);
}

function* watchPostAddAlarmData() {
  yield takeLatest(POST_ALARM_DATA_REQUEST, postAddAlarmData);
}

function* watchPostAlarmModData() {
  yield takeLatest(POST_ALARM_DATA_MOD_REQUEST, postAlarmModData);
}

function* watchgetRemoveAlarmData() {
  yield takeLatest(REMOVE_ALARM_DATA_REQUEST, getAlarmRemoveData);
}

export default function* alarmPostSaga() {
  yield all([
    fork(watchGetAlarmListData),
    fork(watchGetAlarmDetailData),
    fork(watchPostAddAlarmData),
    fork(watchPostAlarmModData),
    fork(watchgetRemoveAlarmData),
  ]);
}
