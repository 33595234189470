import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import store, { history } from "./store";
import "antd/dist/antd.css";
import GlobalStyles from "./style/GlobalStyle";
import "./App.css";
import Router from "./router/Router";

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GlobalStyles />
        <Router />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
