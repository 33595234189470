/* eslint-disable react/react-in-jsx-scope */

export const Numberpattern = /^([1-9]|[01][0-9]|2[0-3]):([0-5][0-9])$/;
export const StringPattern = /[a-zA-Z]/;
export const KoreanStringPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
export const SpecialPattern = /[~!@#$%^&*()_+|<>?:{}]/;

export const isHourLargerThanTwelve = (name, value) =>
  (name === "hour" && value > 12) || ("hour" && Numberpattern.test(value));

export const isMinLargerThanSixty = (name, value) => name === "min" && value > 60;

export const isNoString = (value) =>
  SpecialPattern.test(value) || StringPattern.test(value) || KoreanStringPattern.test(value);

export const arrayIsNullorEmpty = (array) => {
  console.log(array === null);
};
