import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import styled from "styled-components";
import moment from "moment";
import { Button } from "antd";
import { SyncOutlined, RightOutlined } from "@ant-design/icons";

import DashBoardCard from "../../components/DashBoardCard";
import MainTableHandler from "../../components/Main/MainTableHandler";
import { customSecondTitleButton } from "../../style/theme";
import { setSessionStoragepostTime } from "../../utils/login";
import fetcher from "../../utils/fetcher";

const DashboardWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
  }
  h2 {
    color: #000;
  }
`;
const DashBoardCardWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  width: 100%:
`;

const CustomRefreshButton = styled(Button)`
  padding: 0;
  width: 28px;
  height: 28px;
  border-radius: 6px;
  margin: 0 5px;
  svg {
    width: 11px;
    height: 11px;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    border-color: #d9d9d9 !important;
  }
`;

const CustomButtonWrapper = styled.div`
  margin-top: 70px;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
  cursor: pointer;
`;

const CumtomRouterButton = styled.div`
  ${customSecondTitleButton}
  margin-top: 0;
`;

function Main() {
  const { isToken } = useSelector((state) => state.auth);

  const history = useHistory();
  const cat = "cat";
  const user = "user";
  const band = "band";

  // eslint-disable-next-line no-unused-vars

  const { data: mainNotice, mutate } = useSWR([`${process.env.REACT_APP_API}home/dashboard/${540}`, isToken], fetcher);

  const returnUpdateTime = () => moment(new Date()).format("YY.MM.DD HH:mm");

  return (
    <>
      {mainNotice && (
        <main role="main">
          <DashboardWrapper>
            <h2>Dashboard</h2>
            <CustomRefreshButton
              type="button"
              onClick={() => {
                mutate(`home/dashboard/${540}`);
                setSessionStoragepostTime(new Date());
              }}
            >
              <SyncOutlined />
            </CustomRefreshButton>
            <span>
              업데이트 시간:
              {returnUpdateTime()}
            </span>
          </DashboardWrapper>
          <DashBoardCardWrapper>
            <DashBoardCard
              title={user}
              total={mainNotice.statics?.userTotal}
              newData={mainNotice.statics?.userNew}
              deleteData={mainNotice.statics?.userWithdrawal}
            />
            <DashBoardCard
              title={cat}
              total={mainNotice.statics?.catTotal}
              newData={mainNotice.statics?.catNew}
              deleteData={mainNotice.statics?.catDeleted}
            />
            <DashBoardCard title={band} total={mainNotice.bandTotal} newData={mainNotice.bandNew} />
          </DashBoardCardWrapper>
          <CustomButtonWrapper onClick={() => history.push("/alarm")}>
            <CumtomRouterButton>최신알림</CumtomRouterButton>
            <RightOutlined />
          </CustomButtonWrapper>
          <MainTableHandler data={mainNotice.notificationList} />
        </main>
      )}
    </>
  );
}

export default Main;
