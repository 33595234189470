import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Radio, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";

import useInputs from "../../hooks/useInputs";
import { userFormInnerCss, labelCss } from "../../style/theme";
import SelectHandler from "./SelectHandler";
import {
  selectGenderData,
  selectWeightData,
  selectKorAgeData,
  selectEngAgeData,
  languageOption,
} from "../../pages/Cat/config";
import { selectKindsDataValueNull } from "../../pages/Alarm/config";

const { Group } = Radio;

const UserFormInner = styled.div`
  ${userFormInnerCss}
  margin-top: 0;
`;

const CustomLabel = styled.label`
  ${labelCss}
`;

const CustomFilterButton = styled(Button)`
  padding: 0 8px;
`;

function DetailSelector({ setLangCode, selectOnChange, isTransfer, toggleFilter, alarmPage, searchData, langCode }) {
  const [language, onChangeLanguage] = useInputs("한글");

  const languageHandler = (e) => {
    e.preventDefault();
    const { value } = e.target;
    onChangeLanguage(e);
    if (value === "한글") return setLangCode(1);
    return setLangCode(2);
  };

  useEffect(() => {
    if (langCode === 1) {
      onChangeLanguage({ target: { value: "한글" } });
    } else {
      onChangeLanguage({ target: { value: "English" } });
    }
  }, [langCode]);

  return (
    <UserFormInner>
      <div>
        <label>언어</label>
        <Group options={languageOption} onChange={languageHandler} value={language} optionType="button" />
      </div>
      <div>
        <CustomLabel>품종</CustomLabel>
        {isTransfer ? (
          <CustomFilterButton type="button" onClick={toggleFilter}>
            <FilterOutlined />
          </CustomFilterButton>
        ) : (
          <SelectHandler data={selectKindsDataValueNull} onChange={selectOnChange} isValue={searchData?.hairType} />
        )}
      </div>
      {!alarmPage && (
        <div>
          <CustomLabel>성별</CustomLabel>
          <SelectHandler data={selectGenderData} onChange={selectOnChange} isValue={searchData?.catGenderCode} />
        </div>
      )}
      <div>
        <CustomLabel>체중</CustomLabel>
        <SelectHandler data={selectWeightData} onChange={selectOnChange} isValue={searchData?.catWeightType} />
      </div>
      <div>
        <CustomLabel>나이</CustomLabel>
        <SelectHandler
          data={language === "English" ? selectEngAgeData : selectKorAgeData}
          onChange={selectOnChange}
          isValue={searchData.catAge}
        />
      </div>
    </UserFormInner>
  );
}

DetailSelector.propTypes = {
  toggleFilter: PropTypes.func,
  isTransfer: PropTypes.bool.isRequired,
  selectOnChange: PropTypes.func.isRequired,
  setLangCode: PropTypes.func,
  alarmPage: PropTypes.bool,
  searchData: PropTypes.object.isRequired,
  langCode: PropTypes.number,
};

DetailSelector.defaultProps = {
  toggleFilter: null,
  setLangCode: () => {},
  alarmPage: false,
  langCode: 1,
};

export default DetailSelector;
