import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ForwardOutlined, BackwardOutlined, CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";

import {
  setFirstPaginationNumber,
  setLastPaginationNumber,
  setPrevFirstPaginationNumber,
  setPrevLastPaginationNumber,
} from "../../utils/paginate";

const CustomPaginationWrapper = styled.div`
  margin-top: 30px;
  position: relative;
  width: 100%;
  button {
    font-size: 14px;
  }
  div {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 0;
    text-align: center;
    transform: translateX(-50%);
  }
`;

const CustomCaretLeftOutlined = styled(CaretLeftOutlined)`
  svg {
    width: 13px;
  }
`;

const CustomCaretRightOutlined = styled(CaretRightOutlined)`
  svg {
    width: 13px;
  }
`;

const CustomPageButton = styled.button`
  width: 28px;
  margin: 0 5px;
  font-weight: normal;
`;

const BorderButton = styled.button`
  width: 24px;
  height: 28px;

  color: #222;
  :hover {
    color: rgb(24, 144, 255);
  }

  margin-left: ${(props) => props.marginLeft && "7px"};
`;

function Paginations({ total, page, nowPage }) {
  const [lastPage, setLastPage] = useState();
  const [paginationNumber, setPaginationNumber] = useState({
    firstNumber: 0,
    lastNumber: 1,
  });

  const { firstNumber, lastNumber } = paginationNumber;

  const goToFirstPage = () => {
    const lastPageNumber = Math.ceil(total / 20);
    if (lastPageNumber <= 10) {
      setPaginationNumber({
        ...paginationNumber,
        firstNumber: 0,
        lastNumber: lastPageNumber,
      });
      return nowPage(1);
    }
    setPaginationNumber({
      ...paginationNumber,
      firstNumber: 0,
      lastNumber: 10,
    });

    nowPage(1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    const lastPageNumber = Math.ceil(total / 20);
    setLastPage(lastPageNumber);
    goToFirstPage();
  }, [total]);

  const pageClickForward = () => {
    if (page === lastPage) return;
    const changefirstNumber = setFirstPaginationNumber(page, lastPage);
    const changelastNumber = setLastPaginationNumber(page, lastPage);

    setPaginationNumber({
      ...paginationNumber,
      firstNumber: changefirstNumber,
      lastNumber: changelastNumber,
    });

    if (changelastNumber - changefirstNumber > 10) return;

    nowPage(changefirstNumber + 1);
  };

  const pageClickBack = () => {
    if (page === 1) return;
    const changefirstNumber = setPrevFirstPaginationNumber(page);
    const changelastNumber = setPrevLastPaginationNumber(page, lastPage);
    setPaginationNumber({
      ...paginationNumber,
      firstNumber: changefirstNumber,
      lastNumber: changelastNumber,
    });

    nowPage(changefirstNumber + 1);
  };

  const pageClickHandler = (value) => {
    nowPage(value);
  };

  const toLastPage = () => {
    pageClickHandler(lastPage);
    const changefirstNumber = setFirstPaginationNumber(lastPage, lastPage);
    const changelastNumber = setLastPaginationNumber(lastPage, lastPage);
    if (changefirstNumber === changelastNumber) {
      return setPaginationNumber({
        ...paginationNumber,
        firstNumber: changefirstNumber - 1,
        lastNumber: changelastNumber,
      });
    }
    setPaginationNumber({
      ...paginationNumber,
      firstNumber: changefirstNumber,
      lastNumber: changelastNumber,
    });
  };

  const newArray = new Array(lastPage)
    .fill()
    .map((v, i) => i)
    .slice(firstNumber, lastNumber);

  const nowPageStyle = (value) => {
    if (value === page) return true;
  };
  return (
    <>
      <CustomPaginationWrapper>
        <div>
          <BorderButton type="button" onClick={() => goToFirstPage()}>
            <BackwardOutlined />
          </BorderButton>
          <BorderButton marginLeft type="button" onClick={() => pageClickBack()}>
            <CustomCaretLeftOutlined />
          </BorderButton>
          {newArray.map((v, i) => (
            <CustomPageButton
              type="button"
              onClick={() => pageClickHandler(v + 1)}
              key={i}
              style={{
                textDecoration: nowPageStyle(v + 1) && "underline",
                color: nowPageStyle(v + 1) && "#1890ff",
                fontWeight: nowPageStyle(v + 1) && "bold",
              }}
            >
              {v + 1}
            </CustomPageButton>
          ))}
          <BorderButton type="button" onClick={() => pageClickForward()}>
            <CustomCaretRightOutlined />
          </BorderButton>
          <BorderButton marginLeft type="button" onClick={() => toLastPage()}>
            <ForwardOutlined />
          </BorderButton>
        </div>
      </CustomPaginationWrapper>
    </>
  );
}

Paginations.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number.isRequired,
  nowPage: PropTypes.func.isRequired,
};

Paginations.defaultProps = {
  total: null,
};

export default Paginations;
